export default {
  unlock_a_hero:
    'Добро пожаловать в Команда Подземелья! Для начала откройте своего первого персонажа...',
  nav_to_caverns_tip:
    'В этой игре вы будете создавать отряды героев для сражений в PvE и PvP авто-битвах. Вы также можете участвовать в живых матчах PvP Арены, где вы управляете каждым героем.\n\nНачнем с того, что отправимся в Пещеры, чтобы получить немного опыта, сражаясь с гигантскими крысами.',
  caverns_explained_1:
    'В Пещерах ваши герои будут автоматически сражаться с монстрами.',
  caverns_explained_2:
    'Когда очки действия (ОД) отряда заполнены, он получает возможность сделать ход.',
  caverns_explained_3:
    'После того, как комната будет очищена, вы сможете перемещаться через двери:\n\n/\n\nили лестницы:\n\n>(вниз)\n<(вверх)',
  caverns_explained_4:
    'Когда вы будете готовы завершить пробежку, нажмите «Вернуться на поверхность».',
  nav_to_edit_hero: 'Теперь давайте снабдим этого героя особыми способностями!',
  equip_ability_tip:
    'Нажатие на знак «плюс» повысит уровень способности и активирует ее.',
  nav_to_armory_tip:
    'Отлично! Теперь давайте дадим вашему герою несколько спутников для сражений. Отправляйтесь в Оружейную.',
  unlock_2nd_hero_tip:
    'Здесь вы можете разблокировать новых героев, которые присоединятся к вашей команде.\n\nУ вас достаточно Pixie Dust, чтобы получить в общей сложности 3. Вероятно, лучше всего разблокировать еще 1 героя сейчас, немного изучить игру, а затем разблокировать третьего героя, когда у вас будет лучшее представление о том, что вам нужно.\n\nНаличие 3 героев открывает гостиницу для авто-сражений PvP!',
  open_context_info_panel_tip:
    'Вы готовы играть. Просто помните, что вы всегда можете получить полезную информацию здесь. Удачи!',

  no_abilities_equipped: 'Нет активных способностей!',

  wait_btn_tip:
    'Бой поставлен на паузу.\n\n' +
    'Сейчас ваш ход, но у вас нет очков действия (ОД).\n\n' +
    "Нажмите 'Ждать', чтобы завершить ход и получить ОД.",
  attack_tip:
    'Чтобы произвести основную атаку, нажмите сюда, а затем — на врага.',
  move_tip: 'Чтобы переместиться, нажмите сюда, а затем — на нужную ячейку.',
  zoom_tip: 'Прокрутив колесико мыши, можно изменить масштаб. Попробуйте!',
  edit_hero_tip: 'Нажмите на персонажа, чтобы добавить способности ----->',
  cycle_ability_trees_tip:
    'Нажмите на панель, чтобы просмотреть деревья способностей персонажа.',
  get_ability_info:
    'Нажав значок плюса, можно повысить уровень и экипировать способность.',
  final_tip:
    'Ну все, пора начинать! Если вам понадобится помощь, пишите в чат!\n' +
    'Когда добавите все нужные способности, нажмите кнопку АРЕНА и начните свой первый серьезный бой.\n' +
    'Удачи! Спасибо, что играете в Команда Подземелья!',
  tutorial_engine_error:
    'Что-то пошло не так с движком руководства по игре. Нам нужно подключить ваш аккаунт заново...',
};
