import { Dispatcher } from 'flux';

const EditHeroDispatcher = Object.assign(new Dispatcher(), {
  NAME: 'EDIT_HERO_DISPATCHER',

  EQUIP_ABILITY: 'EQUIP_ABILITY',
  INCREMENT_AFFINITY: 'INCREMENT_AFFINITY',
  MOVE_AI_ROLE_TO_INDEX: 'MOVE_AI_ROLE_TO_INDEX',
  MOVE_ITEM: 'MOVE_ITEM',
  RESET_ABILITIES: 'RESET_ABILITIES',
  RESET_ABILITIES_CONFIRM: 'RESET_ABILITIES_CONFIRM',
  RESET_ATTRIBUTES: 'RESET_ATTRIBUTES',
  RESET_ATTRIBUTES_CONFIRM: 'RESET_ATTRIBUTES_CONFIRM',
  UNEQUIP_ABILITY: 'UNEQUIP_ABILITY',
  UPGRADE_ABILITY: 'UPGRADE_ABILITY',
  UNEQUIP_ITEM: 'UNEQUIP_ITEM',
  UPGRADE_ATTRIBUTE: 'UPGRADE_ATTRIBUTE',
  USE_XP_BOOST: 'USE_XP_BOOST',

  handleEditHeroAction(action) {
    this.dispatch({
      source: EditHeroDispatcher.NAME,
      action,
    });
  },
});
export default EditHeroDispatcher;
