import Audio from '~/Audio';

const LeapRendering = {
  render: function (data, gameBoard, isCaverns) {
    var actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
    const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;
    var victim = gameBoard.getAllPieceSprites()[data.victimId].gamePiece;

    if (isCaverns) {
      const victimSprite = gameBoard.getAllPieceSprites()[data.victimId];
      victimSprite.tileImg.visible = false;
      if (victimSprite.lootImg) {
        victimSprite.lootImg.visible = false;
      }

      const actor = actorSprite.gamePiece;
      const tileSprites = gameBoard.getTileSprites();
      for (var prop in tileSprites) {
        var tSprite = tileSprites[prop];
        var tile = tSprite.gamePiece;
        if (tile.x === actor.x && tile.y === actor.y) {
          if (tSprite.lootImg) {
            tSprite.lootImg.visible = true;
          } else {
            tSprite.tileImg.visible = true;
          }
          break;
        }
      }
    }

    if (render_movement) {
      actorSprite.puppet
        .getMovements()
        .leap.movePuppet({ transitTime, timeVar: data.projectileTime / 1000 });
    }
    TweenMax.delayedCall(0.4, actorSprite.tweenToBoardPosition, [
      victim.x,
      victim.y,
      data.projectileTime / (isCaverns ? 2000 : 1000), // faster in caverns
    ]);
    TweenMax.delayedCall(0.37, Audio.play, ['leap']);
  },
};
export default LeapRendering;
