import abilities from './abilities.js';
import ability_trees from './ability_trees.js';
import attributes from './attributes.js';
import battle from './battle.js';
import battle_event_log from './battle_event_log.js';
import chat from './chat.js';
import context_info from './context_info.js';
import equipment from './equipment.js';
import game from './game.js';
import heroes from './heroes.js';
import server from './server.js';
import shop from './shop.js';
import tutorial from './tutorial.js';
import ui from './ui.js';
import unit_names from './unit_names.js';

const en_index = {
  abilities,
  ability_trees,
  attributes,
  battle,
  battle_event_log,
  chat,
  context_info,
  equipment,
  game,
  heroes,
  server,
  shop,
  tutorial,
  ui,
  unit_names,
};
export default en_index;
