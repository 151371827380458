export default {
  dye: 'Краска для снаряжения',
  miracle_dye: 'Волшебная краска',
  hero_xp_boost: 'Геройский бонус к опыту',
  prayer_candle: 'Церковная свеча',
  bag: 'Сумка',
  item_container_space: (container_size) => `Место: ${container_size}`,

  // light helmet
  headband: 'Повязка',
  headdress: 'Наголовник',
  circlet: 'Венец',
  diadem: 'Диадема',
  crown: 'Корона',

  // med helmet
  skullcap: 'Шапка',
  leather_helm: 'Кожаный шлем',
  studded_helm: 'Шипастый шлем',
  coif: 'Подшлемник',
  dragonscale_helm: 'Драконий шлем',

  // heavy helmet
  copper_helm: 'Медный шлем',
  bronze_helm: 'Бронзовый шлем',
  iron_helm: 'Железный шлем',
  steel_helm: 'Стальной шлем',
  mithril_helm: 'Мифриловый шлем',

  // light torsoe
  rags: 'Лохмотья',
  linen_cloak: 'Холщовая накидка',
  brocade_cloak: 'Парчовая накидка',
  spidersilk_cloak: 'Накидка из паутины',
  mageweave_cloak: 'Накидка из магической ткани',

  // med torsoe
  hide_vest: 'Куртка из шкуры',
  leather_vest: 'Кожаная куртка',
  studded_vest: 'Шипастая куртка',
  chainmail_vest: 'Кольчуга',
  dragonscale_vest: 'Драконья куртка',

  // heavy torsoe
  copper_plate: 'Медный доспех',
  bronze_plate: 'Бронзовый доспех',
  iron_plate: 'Железный доспех',
  steel_plate: 'Стальной доспех',
  mithril_plate: 'Мифриловый доспех',

  // light boots
  sandals: 'Сандалии',
  linen_shoes: 'Холщовые туфли',
  brocade_slippers: 'Парчовые туфли',
  spidersilk_slippers: 'Туфли из паутины',
  mageweave_slippers: 'Туфли из магической ткани',

  // med boots
  hide_boots: 'Ботинки из шкуры',
  leather_boots: 'Кожаные ботинки',
  studded_boots: 'Шипастые ботинки',
  chainmail_boots: 'Кольчужные ботинки',
  dragonscale_boots: 'Драконьи ботинки',

  // heavy boots
  copper_boots: 'Медные сапоги',
  bronze_boots: 'Бронзовые сапоги',
  iron_boots: 'Железные сапоги',
  steel_boots: 'Стальные сапоги',
  mithril_boots: 'Мифриловые сапоги',

  // daggers
  shiv: 'Заточка',
  dagger: 'Кинжал',
  slicer: 'Нож',
  stiletto: 'Стилет',
  dragon_tooth: 'Зуб дракона',

  // hammers
  club: 'Дубина',
  hammer: 'Молот',
  greathammer: 'Большой молот',
  grandhammer: 'Тяжелый молот',
  thors_hammer: 'Молот Тора',

  // staves
  walking_stick: 'Палка',
  quarterstaff: 'Боевой посох',
  oaken_staff: 'Дубовый посох',
  greatstaff: 'Большой посох',
  wizards_staff: 'Посох волшебника',

  // swords
  shortsword: 'Короткий меч',
  broadsword: 'Широкий меч',
  longsword: 'Длинный меч',
  falchion: 'Фальшион',
  anduril: 'Андурил',

  // bows
  shortbow: 'Короткий лук',
  longbow: 'Длинный лук',
  composite_bow: 'Композитный лук',
  mechanical_bow: 'Блочный лук',
  elven_bow: 'Эльфийский лук',

  // shields
  wooden_shield: 'Деревянный щит',
  buckler: 'Баклер',
  round_shield: 'Круглый щит',
  kite_shield: 'Каплевидный щит',
  tower_shield: 'Скутум',

  // books
  folio: 'Страница',
  scroll: 'Свиток',
  book: 'Книга',
  tome: 'Фолиант',
  compendium: 'Справочник',

  // trinkets
  bauble: 'Сувенир',
  trinket: 'Брелок',
  figurine: 'Фигурка',
  totem: 'Тотем',
  orb: 'Шар',

  // ring
  ring: 'Кольцо',

  prefixes: {
    blessed: 'Освященное',
    clumsy: 'Неудобное',
    coarse: 'Кривое',
    crude: 'Дешевое',
    cursed: 'Проклятое',
    dull: 'Тусклое',
    elegant: 'Элегантное',
    emblazoned: 'Гербовое',
    fine: 'Изысканное',
    glorious: 'Шикарное',
    glowing: 'Сияющее',
    heavy: 'Тяжелое',
    lavish: 'Роскошное',
    light: 'Легкое',
    magical: 'Магическое',
    majestic: 'Королевское',
    modest: 'Скромное',
    ornate: 'Декоративное',
    rotting: 'Ржавое',
    runed: 'Рунное',
    scuffed: 'Поцарапанное',
    shining: 'Блестящее',
    sleek: 'Гладкое',
    ulrich: 'Ульриха',
    garland: 'Гарланда',
    vetronius: "Ветрония'",
    sindri: 'Синдри',
    cousity: 'Коцита',

    // armor
    fitted: 'с подгонкой',
    padded: 'с пропиткой',
    patched: 'с заплатами',
    ragged: 'с дефектами',
    stinking: 'с запахом',
    thin: 'без утепления',
    warm: 'с утеплением',
    scrubbed: 'после очистки',

    // weapons
    barbaric: 'варвара',
    barbed: 'с зубцами',
    brutal: 'силача',
    fragile: 'хрупкости',
    hefty: 'великана',
    gladiator: 'гладиатора',
    masterwork: 'мастера',
    murderous: 'убийцы',
    swift: 'скорости',
    unbalanced: 'нестабильности',
    vampiric: 'вампира',
    venomous: 'отравителя',
    weeping: 'плача',
    well_balanced: 'гармонии',
  },

  suffixes: {
    anger: 'гнева',
    balance: 'равновесия',
    bear: 'медведя',
    boar: 'вепря',
    chaos: 'хаоса',
    cunning: 'смекалки',
    defense: 'защиты',
    doom: 'рока',
    dragon: 'дракона',
    eagle: 'орла',
    fawn: 'сокола',
    health: 'здоровья',
    ignorance: 'невежества',
    leadership: 'лидера',
    life: 'жизни',
    madness: 'безумия',
    might: 'величия',
    mountain: 'горы',
    order: 'порядка',
    ox: 'буйвола',
    power: 'власти',
    protection: 'заступника',
    resilience: 'стойкости',
    scourge: 'бедствия',
    sea: 'моря',
    sky: 'неба',
    sloth: 'лени',
    snake: 'змеи',
    speed: 'скорости',
    stupidity: 'глупости',
    tiger: 'тигра',
    travelling: 'странствий',
    tree: 'дерева',
    unicorn: 'единорога',
    vulgar: 'дикаря',
    weakness: 'слабости',
    whale: 'кита',
    wonder: 'чуда',
    rejuvenation: 'молодости',
    poise: 'соразмерности',
    focus: 'сосредоточения',
    novice: 'ученика',
    fortress: 'крепости',
    lunatic: 'безумца',
    dove: 'голубя',

    // weapons
    destruction: 'разрушения',
    fire: 'пламени',
    ice: 'льда',
    penetration: 'проникания',
    thunder: 'грома',
    terror: 'ужаса',
  },

  descriptions: {
    equipment_dye: 'Меняет расцветку одной единицы снаряжения.',
    miracle_dye: 'Меняет расцветку снаряжения на выбор игрока.',
    hero_xp_boost:
      'На время действия дает персонажу бонус ко всем очкам опыта.\n\nИСПОЛЬЗОВАНИЕ: на экране редактирования героя перетащите из инвентаря в слот в верхней средней части.',
    prayer_candle:
      'Выберите игрока, за которого вы хотите помолиться - его активные таймеры молитв (богу свечи) мгновенно уменьшаются. Кроме того, вы получаете меньшее время молитв на время действия.',
    vampiric: 'Возврат 15% урона от атак в виде ОЗ.',
    venomous: 'Атаки отравляют цель.',
    weeping: 'При атаке цель получает состояние кровотечения.',
    fire: 'Вероятность 20% активировать Пылающие руки при получении урона.',
    gladiator: '20% шанс оглушить.',
    ice: 'Вероятность 20% активировать Лед-девять при получении урона.',
    penetration: 'При атаках показатель брони противника снижается на 50%.',
    thunder: 'Вероятность 20% активировать Молнию при получении урона.',
    terror: 'Вероятность 7% наложить на цель состояние испуга.',
    destruction: '+20% шанс крита',
  },
};
