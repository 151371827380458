import { EventEmitter } from 'events';
import { Colors } from '~/constants';
import {
  EditHeroDispatcher,
  HeroBuildDispatcher,
  PlayerDispatcher,
} from '~/flux/dispatchers';
import text from '~/text';
import { awaitSocket, registerDispatchHandlers } from '~/Tools';

let playerId;
let _socket;

// the stuff we serve:
let hero_builds;
let pending_ability_upgrade_handle = false;

const HeroBuildStore = Object.assign({}, EventEmitter.prototype, {
  ABILITY_CHANGE: 'ABILITY_CHANGE',
  AI_PRIORITIES_CHANGE: 'AI_PRIORITIES_CHANGE',
  ATTRIBUTE_CHANGE: 'ATTRIBUTE_CHANGE',
  GOT_HERO_BUILDS: 'GOT_HERO_BUILDS',
  HERO_UNLOCKED: 'HERO_UNLOCKED',
  ITEM_EQUIPPED: 'ITEM_EQUIPPED',
  ITEM_UNEQUIPPED: 'ITEM_UNEQUIPPED',
  NEW_HERO_BUILD_CREATED: 'NEW_HERO_BUILD_CREATED',
  RESET_ABILITIES_REQUESTED: 'RESET_ABILITIES_REQUESTED',

  getAll() {
    return {
      hero_builds,
      pending_ability_upgrade_handle,
    };
  },
});
export default HeroBuildStore;

EditHeroDispatcher.register(
  registerDispatchHandlers({
    [EditHeroDispatcher.RESET_ABILITIES_CONFIRM]: requestAbilitiesReset,
    [EditHeroDispatcher.UPGRADE_ABILITY]: requestAbilityUpgrade,
    [EditHeroDispatcher.EQUIP_ABILITY]: requestEquipAbility,
    [EditHeroDispatcher.UNEQUIP_ABILITY]: requestUnequipAbility,
    [EditHeroDispatcher.RESET_ATTRIBUTES_CONFIRM]: requestAttributeReset,
    [EditHeroDispatcher.UPGRADE_ATTRIBUTE]: requestAttributeUpgrade,
    [EditHeroDispatcher.MOVE_AI_ROLE_TO_INDEX]: requestMoveAIRoleToIndex,
    [EditHeroDispatcher.INCREMENT_AFFINITY]: requestIncrementHeroAffinity,
  })
);
PlayerDispatcher.register(
  registerDispatchHandlers({
    [PlayerDispatcher.PLAYER_LOGGED_IN]: onPlayerLoggedIn,
  })
);
HeroBuildDispatcher.register(
  registerDispatchHandlers({
    [HeroBuildDispatcher.CREATE_NEW_HERO_BUILD]: requestCreateNewHeroBuild,
    [HeroBuildDispatcher.DELETE_HERO_BUILD]: requestDeleteHeroBuild,
    [HeroBuildDispatcher.ENGAGE_HERO_BULID]: requestEngageHeroBuild,
    [HeroBuildDispatcher.RENAME_HERO_BULID]: requestRenameHeroBuild,
  })
);

awaitSocket(onSocketConnected);
// awaitSocket().then(onSocketConnected);
function onSocketConnected(socket) {
  _socket = socket;
  if (!_socket.has_HeroBuildStore_listeners) {
    _socket.on('ability_change', onAbilityChange);
    _socket.on('ai_priorities_change', onAIPrioritiesChange);
    _socket.on('attribute_change', onAttributeChange);
    _socket.on('hero_build_deleted', onHeroBuildDeleted);
    _socket.on('hero_build_renamed', onHeroBuildRenamed);
    _socket.on('hero_builds', onHeroBuilds);
    _socket.on('heroUnlocked', onHeroUnlocked);
    _socket.on('invalid_hero_build_name', onInvalidHeroBuildName);
    _socket.on('item_equipped', onItemEquipped);
    _socket.on('item_unequipped', onItemUnequipped);
    _socket.on('new_hero_build_created', onNewHeroBuildCreated);

    _socket.has_HeroBuildStore_listeners = true;
  }
}

function onPlayerLoggedIn(action) {
  try {
    const { player } = action;
    playerId = action.player._id;
    hero_builds = player.gameState.hero_builds;
  } catch (err) {
    logError(err, {
      module: 'HeroBuildStore',
      func: 'onPlayerLoggedIn',
      action,
    });
  }
}

function onHeroBuilds(data) {
  try {
    hero_builds = data.hero_builds;
    HeroBuildStore.emit(HeroBuildStore.GOT_HERO_BUILDS, hero_builds);
  } catch (err) {
    logError(err, {
      module: 'HeroBuildStore',
      func: 'onHeroBuilds',
      data,
    });
  }
}

function onHeroUnlocked(data) {
  try {
    hero_builds = data.hero_builds;
    HeroBuildStore.emit(HeroBuildStore.HERO_UNLOCKED, data.heroHandle);
    HeroBuildStore.emit(HeroBuildStore.GOT_HERO_BUILDS, hero_builds);
  } catch (err) {
    logError(err, {
      module: 'HeroBuildStore',
      func: 'onHeroUnlocked',
      data,
    });
  }
}

function requestCreateNewHeroBuild(action) {
  try {
    const { hero_handle, new_build_name } = action;

    // validation is done in the API, but lets try to avoid sending overly long strings
    if (
      !new_build_name ||
      new_build_name.length < 1 ||
      new_build_name.length > 20
    ) {
      $addMessageLogMessage(
        text('server.error.hero_build_name_rule_2'),
        Colors.RED
      );
      return;
    }

    _socket.emit('create_new_hero_build', {
      playerId,
      hero_handle,
      new_build_name,
    });
  } catch (err) {
    logError(err, {
      module: 'HeroBuildStore',
      func: 'requestCreateNewHeroBuild',
      action,
    });
  }
}

function onInvalidHeroBuildName(data) {
  $addMessageLogMessage(
    `${text('ui.invalid_username')} ${data.message}`,
    Colors.RED
  );
}

function onNewHeroBuildCreated(data) {
  try {
    hero_builds = data.hero_builds;
    HeroBuildStore.emit(HeroBuildStore.GOT_HERO_BUILDS, hero_builds);
    HeroBuildStore.emit(HeroBuildStore.NEW_HERO_BUILD_CREATED);
  } catch (err) {
    logError(err, {
      module: 'HeroBuildStore',
      func: 'onNewHeroBuildCreated',
      data,
    });
  }
}

function requestDeleteHeroBuild(action) {
  try {
    const { hero_handle, build_id } = action;
    _socket.emit('delete_hero_build', {
      playerId,
      hero_handle,
      build_id,
    });
  } catch (err) {
    logError(err, {
      module: 'HeroBuildStore',
      func: 'requestDeleteHeroBuild',
      action,
    });
  }
}

function onHeroBuildDeleted(data) {
  try {
    hero_builds = data.hero_builds;
    HeroBuildStore.emit(HeroBuildStore.GOT_HERO_BUILDS, hero_builds);
    HeroBuildStore.emit(HeroBuildStore.HERO_BUILD_DELETED);
  } catch (err) {
    logError(err, {
      module: 'HeroBuildStore',
      func: 'onHeroBuildDeleted',
      data,
    });
  }
}

function onItemEquipped(data) {
  try {
    hero_builds[data.hero_handle] = data.this_hero_builds;
    HeroBuildStore.emit(HeroBuildStore.ITEM_EQUIPPED);
  } catch (err) {
    logError(err, {
      module: 'HeroBuildStore',
      func: 'onItemEquipped',
      data,
    });
  }
}

function onItemUnequipped(data) {
  try {
    hero_builds[data.hero_handle] = data.this_hero_builds;
    HeroBuildStore.emit(HeroBuildStore.ITEM_UNEQUIPPED);
  } catch (err) {
    logError(err, {
      module: 'HeroBuildStore',
      func: 'onItemUnequipped',
      data,
    });
  }
}

function requestAbilityUpgrade({ hero_handle, ability_handle, hero_build_id }) {
  pending_ability_upgrade_handle = ability_handle;

  _socket.emit('upgrade_ability', {
    playerId,
    hero_handle,
    ability_handle,
    hero_build_id,
  });
}

function onAbilityChange(data) {
  try {
    const { hero_handle, this_hero_build } = data;

    const build_index = hero_builds[hero_handle].findIndex(
      ({ _id }) => _id === this_hero_build._id
    );
    hero_builds[hero_handle][build_index] = this_hero_build;

    pending_ability_upgrade_handle = false;
    HeroBuildStore.emit(HeroBuildStore.ABILITY_CHANGE);
  } catch (err) {
    logError(err, {
      module: 'HeroBuildStore',
      func: 'onAbilityChange',
      data,
    });
  }
}

function requestAbilitiesReset({ hero_build_id, hero_handle }) {
  HeroBuildStore.emit(HeroBuildStore.RESET_ABILITIES_REQUESTED);

  _socket.emit('reset_abilities', {
    playerId,
    hero_build_id,
    hero_handle,
  });
}

function requestEquipAbility({ ability_handle, hero_handle, hero_build_id }) {
  _socket.emit('equip_ability', {
    playerId,
    ability_handle,
    hero_handle,
    hero_build_id,
  });
}

function requestUnequipAbility({ ability_handle, hero_handle, hero_build_id }) {
  _socket.emit('unequip_ability', {
    playerId,
    ability_handle,
    hero_handle,
    hero_build_id,
  });
}

function onAttributeChange(data) {
  try {
    const { hero_handle, this_hero_build } = data;

    const build_index = hero_builds[hero_handle].findIndex(
      ({ _id }) => _id === this_hero_build._id
    );
    hero_builds[hero_handle][build_index] = this_hero_build;
    HeroBuildStore.emit(HeroBuildStore.ATTRIBUTE_CHANGE);
  } catch (err) {
    logError(err, {
      module: 'HeroBuildStore',
      func: 'onAttributeChange',
      data,
    });
  }
}

function requestAttributeUpgrade({
  hero_handle,
  attribute_handle,
  hero_build_id,
}) {
  _socket.emit('upgrade_attribute', {
    playerId,
    hero_handle,
    attribute_handle,
    hero_build_id,
  });
}

function requestAttributeReset({ hero_handle, hero_build_id }) {
  _socket.emit('reset_attributes', {
    playerId,
    hero_handle,
    hero_build_id,
  });
}

function requestMoveAIRoleToIndex({
  ai_tree_tag,
  hero_build_id,
  hero_handle,
  target_index,
}) {
  _socket.emit('move_ai_role_to_index', {
    playerId,
    ai_tree_tag,
    hero_build_id,
    hero_handle,
    target_index,
  });
}

function onAIPrioritiesChange(data) {
  try {
    const { hero_handle, this_hero_build } = data;

    const build_index = hero_builds[hero_handle].findIndex(
      ({ _id }) => _id === this_hero_build._id
    );
    hero_builds[hero_handle][build_index] = this_hero_build;
    HeroBuildStore.emit(HeroBuildStore.AI_PRIORITIES_CHANGE);
  } catch (err) {
    logError(err, {
      module: 'HeroBuildStore',
      func: 'onAIPrioritiesChange',
      data,
    });
  }
}

function requestEngageHeroBuild(action) {
  _socket.emit('engage_hero_build', {
    playerId,
    action,
  });
}

function requestRenameHeroBuild(action) {
  _socket.emit('rename_hero_build', {
    playerId,
    ...action,
  });
}

function onHeroBuildRenamed(data) {
  hero_builds = {
    ...hero_builds,
    ...data.updated_hero_builds,
  };
  HeroBuildStore.emit(HeroBuildStore.GOT_HERO_BUILDS, hero_builds);
}

function requestIncrementHeroAffinity({
  hero_handle,
  hero_build_id,
  affinity_type,
  unit_type_handle,
  value,
}) {
  _socket.emit('increment_hero_affinity', {
    playerId,
    hero_handle,
    hero_build_id,
    affinity_type,
    unit_type_handle,
    value,
  });
}
