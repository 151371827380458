import * as PIXI from 'pixi.js';
import { EventEmitter } from 'events';
import AbilityIcon from '~/components/common/AbilityIcon';
import BattleCalc from 'dt-common/battle_engine/BattleCalc';
import PinwheelPanel from '~/components/common/PinwheelPanel';

const AbilitySelectPanel = function (hero) {
  PIXI.Container.call(this);

  this.eventEmitter = new EventEmitter();

  const pinwheel = new PinwheelPanel();

  const _icons = (this.icons = []);

  // something somewhere is turning arrays into objects { '0': foo, '1': bar}
  if (typeof hero.equipped_abilities === 'object') {
    hero.equipped_abilities = Object.values(hero.equipped_abilities);
  }
  if (typeof hero.abilityTrees === 'object') {
    hero.abilityTrees = Object.values(hero.abilityTrees);
  }

  for (let i = 0; i < hero.equipped_abilities.length; ++i) {
    for (let j = 0; j < hero.abilityTrees.length; ++j) {
      if (hero.abilityTrees[j].handle === 'standard') {
        continue;
      }

      for (let prop in hero.abilityTrees[j].abilities) {
        const ability = hero.abilityTrees[j].abilities[prop];

        if (ability.handle === hero.equipped_abilities[i] && !ability.passive) {
          var icon = new AbilityIcon(ability);
          icon.height = Math.min(icon.height, window.innerHeight * 0.085);
          icon.scale.x = icon.scale.y;
          _icons.push(icon);
          pinwheel.pinwheelObjects.push(icon);
        }
      }
    }
  }

  if (_icons.length) {
    this.buttonWidth = _icons[0].width;
  }

  const makeStanceDancingIcons = () => {
    const stanceHandles = [
      'defensive_stance',
      'stance_dancing',
      'offensive_stance',
    ];

    for (var i = 0; i < stanceHandles.length; ++i) {
      var icon = new PIXI.Sprite();
      icon.texture = PIXI.utils.TextureCache[stanceHandles[i] + '.png'];
      icon.stanceHandle = stanceHandles[i];
      if (icon.stanceHandle === 'stance_dancing') {
        icon.stanceHandle = 'normal_stance';
      }

      icon.height = Math.min(icon.height, window.innerHeight * 0.085);
      icon.scale.x = icon.scale.y;
      icon.anchor.x = icon.anchor.y = 0.5;
      icon.x = -icon.width * 1.1 + i * icon.width * 1.1;

      icon.interactive = true;
      icon.buttonMode = true;

      icon.mousedown = icon.touchstart = (event) => {
        const ability = BattleCalc.getEquippedAbility(hero, 'stance_dancing');
        ability.stance = event.target.stanceHandle;

        this.eventEmitter.emit('ABILITY_SELECTED', ability);
      };

      TweenMax.from(icon.scale, 0.15, { x: 0, y: 0 }).delay(0.05 * i);

      this.addChild(icon);
      _icons.push(icon);
    }
  };

  const hotkeyStyle = {
    fontFamily: 'Courier New',
    fontSize: 32,
    fontStyle: 'bold',
    fill: 0xffffff,
    dropShadow: true,
    dropshadowColor: 0x000000,
    dropShadowDistance: 3,
  };

  for (let k = 0; k < _icons.length; ++k) {
    const btn = _icons[k];

    btn.anchor.x = btn.anchor.y = 0.5;
    btn.interactive = true;
    btn.buttonMode = true;

    btn.mousedown = btn.touchstart = (event) => {
      const ability = event.target.getAbility();
      if (ability.handle === 'stance_dancing') {
        hideIcons();
        makeStanceDancingIcons();
      } else {
        this.eventEmitter.emit('ABILITY_SELECTED', ability);
        hideIcons();
      }
    };
    this.addChild(btn);

    // store the index
    btn.index = k + 1;

    // add hotkey text
    var hotkeyText = new PIXI.Text(btn.index.toString(), hotkeyStyle);
    hotkeyText.x = -hotkeyText.width / 2;
    hotkeyText.y = -hotkeyText.height / 2;
    btn.addChild(hotkeyText);
  }

  pinwheel.pinwheelDelay = 0.1;
  pinwheel.pinwheelDuration = 0.4;
  pinwheel.radius = window.innerHeight * 0.075;
  pinwheel.init();

  const onKeydown = (event) => {
    for (const btn of pinwheel.pinwheelObjects) {
      if (event.key === btn.index.toString()) {
        btn.mousedown({ target: btn });
      }
    }
  };
  document.addEventListener('keydown', onKeydown);

  this.disable = () => {
    disableIcons();
    document.removeEventListener('keydown', onKeydown);
    this.eventEmitter.removeAllListeners();
  };

  this.dispose = () => {
    this.disable();
    this.removeChildren();
    this.eventEmitter = null;
  };

  function hideIcons() {
    if (_icons) {
      for (const icon of _icons) {
        TweenMax.to(icon.scale, 0.15, { x: 0, y: 0 });
      }
    }
  }

  function disableIcons() {
    if (_icons) {
      for (const icon of _icons) {
        icon.mousedown = icon.touchstart = null;
      }
    }
  }
};
AbilitySelectPanel.prototype = Object.create(PIXI.Container.prototype);
AbilitySelectPanel.prototype.constructor = AbilitySelectPanel;
export default AbilitySelectPanel;
