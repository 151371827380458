<template>
  <div
    id="chat_root"
    ref="chat_root"
    :class="{
      [`absolute bottom-[1.75vh] left-[1.75vh] w-[calc(50%_-_1.25vh)] p-1 pt-0 flex flex-col content-left`]: true,
      'h-[80%]': expanded,
      'h-[25.5%]': !expanded,
    }"
  >
    <!-- row for expandable toggle, players online info, etc -->
    <div class="w-full flex">
      <!-- expand/contract button, and Players Online count -->
      <div class="border-b border-b-gray-600 flex items-end">
        <button
          class="cursor-pointer w-8"
          @click="expanded = !expanded"
        >
          <img
            :src="`${Config.DOM_IMG_PATH}/${expanded ? 'down' : 'up'}_arrow.png`"
          />
        </button>
        <PlayersOnlineInfoPanel />
      </div>
      <!-- middle placeholder -->
      <div class="flex-grow border-b border-b-gray-600"></div>
      <!-- chat room nameplates -->
      <div class="flex text-xs">
        <div
          v-for="room_name of state.room_names"
          :key="room_name"
          :class="{
            'relative border border-gray-600 rounded-[3px] px-4 py-1 bg-zinc-900/80 flex items-center cursor-pointer': true,
            'bg-zinc-900/60': room_name !== state.current_room_name,
            'border-b-0': [room_name, NULL_ROOM_NAME].includes(
              state.current_room_name
            ),
          }"
          @click="
            () => {
              ChatActions.selectChatRoom(room_name);
              state.current_room_name = room_name;
            }
          "
        >
          <span>{{
            room_name.includes('lobby')
              ? `${text('ui.chat_lobby')} [${room_name.split('-')[1]}-${room_name.split('-')[2]}]`
              : room_name
          }}</span>
          <!-- leave room X button -->
          <div
            v-if="state.num_chat_rooms > 1"
            class="absolute -top-[3px] right-[2px] text-tiny text-gray-500 no-select"
            @click="
              (event) => {
                event.stopPropagation();
                ChatActions.leaveChatRoom(room_name);
              }
            "
          >
            x
          </div>
        </div>
        <!-- button for adding new chat rooms -->
        <div
          v-if="state.num_chat_rooms < MAX_NUM_ROOMS"
          class="relative flex flex-col items-end"
        >
          <button
            class="h-[32px] border border-gray-600 rounded-[3px] px-4 py-1 bg-zinc-900/60 flex items-center cursor-pointer text-xl font-bold text-gray-600"
            @click="
              (event) => {
                event.stopPropagation();
                state.show_new_chat_room_selector =
                  !state.show_new_chat_room_selector;
              }
            "
          >
            <span>+</span>
          </button>
          <ul
            v-if="state.show_new_chat_room_selector"
            class="absolute right-0 top-[32px] w-[130px] bg-gray-800 max-h-[calc(27vh-40px)] overflow-y-auto"
          >
            <li
              v-for="locale of Object.keys(Locales)"
              class="py-1 cursor-pointer hover:bg-gray-500"
              @click="ChatActions.joinNewChatRoom({ channel: 'lobby', locale })"
            >
              <span class="mx-2">{{ Locales[locale].emoji }}</span>
              <span>{{ Locales[locale].name }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- channel content -->
    <ChatBodyScroll :expanded="expanded" />

    <!-- player text entry form -->
    <form
      class="w-full mt-2 flex"
      @submit="onTextEntered"
    >
      <DTTextInput
        id="chat_input"
        forwardRef="chat_input"
        :placeholder="text('ui.chat_input_placeholder')"
      />
      <button
        type="submit"
        class="px-4 py-1 submit-btn ml-3"
        :style="{
          backgroundImage: `url('${Config.DOM_IMG_PATH}/send_btn.png')`,
        }"
      >
        {{ text('ui.send') }}
      </button>
    </form>
  </div>
</template>

<script setup>
import {
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  onUnmounted,
} from 'vue';
import { Config, Locales } from '~/constants';
import { ChatActions } from '~/flux/actions';
import { ChatStore } from '~/flux/stores';
import text from '~/text';
import { DTTextInput } from '~/view/components/common/DOM';
import ChatBodyScroll from './ChatBodyScroll.vue';
import PlayersOnlineInfoPanel from './PlayersOnlineInfoPanel.vue';

const NULL_ROOM_NAME = '----------';
const MAX_NUM_ROOMS = 5;

const expanded = ref(false);
const chat_root = ref(null);

const { message_cache, current_room_name } = ChatStore.getAll();
const state = reactive({
  current_room_name: NULL_ROOM_NAME,
  message_cache,
  num_chat_rooms: Object.keys(message_cache).length,
  room_names: [NULL_ROOM_NAME], // Object.keys(message_cache),
});

// handle ENTER key presses (holding SHIFT prevents submit)
onBeforeMount(() => document.addEventListener('keypress', onKeypress));
onUnmounted(() => document.removeEventListener('keypress', onKeypress));
function onKeypress(event) {
  if (event.code === 'Enter' && !event.shiftKey) {
    onTextEntered(event);
  }
}

onMounted(() => {
  try {
    // transition in
    TweenMax.from(chat_root.value, {
      duration: 1.15,
      y: window.innerHeight,
      ease: 'expo.out',
    });

    ChatStore.on(ChatStore.CHAT_ROOM_JOINED, onChatRoomChange);
    ChatStore.on(ChatStore.CHAT_ROOM_LEFT, onChatRoomChange);
    window.addEventListener('click', onWindowClick);

    onChatRoomChange();
  } catch (err) {
    logError(err, {
      module: 'components/DOM/chat/Chat',
      func: 'componentDidMount',
    });
  }
});

onBeforeUnmount(() => {
  ChatStore.removeListener(ChatStore.CHAT_ROOM_JOINED, onChatRoomChange);
  ChatStore.removeListener(ChatStore.CHAT_ROOM_LEFT, onChatRoomChange);
  window.removeEventListener('click', onWindowClick);
});

function onChatRoomChange() {
  const { message_cache, current_room_name } = ChatStore.getAll();
  state.message_cache = message_cache;
  state.room_names = Object.keys(message_cache);
  state.room_names = state.room_names.length
    ? state.room_names
    : [NULL_ROOM_NAME];
  state.num_chat_rooms = state.room_names.length;
  state.current_room_name = current_room_name || NULL_ROOM_NAME;
}

function onWindowClick() {
  state.show_new_chat_room_selector = false;
}

function onTextEntered(e) {
  try {
    // no page reload
    e.preventDefault();

    const chat_input = document.getElementById('chat_input');
    const text = chat_input?.value?.trim();
    if (!!text) {
      ChatActions.enterMessage(text);
    }

    // clear the field
    chat_input.value = '';
  } catch (err) {
    logError(err, {
      module: 'view/components/footer/Footer_DOM/Chat',
      func: 'onTextEntered',
    });
  }
}
</script>

<style>
.submit-btn,
.submit-btn:hover,
.submit-btn:focus,
.submit-btn:active {
  color: black !important;
  border: 0 none !important;
  border-radius: 0 !important;
  background-position: center !important;
  background-size: 100% !important;
  background-repeat: no-repeat !important;
}
</style>
