import CommonEffects from '../../../effects/CommonEffects';
import Audio from '~/Audio';
import { BattleStore, CavernsStore } from '~/flux/stores';
import BattleCalc from 'dt-common/battle_engine/BattleCalc';

const TeleportRendering = {
  render: function (data, gameBoard, isCaverns) {
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
    const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

    render_movement &&
      actorSprite.puppet.getMovements().magicSummon.movePuppet({ transitTime });
  },

  renderResult: function (data, gameBoard, isCaverns) {
    const battleState = isCaverns
      ? CavernsStore.getAll().battleState
      : BattleStore.getAll().battleState;
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const originalTileSprite =
      gameBoard.getTileSprites()[
        BattleCalc.getTileAtCoords(
          data.originalTileCoords.x,
          data.originalTileCoords.y,
          battleState.allTiles
        ).uid
      ];
    const victimSprite = gameBoard.getAllPieceSprites()[data.victimId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
    const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

    TweenMax.delayedCall(transitTime / 3, CommonEffects.makePortalEffect, [
      originalTileSprite,
    ]);
    TweenMax.delayedCall(transitTime / 3, CommonEffects.makePortalEffect, [
      victimSprite,
    ]);

    if (!isCaverns) {
      TweenMax.delayedCall(transitTime / 1.2, () => {
        TweenMax.to(actorSprite.puppet.scale, 0.2, {
          x: 0,
          y: 0,
          ease: Quad.easeIn,
          onComplete: () => {
            actorSprite.snapToBoardPosition(
              victimSprite.gamePiece.x,
              victimSprite.gamePiece.y
            );
            Audio.play('summon_04');
            TweenMax.to(actorSprite.puppet.scale, 0.2, {
              x: 1,
              y: 1,
              ease: Quad.easeOut,
            });
          },
        });
      });
    } else {
      setTimeout(() => {
        actorSprite.snapToBoardPosition(
          victimSprite.gamePiece.x,
          victimSprite.gamePiece.y
        );

        victimSprite.tileImg.visible = false;
        if (victimSprite.lootImg) {
          victimSprite.lootImg.visible = false;
        }

        const tileSprites = gameBoard.getTileSprites();
        for (var prop in tileSprites) {
          var tSprite = tileSprites[prop];
          var tile = tSprite.gamePiece;
          if (tile.x === actorSprite.x && tile.y === actorSprite.y) {
            if (tSprite.lootImg) {
              tSprite.lootImg.visible = true;
            } else {
              tSprite.tileImg.visible = true;
            }
            break;
          }
        }
      }, 500);
    }
  },
};
export default TeleportRendering;
