import ChatDispatcher from '~/flux/dispatchers/ChatDispatcher';

var ChatActions = {
  enterMessage(text) {
    ChatDispatcher.handleChatAction({
      actionType: ChatDispatcher.ENTER_MESSAGE,
      text,
    });
  },

  gameNotification(type, data) {
    ChatDispatcher.handleChatAction({
      actionType: ChatDispatcher.GAME_NOTIFICATION,
      type,
      data,
    });
  },

  joinNewChatRoom({ channel, locale }) {
    ChatDispatcher.handleChatAction({
      actionType: ChatDispatcher.JOIN_NEW_CHAT_ROOM,
      channel,
      locale,
    });
  },

  leaveChatRoom(room_name) {
    ChatDispatcher.handleChatAction({
      actionType: ChatDispatcher.LEAVE_CHAT_ROOM,
      room_name,
    });
  },

  selectChatRoom(room_name) {
    ChatDispatcher.handleChatAction({
      actionType: ChatDispatcher.SELECT_CHAT_ROOM,
      room_name,
    });
  },

  setChatTextColor(color) {
    ChatDispatcher.handleChatAction({
      actionType: ChatDispatcher.SET_CHAT_TEXT_COLOR,
      color,
    });
  },
};
export default ChatActions;
