import * as PIXI from 'pixi.js';
import FlyingArrow from '../../FlyingArrow';
import CanvasTools from '~/view/CanvasTools';
import BattleConfig from 'dt-common/constants/BattleConfig';
import BattleCalc from 'dt-common/battle_engine/BattleCalc';
import Audio from '~/Audio';

var frame;
var ropeImages;

const ChainPullRendering = {
  render: function (data, gameBoard, isCaverns) {
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const victimSprite = gameBoard.getAllPieceSprites()[data.victimId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
    const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

    render_movement &&
      actorSprite.puppet.getMovements().fireBow.movePuppet({ transitTime });

    var dist = CanvasTools.distanceFromSpriteToSprite(
      actorSprite,
      victimSprite
    );
    var numLinks = dist / 8;
    var arrowImage;

    setTimeout(
      () => {
        arrowImage = FlyingArrow(
          actorSprite,
          victimSprite,
          BattleCalc.projectileTime(
            BattleConfig.ARROW_SPEED,
            actorSprite.gamePiece,
            victimSprite.gamePiece
          )
        );

        ropeImages = [];

        for (var i = 0; i < numLinks; ++i) {
          var link = (ropeImages[i] = new PIXI.Graphics());
          link.beginFill(0xaad4d4);
          link.drawRect(-1, -1, 2, 2);
          link.endFill();
          link.x = arrowImage.x;
          link.y = arrowImage.y;
          gameBoard.addChild(link);
        }

        frame = requestAnimationFrame(onFrame);

        Audio.play('chain_pull');

        TweenMax.delayedCall(transitTime * 2, ChainPullRendering.clear);
      },
      transitTime * 1000 * (5 / 8)
    );

    function onFrame() {
      for (var i = 0; i < numLinks; ++i) {
        if (!ropeImages[i]) {
          continue;
        }
        ropeImages[i].x =
          actorSprite.x + ((arrowImage.x - actorSprite.x) * (i + 1)) / numLinks;
        ropeImages[i].y =
          actorSprite.y -
          13 +
          ((arrowImage.y - (actorSprite.y - 13)) * (i + 1)) / numLinks;
      }

      frame = requestAnimationFrame(onFrame);
    }
  },

  renderResult: function (data, gameBoard) {
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const victimSprite = gameBoard.getAllPieceSprites()[data.victimId];

    if (data.success) {
      switch (Math.floor(Math.random() * 3)) {
        case 0:
          Audio.play('arrow_hit_01');
          break;
        case 1:
          Audio.play('arrow_hit_02');
          break;
        case 2:
          Audio.play('arrow_hit_03');
          break;
      }

      Audio.play('chain_pull_02');

      cancelAnimationFrame(frame);
      frame = requestAnimationFrame(onFrame);
    } else {
      cancelAnimationFrame(frame);
      ChainPullRendering.clear();
    }

    function onFrame() {
      if (!actorSprite || !victimSprite) {
        return;
      }

      for (var i = 0; i < ropeImages.length; ++i) {
        if (!ropeImages[i]) {
          continue;
        }
        ropeImages[i].x =
          actorSprite.x +
          ((victimSprite.x - actorSprite.x) * (i + 1)) / ropeImages.length;
        ropeImages[i].y =
          actorSprite.y -
          13 +
          ((victimSprite.y - (actorSprite.y - 13)) * (i + 1)) /
            ropeImages.length;
      }

      frame = requestAnimationFrame(onFrame);
    }
  },

  clear: () => {
    for (const ropeImage of ropeImages) {
      if (!ropeImage) continue;
      TweenMax.to(ropeImage, 0.5, {
        alpha: 0,
        onComplete: (dead_img) => {
          dead_img?.parent?.removeChild(dead_img);
        },
        onCompleteParams: [ropeImage],
      });
      // PIXI sometimes bugs out here & doesn't remove the image. for good measure, clear after a timeout
      setTimeout(
        (dead_img) => {
          dead_img?.parent?.removeChild(dead_img);
        },
        500,
        ropeImage
      );
    }
    cancelAnimationFrame(frame);
  },
};
export default ChainPullRendering;
