import * as PIXI from 'pixi.js';
import { EventEmitter } from 'events';
import { Colors, Screens } from '~/constants';
import { TutorialActions } from '~/flux/actions';
import { TutorialStore, UIStore } from '~/flux/stores';
import text from '~/text';

const EditHeroNavBtns = function (currentScreenTag) {
  PIXI.Container.call(this);

  this.transitionIn = () => {};

  this.transitionOut = (onComplete) => {
    onComplete();
  };

  this.dispose = () => {
    this.eventEmitter = null;

    for (var prop in _buttons) {
      const btn = _buttons[prop];
      btn.tap = btn.click = null;
      btn.mouseover = btn.pointerover = null;
      btn.mouseout = btn.pointerout = null;
      btn.mousedown = btn.pointerdown = null;
      btn.mouseup = btn.pointerup = null;
    }
    _buttons = null;

    this.destroy();

    TutorialActions.destroyTutorialSpotlight();

    TutorialStore.removeListener(
      TutorialStore.TUTORIAL_SPOTLIGHT_VISIBLE,
      onTutorialSpotlightVisible
    );
  };

  this.eventEmitter = new EventEmitter();

  const LABEL_STYLE = {
    fontFamily: 'Courier New',
    fontStyle: 'bold',
    fontSize: '13px',
    fill: 0xffffff,
  };

  const makeBtn = (screenTag, labelText) => {
    const btn = new PIXI.Sprite();
    btn.texture = PIXI.utils.TextureCache['button_unselected.png'];
    btn.screenTag = screenTag;

    const label = (btn.label = new PIXI.Text(
      labelText.toUpperCase(),
      LABEL_STYLE
    ));
    label.x = -label.width / 2;
    label.y = -btn.height * 0.05 - label.height / 2;
    btn.addChild(label);

    btn.height = Math.min(btn.height, window.innerHeight * 0.04);
    btn.scale.x = btn.scale.y;

    btn.anchor.set(0.5, 0.5);
    this.addChild(btn);

    // add interactions
    btn.interactive = btn.buttonMode = true;
    btn.tap = btn.click = () => {
      // complete tutorial step 5 - navigate to Edit Abilities
      if (
        screenTag === Screens.ABILITIES &&
        !TutorialStore.getCompletedTutorialSteps().equipped_an_ability
      ) {
        TutorialActions.destroyTutorialSpotlight();
      }

      doCurrentScreenHighlight(btn.screenTag);
      this.eventEmitter.emit('EDIT_HERO_MENU_NAV', btn.screenTag);
    };

    // mouseover - tint selected hero's color
    btn.mouseover = btn.pointerover = () => {
      btn.tint = Colors[UIStore.getAll().focused_hero_handle];
    };

    // mouseout - tint back to white
    btn.mouseout = btn.pointerout = () => {
      btn.tint = 0xffffff;
    };

    // mousedown - scale down
    btn.mousedown = btn.touchstart = () => {
      btn.scale_buffer = { x: btn.scale.x, y: btn.scale.y };
      btn.scale = { x: btn.scale.x * 0.92, y: btn.scale.y * 0.92 };
    };

    // mouseup - scale up
    btn.mouseup = btn.touchend = () => {
      if (btn.scale_buffer) {
        btn.scale = btn.scale_buffer;
      }
    };

    const { equipped_an_ability, skipped_all } =
      TutorialStore.getCompletedTutorialSteps();
    if (
      screenTag === Screens.ABILITIES &&
      !equipped_an_ability &&
      !skipped_all
    ) {
      // tutorial step 5 - navigate to Edit Abilities
      setTimeout(() => {
        const { x, y } = btn.toGlobal({ x: 0, y: 0 });
        TutorialActions.makeTutorialSpotlight({
          radius: 105,
          x,
          y,
        });
      }, 800);
    }

    return btn;
  };

  var _buttons = {};
  _buttons[Screens.EQUIPMENT] = makeBtn(
    Screens.EQUIPMENT,
    text('ui.equipment.full')
  );
  _buttons[Screens.ABILITIES] = this.abilitiesBtn = makeBtn(
    Screens.ABILITIES,
    text('ui.abilities.full')
  );
  _buttons[Screens.ATTRIBUTES] = makeBtn(
    Screens.ATTRIBUTES,
    text('ui.attributes.full')
  );
  _buttons[Screens.AI_SETTINGS] = makeBtn(
    Screens.AI_SETTINGS,
    text('ui.ai_settings.full')
  );

  // button positioning
  var btn = null;
  var lastBtn = null;
  for (var prop in _buttons) {
    btn = _buttons[prop];

    if (lastBtn) {
      btn.x = Math.round(lastBtn.x + lastBtn.width + DT_CANVAS_GLOBALS.spacing);
    } else {
      btn.x = btn.width / 2;
    }

    btn.y = btn.height / 2;

    lastBtn = btn;
  }

  var _currentScreenTag = null;
  const doCurrentScreenHighlight = (newScreenTag) => {
    try {
      if (_currentScreenTag) {
        _buttons[_currentScreenTag].texture =
          PIXI.utils.TextureCache['button_unselected.png'];
        _buttons[_currentScreenTag].label.style.fill = 0xffffff;
      }

      _buttons[newScreenTag].texture =
        PIXI.utils.TextureCache['button_selected.png'];
      _buttons[newScreenTag].label.style.fill = 0x000000;

      _currentScreenTag = newScreenTag;
    } catch (err) {
      logError(err, {
        module: 'components/ui_screens/edit_hero/EditHeroNavBtns',
        func: 'doCurrentScreenHighlight',
        _currentScreenTag,
        newScreenTag,
      });
    }
  };
  doCurrentScreenHighlight(currentScreenTag);

  TutorialStore.on(
    TutorialStore.TUTORIAL_SPOTLIGHT_VISIBLE,
    onTutorialSpotlightVisible
  );

  function onTutorialSpotlightVisible() {
    if (_buttons) {
      _buttons[Screens.EQUIPMENT].interactive = false;
      _buttons[Screens.ATTRIBUTES].interactive = false;
      _buttons[Screens.AI_SETTINGS].interactive = false;
    }
  }
};
EditHeroNavBtns.prototype = Object.create(PIXI.Container.prototype);
EditHeroNavBtns.prototype.constructor = EditHeroNavBtns;
export default EditHeroNavBtns;
