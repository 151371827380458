export default {
  assassin: 'Ассасин',
  battle_mage: 'Боевой маг',
  dreamsmith: 'Чародей',
  invoker: 'Волшебник',
  knight: 'Рыцарь',
  mercenary: 'Наемник',
  monk: 'Монах',
  paladin: 'Паладин',
  ranger: 'Следопыт',
  shaman: 'Шаман',
  warlock: 'Колдун',

  dwarf: 'Дворф',
  elf: 'Эльф',
  faerie: 'Фея',
  poppet: 'марионетка',

  bandit: 'Бандит',
  cave_lizard: 'Пещерная ящерица',
  ectoplasm: 'Эктоплазма',
  fire_drake: 'Малыш Огненный Дракон',
  flying_jelly: 'Летающая Желе',
  ghost: 'Призрак',
  giant_rat: 'Огромная крыса',
  goblin: 'Гоблин',
  goblin_elementalist: 'Гоблин-заклинатель',
  goo: 'Идти',
  huge_bat: 'Большая летучая мышь',
  kobold: 'Кобольд',
  kobold_cleric: 'Кобольд-жрец',
  lich: 'Лич',
  phantasm: 'Фантазм',
  ratling_priest: 'Ретлинг-священник',
  ratling_warrior: 'Ретлинг-воин',
  skeletal_archer: 'Скелет-лучник',
  skeleton: 'Скелет',
  slime: 'Слизь',
  snake: 'Змея',
  voidwalker: 'Пустоход',
  wraith: 'Ревенант',

  // types
  amorphous: 'Аморфный',
  beast: 'Зверь',
  flying: 'Летающий',
  humanoid: 'Гуманоид',
  magic: 'Волшебный',
  undead: 'Нежить',
};
