import * as PIXI from 'pixi.js';
import GameStateStore from '~/flux/stores/GameStateStore';
import PinwheelPanel from '~/components/common/PinwheelPanel';
import GameItemIcon from '~/components/common/GameItemIcon';
import CanvasTools from '~/view/CanvasTools';
import MessageLog from '~/view/components/MessageLog_canvas';
import { AltarActions, UIActions } from '~/flux/actions';
import Tools from '~/Tools';
import text from '~/text';

const CandlePillar = function (candle, candleIndex) {
  PIXI.Container.call(this);

  this.dispose = () => {
    this.tap = this.click = null;

    this.destroyCandlePinwheel();

    if (_activeCandleIcon) {
      _activeCandleIcon.dispose();
    }

    TweenMax.killTweensOf(updateCandleCountdown);

    this.destroy();
  };

  this.destroyCandlePinwheel = () => {
    if (_pinwheel) {
      _pinwheel.dispose();
    }
  };

  const makeCandlePinwheel = () => {
    this.destroyCandlePinwheel();

    _pinwheel = new PinwheelPanel();
    _pinwheel.pinwheelDelay = 0.3;
    _pinwheel.radius = Math.min(
      window.innerWidth * 0.06,
      DT_CANVAS_GLOBALS.stage.height * 0.12
    );

    // combine items from global inventory and hero bags
    const all_items = [];
    const { inventory, hero_roster } = GameStateStore.getAll().gameState;
    for (const bag of inventory.bags) {
      if (bag) all_items.push(...bag.items);
    }
    for (const hero of Object.values(hero_roster)) {
      for (const bag of hero.bags || []) {
        if (bag) all_items.push(...bag.items);
      }
    }

    for (const item of all_items) {
      if (!item) continue;

      if (item?.type === 'prayer_candle') {
        const icon = new GameItemIcon(item);
        icon.width = _pinwheel.radius * 0.6;
        icon.scale.y = icon.scale.x;
        _pinwheel.addChild(icon);
        _pinwheel.pinwheelObjects.push(icon);

        TweenMax.from(icon, 1.4, { alpha: 0 });

        icon.mousedown = icon.touchstart = () => {
          UIActions.showModal({
            modal_key: 'EnterTextModal',
            modal_props: {
              modal_title: text('ui.devote_a_candle'),
              modal_prompt: text('ui.whom_shall_we_pray_for'),
              onConfirmed: ({ input }) => {
                AltarActions.usePrayerCandle(item.uid, candleIndex, input);
              },
            },
          });
          _pinwheel.dispose();
        };
      }
    }

    if (_pinwheel.pinwheelObjects.length === 0) {
      _pinwheel.dispose();
      MessageLog.addMessage(text('ui.no_prayer_candles'), 0xff0000);
      return;
    }

    _pinwheel.init();
    _pinwheel.x = this.toGlobal({ x: bd.width / 2, y: 0 }).x;
    _pinwheel.y = this.toGlobal({ x: bd.width / 2, y: 0 }).y;

    _pinwheel.dispose = function () {
      if (_pinwheel.pinwheelObjects) {
        for (var i = 0; i < _pinwheel.pinwheelObjects.length; ++i) {
          _pinwheel.pinwheelObjects[i].dispose();
        }

        _pinwheel.pinwheelObjects = null;
      }

      if (_pinwheel.initTween) {
        _pinwheel.initTween.kill();
        _pinwheel.initTween = null;
      }

      _pinwheel.removeChildren();

      if (_pinwheel.parent) {
        _pinwheel.parent.removeChild(_pinwheel);
      }

      if (_pinwheel.bg) {
        var bg = _pinwheel.bg;
        bg.mousedown = bg.touchstart = bg.tap = bg.click = null;
        bg.parent.removeChild(bg);
        _pinwheel.bg = null;
      }
    };

    var bg = (_pinwheel.bg = CanvasTools.addBackFill(
      DT_CANVAS_GLOBALS.stage,
      0x000000
    ));
    bg.alpha = 0.9;
    TweenMax.from(bg, 0.6, { alpha: 0 });
    bg.interactive = true;
    bg.mousedown =
      bg.touchstart =
      bg.tap =
      bg.click =
        (event) => {
          event.stopPropagation();
          _pinwheel.dispose();
        };

    DT_CANVAS_GLOBALS.stage.addChild(_pinwheel);
  };

  var _pinwheel;

  var bd = (this.bd = new PIXI.Sprite());
  bd.texture = PIXI.utils.TextureCache['altar/pillar.png'];
  this.addChild(bd);

  var _activeCandleIcon;
  var _candleCountdown;
  if (candle) {
    _activeCandleIcon = new GameItemIcon(candle, {
      disable_mouseover_scaling: true,
      frame: false,
      showBlackwash: false,
    });
    _activeCandleIcon.width = bd.width * 0.85;
    _activeCandleIcon.scale.y = _activeCandleIcon.scale.x;
    _activeCandleIcon.x = bd.x + bd.width / 2 - _activeCandleIcon.width * 0.03;
    _activeCandleIcon.y = -_activeCandleIcon.height * 0.17;
    this.addChild(_activeCandleIcon);

    _activeCandleIcon.img.interactive = false;

    _candleCountdown = new PIXI.Text('', {
      fontFamily: 'Courier New',
      fontStyle: 'bold',
      fontSize: 36,
      fill: 0xffffff,
    });
    _candleCountdown.y = bd.y + bd.height + 10;
    this.addChild(_candleCountdown);
  }

  const updateCandleCountdown = () => {
    if (_candleCountdown) {
      var timeLeft = candle.startTime + candle.duration - new Date().getTime();

      _candleCountdown.text = Tools.millisecondsToHHMMSS(timeLeft);
      _candleCountdown.x = bd.x + bd.width / 2 - _candleCountdown.width / 2;

      if (timeLeft > 0) {
        TweenMax.delayedCall(1.0, updateCandleCountdown);
      } else {
        AltarActions.getAltarState();
      }
    }
  };
  if (_candleCountdown) {
    updateCandleCountdown();
  }

  this.interactive = this.buttonMode = true;
  this.tap = this.click = makeCandlePinwheel;
};
CandlePillar.prototype = Object.create(PIXI.Container.prototype);
CandlePillar.prototype.constructor = CandlePillar;
export default CandlePillar;
