import PaymentsDispatcher from '~/flux/dispatchers/PaymentsDispatcher';

const PaymentsActions = {
  claimKongPurchases: function (purchase_id) {
    PaymentsDispatcher.handlePaymentsAction({
      actionType: PaymentsDispatcher.CLAIM_KONG_PURCHASES,
      purchase_id,
    });
  },

  createPaymentIntent({ purchase_selection, payment_method_id }) {
    PaymentsDispatcher.handlePaymentsAction({
      actionType: PaymentsDispatcher.CREATE_PAYMENT_INTENT,
      purchase_selection,
      payment_method_id,
    });
  },

  initSteamPurchase({ purchase_selection }) {
    PaymentsDispatcher.handlePaymentsAction({
      actionType: PaymentsDispatcher.INIT_STEAM_PURCHASE,
      purchase_selection,
    });
  },

  initYandexPurchase({ purchase_selection }) {
    PaymentsDispatcher.handlePaymentsAction({
      actionType: PaymentsDispatcher.INIT_YANDEX_PURCHASE,
      purchase_selection,
    });
  },
};
export default PaymentsActions;
