import * as PIXI from 'pixi.js';
import Game from 'dt-common/constants/Game';
import generateStarterEquipmentAndBags from 'dt-common/isomorphic-helpers/generateStarterEquipmentAndBags';
import UnitPuppet from '~/components/common/unit_puppets/UnitPuppet';
import XPBar from '~/components/common/XPBar';
import { Colors, Screens } from '~/constants';
import { GameStateStore, PlayerStore } from '~/flux/stores';
import { ChatActions, UIActions } from '~/flux/actions';
import text from '~/text';
import CanvasTools from '~/view/CanvasTools';
import HeroLevelView from '~/view/components/common/canvas/HeroLevelView';
import LightFromAbove from '~/view/game-screens/battle/canvas/game_board/action_renderings/LightFromAbove';

const HeroRosterPuppets = function () {
  PIXI.Container.call(this);

  this.transitionIn = () => {
    for (var i = 0; i < _puppets.length; ++i) {
      var delay = Math.random() * 0.6;

      TweenMax.from(_puppets[i], delay + 0.8, { alpha: 0, ease: Quad.easeIn });
      TweenMax.from(_puppets[i], delay + 0.8, {
        y: window.innerHeight * 0.57,
        ease: Linear.easeNone,
      });

      _puppets[i].inTransit = true;
      TweenMax.from(_puppets[i].scale, 0.8, {
        x: 0,
        y: 0,
        ease: Linear.easeNone,
        onCompleteParams: [_puppets[i]],
      }).delay(delay);

      TweenMax.delayedCall(
        delay + 0.3,
        (puppet) => {
          puppet.inTransit = false;
        },
        [_puppets[i]]
      );
    }
  };

  const destroyPuppets = () => {
    if (_puppets) {
      for (var i = 0; i < _puppets.length; ++i) {
        if (_puppets[i].levelView) {
          _puppets[i].levelView.dispose();
        }
        _puppets[i].dispose();
      }
      _puppets = null;
    }
  };

  var disposed = false;
  this.dispose = () => {
    _roster = null;

    _bg.mousedown = _bg.touchstart = null;
    _bg = null;

    destroyPuppets();

    if (DT_CANVAS_GLOBALS.stage.bg) {
      DT_CANVAS_GLOBALS.stage.bg.tap = DT_CANVAS_GLOBALS.stage.bg.click = null;
      DT_CANVAS_GLOBALS.stage.removeChild(DT_CANVAS_GLOBALS.stage.bg);
    }

    if (_heroUnlockDialog) {
      _heroUnlockDialog.dispose();
      DT_CANVAS_GLOBALS.stage.removeChild(_heroUnlockDialog);
      _heroUnlockDialog = null;
    }

    GameStateStore.removeListener(GameStateStore.HERO_UNLOCKED, onHeroUnlocked);

    this.removeChildren();

    disposed = true;
  };

  var _puppets = [];
  var _heroUnlockDialog = null;

  var _selectedPuppet = null;
  const selectedPuppetToHome = () => {
    if (!_selectedPuppet) {
      return;
    }

    const DURATION = 0.15;

    let oldSelectedPuppet = _selectedPuppet;

    TweenMax.to(oldSelectedPuppet.scale, DURATION, { x: 1, y: 1 });
    TweenMax.to(oldSelectedPuppet, DURATION, {
      x: oldSelectedPuppet.home_x,
      y: oldSelectedPuppet.home_y,
    });
    this.addChild(oldSelectedPuppet);
    oldSelectedPuppet.inTransit = false;

    oldSelectedPuppet.editBtn.tap = oldSelectedPuppet.editBtn.click = null;
    TweenMax.to(oldSelectedPuppet.editBtn.scale, DURATION, {
      x: 0,
      y: 0,
      onComplete: () => {
        oldSelectedPuppet.removeChild(oldSelectedPuppet.editBtn);
      },
    });

    oldSelectedPuppet.xpBar.dispose();
    oldSelectedPuppet.removeChild(oldSelectedPuppet.xbBar);

    TweenMax.to(oldSelectedPuppet.bg.scale, DURATION, {
      x: 0,
      y: 0,
      onComplete: () => {
        oldSelectedPuppet.removeChild(oldSelectedPuppet.bg);
      },
    });

    for (var i = 0; i < _puppets.length; ++i) {
      TweenMax.to(_puppets[i], DURATION, { alpha: 1 });
    }

    DT_CANVAS_GLOBALS.stage.bg.tap = DT_CANVAS_GLOBALS.stage.bg.click = null;
    DT_CANVAS_GLOBALS.stage.removeChild(DT_CANVAS_GLOBALS.stage.bg);

    _selectedPuppet.tap = _selectedPuppet.click = onUnlockedHeroClick;
  };

  const onUnlockedHeroClick = (event) => {
    if (disposed) {
      return;
    }

    if (
      _selectedPuppet &&
      event.target.actor.handle === _selectedPuppet.actor.handle
    ) {
      return;
    }

    selectedPuppetToHome();

    // a screen to handle clicking off the selected puppet
    DT_CANVAS_GLOBALS.stage.bg = CanvasTools.addBackFill(
      DT_CANVAS_GLOBALS.stage,
      0x000000
    );
    DT_CANVAS_GLOBALS.stage.bg.alpha = 0.01;
    DT_CANVAS_GLOBALS.stage.bg.interactive = true;
    DT_CANVAS_GLOBALS.stage.bg.tap = DT_CANVAS_GLOBALS.stage.bg.click = (
      event
    ) => {
      event.stopPropagation();
      selectedPuppetToHome();
    };

    const TARGET_SCALE = { x: 1.5, y: 1.5 };
    const TIME_SCALE = 0.3;

    // move the selected puppet front & center
    _selectedPuppet = event.target;
    _selectedPuppet.home_x = _selectedPuppet.x;
    _selectedPuppet.home_y = _selectedPuppet.y;

    const walk_duration = _selectedPuppet.getCooldown() * TIME_SCALE;

    TweenMax.to(_selectedPuppet.scale, walk_duration, {
      x: TARGET_SCALE.x,
      y: TARGET_SCALE.y,
      ease: Linear.easeNone,
    });
    TweenMax.to(_selectedPuppet, walk_duration, {
      x: Math.round(window.innerWidth / 2),
      y: Math.round(window.innerHeight * 0.43),
      ease: Linear.easeNone,
    });
    TweenMax.delayedCall(walk_duration, () => {
      _selectedPuppet.inTransit = false;
    });
    _selectedPuppet.x = this.x + _selectedPuppet.x * this.scale.x;
    _selectedPuppet.y = this.y + _selectedPuppet.y * this.scale.y;
    DT_CANVAS_GLOBALS.stage.addChild(_selectedPuppet);

    _selectedPuppet.run(Game.SOUTH, TIME_SCALE);

    // add back container
    var bg = (_selectedPuppet.bg = new PIXI.Container());
    bg._width = PUPPET_BODY_SCALE * 150;
    bg._height = PUPPET_BODY_SCALE * 220;
    CanvasTools.addBackFill(bg, Colors.ALMOST_BLACK);
    CanvasTools.addBorder(
      bg,
      'window_border_horiz.png',
      'window_border_vert.png',
      'window_border_horiz.png',
      'window_border_vert.png',
      0
    );
    bg.pivot.x = Math.round(bg._width / 2);
    bg.pivot.y = Math.round(bg._height * 0.55);
    _selectedPuppet.addChildAt(bg, 0);
    bg.scale.x /= TARGET_SCALE.x;
    bg.scale.y /= TARGET_SCALE.y;
    TweenMax.from(bg, 0.3, { alpha: 0 }).delay(0.15);
    TweenMax.from(bg.scale, 0.3, { x: 0, y: 0 }).delay(0.15);

    // add xp bar
    var xpb = (_selectedPuppet.xpBar = new XPBar(_selectedPuppet.actor));
    xpb.scale.x /= TARGET_SCALE.x;
    xpb.scale.y /= TARGET_SCALE.y;
    xpb.x = Math.round(-xpb.width * 0.4);
    xpb.y = Math.round(_selectedPuppet.height * TARGET_SCALE.y * 0.22);
    TweenMax.from(xpb, 0.3, { alpha: 0 }).delay(0.3);
    _selectedPuppet.addChild(xpb);

    // add hero edit button
    var eb = (_selectedPuppet.editBtn = new PIXI.Sprite());
    eb.texture = PIXI.utils.TextureCache['armory/class_customization_btn.png'];
    eb.scale.x = eb.scale.y = PUPPET_BODY_SCALE * 0.35;
    eb.anchor.x = eb.anchor.y = 0.5;
    eb.y = Math.round(xpb.y + xpb.height + eb.height / 2 + 10);
    TweenMax.from(eb.scale, 0.3, { x: 0, y: 0, ease: Elastic.easeOut }).delay(
      0.5
    );
    _selectedPuppet.addChild(eb);

    _selectedPuppet.tap = _selectedPuppet.click = (event) => {
      HomeScreenActions.heroEditBtnClick(event.target.actor);
      UIActions.uiNav({ screen_id: Screens.EDIT_HERO });
    };

    // fade out all other hero puppets
    for (var i = 0; i < _puppets.length; ++i) {
      if (_puppets[i].actor.handle !== _selectedPuppet.actor.handle) {
        TweenMax.to(_puppets[i], 0.5, { alpha: 0.3 }).delay(
          Math.random() * 0.5
        );
      }
    }
  };

  const PUPPET_BODY_SCALE = Math.min(
    window.innerHeight * 0.0035,
    window.innerWidth * 0.0013
  );
  const PUPPET_HORIZ_SPACING = window.innerWidth * 0.087;

  const makeLockedHeroView = (puppet) => {
    puppet.setTint(0x2f2f2f, true, true);

    const unlockBtn = new PIXI.Sprite();
    unlockBtn.texture = PIXI.utils.TextureCache['plus.png'];
    unlockBtn.x = Math.round(-unlockBtn.width / 2);
    unlockBtn.y = Math.round(-PUPPET_BODY_SCALE * 86);
    puppet.addChild(unlockBtn);

    puppet.tap = puppet.click = () => {
      selectedPuppetToHome();

      UIActions.showModal({
        modal_key: 'HeroUnlockModal',
        modal_props: { hero_handle: puppet.actor.handle },
      });
    };
  };

  const makeUnlockedHeroView = (puppet, namePlate) => {
    const hero = puppet.actor;

    namePlate.tint = Colors[hero.handle];
    namePlate.txt.style.fill = 0xffffff;
    namePlate.txt.style.dropShadowColor = 0x000000;

    var levelView = (puppet.levelView = new HeroLevelView({
      roster_hero: hero,
    }));
    levelView.height = Math.min(levelView.height, PUPPET_BODY_SCALE * 25);
    levelView.scale.x = levelView.scale.y;
    levelView.x = Math.round(-levelView.width / 2);
    levelView.y = Math.round(-PUPPET_BODY_SCALE * 96);
    puppet.addChild(levelView);

    puppet.tap = puppet.click = onUnlockedHeroClick;
  };

  this.width = window.innerWidth;
  this.height = window.innerHeight;
  var _bg = CanvasTools.addBackFill(this, Colors.ALMOST_BLACK);
  _bg.interactive = true;
  _bg.mousedown = _bg.touchstart = () => {
    selectedPuppetToHome();
  };

  var _roster;
  const init = () => {
    _puppets = [];
    _roster = GameStateStore.getAll().gameState.hero_roster;

    for (const hero of Object.values(_roster)) {
      const i = _puppets.length;
      const distFromCenter = Math.abs(
        i + 0.5 - Object.keys(_roster).length / 2
      );

      // spoof some starter equipment so he's not nekkid
      const { starter_equipment, starter_bags } =
        generateStarterEquipmentAndBags(hero.handle);
      const equipment_assignment = {};
      for (const [slot, item] of Object.entries(starter_equipment)) {
        equipment_assignment[slot] = item.uid;
      }

      const puppet = new UnitPuppet({
        body_scale: PUPPET_BODY_SCALE,
        roster_hero: { bags: starter_bags },
        unit_build: {
          ...hero,
          equipment_assignment,
        },
      });

      puppet.x = Math.round((i + 0.72) * PUPPET_HORIZ_SPACING);
      puppet.y = Math.round(window.innerHeight * 0.45 + distFromCenter * 10);
      puppet.interactive = puppet.buttonMode = true;
      this.addChild(puppet);
      _puppets.push(puppet);

      var namePlate = new PIXI.Sprite();
      namePlate.texture =
        PIXI.utils.TextureCache['armory/class_label_default.png'];
      namePlate.txt = new PIXI.Text(
        text('heroes.' + hero.handle + '.name').toUpperCase(),
        {
          fontFamily: 'Courier New',
          fontStyle: 'bold',
          fontSize: 22,
          fill: 0x000000,
          dropShadow: true,
          dropShadowDistance: 1,
          dropShadowColor: 0xaaaaaa,
        }
      );
      namePlate.txt.x = Math.round(
        namePlate.width / 2 - namePlate.txt.width / 2
      );
      namePlate.txt.y = Math.round(
        namePlate.height * 0.65 - namePlate.txt.height / 2
      );
      namePlate.addChild(namePlate.txt);
      namePlate.height = Math.min(namePlate.height, PUPPET_BODY_SCALE * 14);
      namePlate.scale.x = namePlate.scale.y;
      namePlate.x = Math.round(-namePlate.width / 2);
      namePlate.y = Math.round(namePlate.height * 2);
      puppet.addChild(namePlate);

      if (hero.level === 0) {
        makeLockedHeroView(puppet);
      } else {
        makeUnlockedHeroView(puppet, namePlate);
      }

      puppet.hitArea = new PIXI.Rectangle(
        -puppet.width / 2,
        -puppet.height * 0.8,
        puppet.width,
        puppet.height * 1.2
      );
    }
  };
  init();

  const onHeroUnlocked = (heroHandle) => {
    destroyPuppets();
    init();

    for (let i = 0; i < _puppets.length; ++i) {
      if (_puppets[i].actor.handle === heroHandle) {
        const lightEffect = new LightFromAbove(_puppets[i]);
        lightEffect.width = _puppets[i].width;
        break;
      }
    }

    const { displayName } = PlayerStore.getAll();
    if (
      displayName &&
      !['??????', text('ui.new_player')].includes(displayName)
    ) {
      ChatActions.gameNotification('hero_unlocked', { heroHandle });
    }
  };
  GameStateStore.on(GameStateStore.HERO_UNLOCKED, onHeroUnlocked);
};
HeroRosterPuppets.prototype = Object.create(PIXI.Container.prototype);
HeroRosterPuppets.prototype.constructor = HeroRosterPuppets;
export default HeroRosterPuppets;
