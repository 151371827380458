import * as PIXI from 'pixi.js';
import CanvasTools from '~/view/CanvasTools';
import AbilityTooltip from './AbilityTooltip';
import ItemTooltip from './ItemTooltip';
import PrimaryAttributeTooltip from './PrimaryAttributeTooltip';
import SecondaryAttributeTooltip from './SecondaryAttributeTooltip';
import UITooltip from './UITooltip';

const Tooltip = function (type, data, tt_caller = null, options = {}) {
  try {
    PIXI.Container.call(this);

    this.dispose = () => {
      TweenMax.to(this, 0.17, {
        alpha: 0,
        onComplete: () => {
          if (_content_sprite) {
            _content_sprite.dispose();
          }
          this.destroy();
        },
      });
    };

    if (DT_CANVAS_GLOBALS.tooltips_disabled) {
      return;
    }

    let _content_sprite;

    // generate the tooltip content
    switch (type) {
      case 'ability':
        _content_sprite = new AbilityTooltip(data);
        break;

      case 'item':
        _content_sprite = new ItemTooltip(data);
        break;

      case 'primary_attribute':
        _content_sprite = new PrimaryAttributeTooltip(data);
        break;

      case 'secondary_attribute':
        _content_sprite = new SecondaryAttributeTooltip(data);
        break;

      case 'ui':
        _content_sprite = new UITooltip(data);
        break;

      default:
        throw new Error('invalid tooltip type');
    }

    // add right & bottom padding
    this._width = _content_sprite.width + DT_CANVAS_GLOBALS.spacing * 2;
    this._height = _content_sprite.height + DT_CANVAS_GLOBALS.spacing * 2;

    // make backdrop & border
    CanvasTools.addBackFill(this, 0x070707, 0.985);
    CanvasTools.addBorder(
      this,
      'chat_border_horiz.png',
      'chat_border_vert.png',
      'chat_border_horiz.png',
      'chat_border_vert.png',
      0
    );

    // position & add the content sprite
    _content_sprite.x = _content_sprite.y = Math.round(
      DT_CANVAS_GLOBALS.spacing
    );
    this.addChild(_content_sprite);

    // calculate the tooltip's x-position
    if (tt_caller) {
      // orient the tooltip to the sprite that called it
      const global_pos = tt_caller.toGlobal({ x: 0, y: 0 });
      const pivot_or_anchor_x = tt_caller.anchor
        ? tt_caller.anchor.x
        : tt_caller.pivot.x;
      // for x-position, try to place it to the right of the caller sprite.
      // if that would cause it to run off the screen, place it to the left
      this.x =
        global_pos.x +
        tt_caller.width * (1 - pivot_or_anchor_x) +
        DT_CANVAS_GLOBALS.spacing * 2.5;
      if (
        this.x + this._width + DT_CANVAS_GLOBALS.spacing * 2 >
        window.innerWidth
      ) {
        this.x =
          global_pos.x -
          tt_caller.width * pivot_or_anchor_x -
          this._width -
          DT_CANVAS_GLOBALS.spacing * 2;
      }
      // y-position ...
      this.y =
        global_pos.y +
        (global_pos.y < DT_CANVAS_GLOBALS.stage.height / 2
          ? tt_caller.height + DT_CANVAS_GLOBALS.spacing * 3
          : -DT_CANVAS_GLOBALS.spacing * 3);
    } else {
      // determine on which side of the mouse to position this
      const mouse_pos =
        DT_CANVAS_GLOBALS._pixi_app.renderer.plugins.interaction.mouse.global;
      // for x-position, try to place it to the right of the mouse.
      // if that would cause it to run off the screen, place it to the left
      this.x = mouse_pos.x + DT_CANVAS_GLOBALS.spacing;
      if (
        this.x + this._width + DT_CANVAS_GLOBALS.spacing * 2.5 >
        window.innerWidth
      ) {
        this.x = mouse_pos.x - this._width - DT_CANVAS_GLOBALS.spacing * 2.5;
      }
      // for y-position, try to place the top just above the cursor.
      this.y = mouse_pos.y - DT_CANVAS_GLOBALS.spacing * 3;
    }

    // correct y-position if it leaks over the footer
    if (this.y + this._height > window.footer_y) {
      this.y = window.footer_y - DT_CANVAS_GLOBALS.spacing - this._height;
    }

    this.x = Math.round(this.x);
    this.y = Math.round(this.y);

    DT_CANVAS_GLOBALS.overlay_stage.addChild(this);
  } catch (err) {
    logError(
      err,
      Object.assign(
        {
          module: 'components/tooltips/Tooltip',
          func: 'constructor',
          type,
        },
        data,
        options
      )
    );
  }
};
Tooltip.prototype = Object.create(PIXI.Container.prototype);
Tooltip.prototype.constructor = Tooltip;
export default Tooltip;
