import { Dispatcher } from 'flux';

const ChatDispatcher = Object.assign(new Dispatcher(), {
  ENTER_MESSAGE: 'ENTER_MESSAGE',
  GAME_NOTIFICATION: 'GAME_NOTIFICATION',
  JOIN_NEW_CHAT_ROOM: 'JOIN_NEW_CHAT_ROOM',
  LEAVE_CHAT_ROOM: 'LEAVE_CHAT_ROOM',
  SELECT_CHAT_ROOM: 'SELECT_CHAT_ROOM',
  SET_CHAT_TEXT_COLOR: 'SET_CHAT_TEXT_COLOR',

  handleChatAction: function (action) {
    this.dispatch({
      source: 'CHAT_ACTION',
      action: action,
    });
  },
});
export default ChatDispatcher;
