import Colors from '~/constants/Colors';
import unit_names from './unit_names';

export default {
  // abilities
  melee_attack: (actorName, victimName, data) => {
    return 'The ' + actorName + ' attacks the ' + victimName + '.';
  },

  // alchemy
  burning_hands: (actorName, victimName, data) => {
    return (
      'The ' +
      actorName +
      ' scorches the ' +
      victimName +
      ' with Burning Hands.'
    );
  },

  fumigate: (actorName, victimName, data) => {
    return 'The ' + actorName + ' releases a cloud of poison gas!';
  },

  ice_nine: (actorName, victimName, data) => {
    return 'The ' + actorName + ' hurls a bottle of Ice Nine...';
  },

  lightning: (actorName, victimName, data) => {
    return 'The ' + actorName + ' casts Lightning!';
  },

  molotov_cocktail: (actorName, victimName, data) => {
    return 'The ' + actorName + ' lights a Molotov Cocktail and throws it!';
  },

  poison_weapon: (actorName, victimName, data) => {
    return (
      'The ' + actorName + ' laces the ' + victimName + "'s weapon with poison."
    );
  },

  road_to_nowhere: (actorName, victimName, data) => {
    return 'The ' + actorName + ' tosses a vial of strange liquid...';
  },

  // armor use
  bash: (actorName, victimName, data) => {
    return (
      'The ' +
      actorName +
      ' throws a heavy shoulder into the ' +
      victimName +
      '!'
    );
  },

  shield_block: (actorName, victimName, data) => {
    return 'The ' + actorName + ' raises their shield.';
  },

  suture: (actorName, victimName, data) => {
    return 'The ' + actorName + ' mends the ' + victimName + "'s' wound.";
  },

  // bow use
  arrow_shot: (actorName, victimName, data) => {
    return 'The ' + actorName + ' fires an arrow at the ' + victimName + '.';
  },

  blunt_arrow: (actorName, victimName, data) => {
    return (
      'The ' + actorName + ' fires a blunt arrow at the ' + victimName + '.'
    );
  },

  chain_pull: (actorName, victimName, data) => {
    return (
      'The ' + actorName + ' fires a chained arrow at the ' + victimName + '.'
    );
  },

  leg_shot: (actorName, victimName, data) => {
    return (
      'The ' + actorName + ' fires an arrow at the ' + victimName + "'s leg."
    );
  },

  multi_shot: (actorName, victimName, data) => {
    return 'The ' + actorName + ' fires several arrows simultaneously!';
  },

  razor_shot: (actorName, victimName, data) => {
    return (
      'The ' + actorName + ' fires a razor arrow at the ' + victimName + '!'
    );
  },

  // conjuration
  summon_dwarf: (actorName, victimName, data) => {
    return 'The ' + actorName + ' summons a fighting Dwarf!';
  },

  summon_elf: (actorName, victimName, data) => {
    return 'The ' + actorName + ' summons an Elven archer!';
  },

  summon_faerie: (actorName, victimName, data) => {
    return 'The ' + actorName + ' summons a healing Faerie!';
  },

  summon_option: (actorName, victimName, data) => {
    return 'The ' + actorName + ' summons an Option for ' + victimName + '.';
  },

  teleport: (actorName, victimName, data) => {
    return 'The ' + actorName + ' teleports!';
  },

  void_spike: (actorName, victimName, data) => {
    return 'The ' + actorName + ' summons a Void Spike.';
  },

  // illusion
  devil_mask: (actorName, victimName, data) => {
    return 'The ' + actorName + ' suddenly looks like an evil demon!';
  },

  faux_self: (actorName, victimName, data) => {
    return 'The ' + actorName + ' seems to multiply!';
  },

  infect_mind: (actorName, victimName, data) => {
    return 'The ' + actorName + ' infects the ' + victimName + "'s mind...";
  },

  psyche_out: (actorName, victimName, data) => {
    return 'The ' + actorName + ' casts Psyche Out.';
  },

  sleight_of_hand: (actorName, victimName, data) => {
    return 'The ' + actorName + ' performs a strange magic trick.';
  },

  // melee combat
  armor_break: (actorName, victimName, data) => {
    return 'The ' + actorName + ' smashes the ' + victimName + "'s armor.";
  },

  mortal_strike: (actorName, victimName, data) => {
    return (
      'The ' + actorName + ' attempts a killing blow on the ' + victimName + '.'
    );
  },

  roundhouse: (actorName, victimName, data) => {
    return 'The ' + actorName + ' performs a roundhouse attack!';
  },

  sky_hammer: (actorName, victimName, data) => {
    return 'The ' + actorName + ' leaps high in the air and thunders down!';
  },

  slam: (actorName, victimName, data) => {
    return 'The ' + actorName + ' slams the ' + victimName + '.';
  },

  slash: (actorName, victimName, data) => {
    return 'The ' + actorName + ' slashes at the ' + victimName + '.';
  },

  taunt: (actorName, victimName, data) => {
    return (
      'The ' + actorName + ' taunts the ' + victimName + ' with a rude gesture.'
    );
  },

  // movement
  adrenaline: (actorName, victimName, data) => {
    return 'Adrenaline flows through the ' + actorName + "'s blood.";
  },

  leap: (actorName, victimName, data) => {
    return 'The ' + actorName + ' leaps!';
  },

  stance_dancing: (actorName, victimName, data) => {
    return 'The ' + actorName + ' changes their stance.';
  },

  tussle: (actorName, victimName, data) => {
    return (
      'The ' + actorName + ' hurls the ' + victimName + ' over their shoulder!'
    );
  },

  // spirit
  bless: (actorName, victimName, data) => {
    return 'The ' + actorName + ' blesses the ' + victimName + '.';
  },

  healing_prayer: (actorName, victimName, data) => {
    return 'The ' + actorName + ' says a prayer for the ' + victimName + '.';
  },

  holy_force: (actorName, victimName, data) => {
    return 'A Holy Force protects the ' + actorName + '!';
  },

  mystic_focus: (actorName, victimName, data) => {
    return 'The ' + actorName + ' meditates.';
  },

  sanctuary: (actorName, victimName, data) => {
    return 'The ' + actorName + ' sanctifies the area!';
  },

  send_mana: (actorName, victimName, data) => {
    return 'The ' + actorName + ' channels energy to the ' + victimName + '.';
  },

  // conditions
  armor_broken: (instigatorName, ownerName, data) => {
    return (
      'The ' + ownerName + "'s armor class is reduced by " + data.potency + '.'
    );
  },

  bleeding: (instigatorName, ownerName, data) => {
    return (
      'The ' +
      ownerName +
      ' now bleeds ' +
      Math.floor(data.potency) +
      ' HP per 3 seconds.'
    );
  },

  blessed: (instigatorName, ownerName, data) => {
    return 'The ' + ownerName + ' is blessed!';
  },

  defensive_stance: (instigatorName, ownerName, data) => {
    return 'The ' + ownerName + ' assumes a defensive stance.';
  },

  enraged: (instigatorName, ownerName, data) => {
    return (
      'The ' + ownerName + ' flies into a rage at the ' + instigatorName + '.'
    );
  },

  inspired: (instigatorName, ownerName, data) => {
    return 'The ' + ownerName + ' is inspired by the ' + instigatorName + '!';
  },

  offensive_stance: (instigatorName, ownerName, data) => {
    return 'The ' + ownerName + ' assumes an offensive stance.';
  },

  poisoned: (instigatorName, ownerName, data) => {
    return (
      'The ' +
      ownerName +
      ' has been poisoned at a potency of ' +
      data.potency +
      '.'
    );
  },

  poisoner: (instigatorName, ownerName, data) => {
    return (
      'The ' +
      ownerName +
      "'s weapon now has " +
      data.charges +
      ' charges of poison.'
    );
  },

  scared: (instigatorName, ownerName, data) => {
    return (
      'The ' +
      ownerName +
      ' is suddenly terrified by the ' +
      instigatorName +
      '.'
    );
  },

  slowed: (instigatorName, ownerName, data) => {
    return 'The ' + ownerName + "'s movements are slowed.";
  },

  stunned: (instigatorName, ownerName, data) => {
    return 'The ' + ownerName + ' is stunned!';
  },

  // events
  block: (actorName, victimName, data) => {
    return {
      text: 'The ' + actorName + ' blocks the attack!',
      color: 0xffffff,
    };
  },

  damage: (actorName, victimName, data) => {
    return {
      text:
        'The ' +
        victimName +
        ' takes ' +
        data.event.damage +
        ' damage' +
        getMod() +
        '!',
      color: Colors.HP_RED,
    };

    function getMod() {
      try {
        switch (data.event.cause.handle) {
          case 'poisoned':
            return ' from poison';
          case 'bleeding':
            return ' from bleeding';
        }
      } catch (error) {
        console.warn('Caught error: ' + error);
      }

      return '';
    }
  },

  death: (actorName, victimName, data) => {
    return {
      text: 'The ' + victimName + ' is destroyed!',
      color: 0xffffff,
    };
  },

  dodge: (actorName, victimName, data) => {
    return {
      text: 'The ' + actorName + ' dodges the attack!',
      color: 0xffffff,
    };
  },

  gain_ap: (actorName, victimName, data) => {
    return {
      text: 'The ' + victimName + ' gains ' + data.event.potency + ' AP!',
      color: Colors.AP_ORANGE,
    };
  },

  gain_mp: (actorName, victimName, data) => {
    return {
      text: 'The ' + victimName + ' gains ' + data.event.potency + ' MP!',
      color: Colors.MP_PURPLE,
    };
  },

  heal: (actorName, victimName, data) => {
    return {
      text:
        'The ' + victimName + ' is healed for ' + data.event.potency + ' HP!',
      color: 0x00ff00,
    };
  },

  ice_proc: (actorName, victimName, data) => {
    return {
      text: 'The ' + victimName + ' is frozen!',
      color: 0xffffff,
    };
  },

  knockback: (actorName, victimName, data) => {
    return {
      text: 'The ' + actorName + ' is knocked to the ground!',
      color: 0xffffff,
    };
  },

  lose_mp: (actorName, victimName, data) => {
    return {
      text: 'The ' + victimName + ' loses ' + data.event.potency + ' MP!',
      color: Colors.MP_PURPLE,
    };
  },

  new_unit_turn: ({ ai_unit }) => {
    const handle = ai_unit.hero_handle || ai_unit.handle;
    // const candidates = [
    //   `The ${unit_names[handle]} springs into action.`,
    //   `The ${unit_names[handle]}'s eyes flare in anger!`,
    //   `The ${unit_names[handle]} is ready.`,
    //   `The ${unit_names[handle]} steps forward.`,
    //   `The ${unit_names[handle]} makes a move!`,
    //   `It's the ${unit_names[handle]}'s moment to take action.`,
    // ];
    return {
      // text: candidates[Math.floor(Math.random() * candidates.length)],
      text: `*** The ${unit_names[handle]} springs into action. ***`,
      color: Colors[handle],
    };
  },

  option_attack: (actorName, victimName, data) => {
    return {
      text: 'The ' + actorName + "'s' option attacks the  " + victimName + '.',
      color: 0xffffff,
    };
  },

  resist: (actorName, victimName, data) => {
    return {
      text: 'The ' + actorName + ' resists!',
      color: 0xffffff,
    };
  },

  unit_spawned: (actorName, victimName, data) => {
    var unit = data.event.unit;

    if (unit.hero) {
      return null;
    } else
      return {
        text:
          'A level ' +
          unit.level +
          ' ' +
          unit_names[unit.hero_handle || unit.handle] +
          ' appears!',
        color: Colors[unit.handle],
      };
  },
};
