<template>
  <Dialog
    :open="true"
    @close="props.onClose"
  >
    <div
      class="fixed inset-0 flex items-center justify-center p-4 z-20 bg-zinc-700/90"
    >
      <DialogPanel
        class="relative border border-white w-[800px] h-[550px] max-h-[90vh] flex flex-col bg-black"
      >
        <canvas
          id="modal_canvas"
          ref="modal_canvas"
          class="absolute z-0 w-[800px] h-[550px] pointer-events-none"
        ></canvas>

        <DialogTitle class="border-b border-white p-3 text-yellow-300">
          {{ text('ui.swap_hero_builds') }}
        </DialogTitle>

        <div
          v-if="!!state.modal_pixi_app?.stage"
          class="w-full h-full"
        >
          <!-- Hero builds list (with puppet anchors) -->
          <div
            v-if="state.focused_hero_builds"
            :key="state.hero_builds_key"
            class="w-full h-full p-8 flex flex-wrap justify-center items-center"
          >
            <div
              v-for="({ _id, hero_handle }, i) of state.focused_hero_builds"
              :id="`SwapEngagementModal_puppet_anchor_${_id}`"
              class="w-[18vh] h-[18vh] cursor-pointer hover:outline hover:outline-yellow-200/30 rounded-full pointer-events-auto"
              @click="onHeroBuildClicked({ hero_build_id: _id, hero_handle })"
            ></div>
          </div>
          <HeroRosterView
            v-else
            :puppet_parent="state.modal_pixi_app.stage"
            :height="480"
            class="px-8"
          />
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script setup>
import {
  nextTick,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  watch,
} from 'vue';
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/vue';
import { GameStateStore, HeroBuildStore, UIStore } from '~/flux/stores';
import text from '~/text';
import CanvasTools from '~/view/CanvasTools';
import { PuppetWithNameplate } from '~/view/components/common/canvas';
import { HeroRosterView } from '~/view/components/common/DOM';

let _build_puppets;

const props = defineProps({
  onClose: {
    type: Function,
    required: true,
  },
  onHeroBuildSelected: {
    type: Function,
    required: true,
  },
});
const modal_canvas = ref(null);

const state = reactive({
  focused_hero_builds: null,
  hero_builds_key: 0,
  modal_pixi_app: null,
});

watch(modal_canvas, (val) => {
  if (val && !state.modal_pixi_app) {
    state.modal_pixi_app = CanvasTools.makePixiRenderer({
      canvas_id: 'modal_canvas',
      canvas_ele: val,
    });
  }
});

onMounted(() => {
  UIStore.on(UIStore.HERO_FOCUSED, onHeroFocused);
});

onBeforeUnmount(() => {
  disposeBuildPuppets();
  state.modal_pixi_app?.destroy();
  UIStore.removeListener(UIStore.HERO_FOCUSED, onHeroFocused);
});

function disposeBuildPuppets() {
  for (const puppet of _build_puppets || []) {
    puppet?.dispose();
  }
  _build_puppets = null;
}

function onHeroFocused(focused_hero_handle) {
  // populate our array focused hero builds
  state.focused_hero_builds =
    HeroBuildStore.getAll().hero_builds[focused_hero_handle];
  state.hero_builds_key++;
  nextTick(makeBuildPuppets);
}

function makeBuildPuppets() {
  disposeBuildPuppets();
  _build_puppets = [];

  const roster_hero =
    GameStateStore.getAll().gameState.hero_roster[
      UIStore.getAll().focused_hero_handle
    ];

  for (const built_hero of state.focused_hero_builds) {
    const puppet = new PuppetWithNameplate({
      roster_hero,
      built_hero,
      scale_mod: 2,
      show_build_name: true,
      build_name_label_only: true,
    });

    // get dom anchor n shit
    const dom_anchor = document.getElementById(
      `SwapEngagementModal_puppet_anchor_${built_hero._id}`
    );
    const { offsetTop, offsetLeft } = dom_anchor;
    const { width, height } = dom_anchor.getBoundingClientRect();
    puppet.x = offsetLeft + width / 2;
    puppet.y = offsetTop + height * 0.72;
    state.modal_pixi_app.stage.addChild(puppet);
    _build_puppets.push(puppet);

    puppet.transitionIn();
  }
}

function onHeroBuildClicked({ hero_build_id, hero_handle }) {
  props.onHeroBuildSelected({ hero_build_id, hero_handle });
  props.onClose();
}
</script>
