import { Dispatcher } from 'flux';

const PaymentsDispatcher = Object.assign(new Dispatcher(), {
  NAME: 'PAYMENTS_DISPATCHER',

  CLAIM_KONG_PURCHASES: 'CLAIM_KONG_PURCHASES',
  CREATE_PAYMENT_INTENT: 'CREATE_PAYMENT_INTENT',
  INIT_STEAM_PURCHASE: 'INIT_STEAM_PURCHASE',
  INIT_YANDEX_PURCHASE: 'INIT_YANDEX_PURCHASE',

  handlePaymentsAction(action) {
    this.dispatch({
      source: PaymentsDispatcher.NAME,
      action,
    });
  },
});
export default PaymentsDispatcher;
