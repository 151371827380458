export default {
  error: {
    no_muting_self: 'Вы не можете отключить себя.',
    no_player_by_that_name: 'Игрок с таким именем не найден.',
    player_name_rule_1: 'Имя не должно начинаться или заканчиваться пробелом.',
    player_name_rule_2: 'Имя должно содержать от 5 до 20 символов.',
    hero_build_name_rule_2: 'Имя должно содержать от 1 до 20 символов.',
    pronoun_rule_2: 'Местоимение должно содержать от 1 до 10 символов.',
    player_name_rule_3:
      'Имя может содержать символы Юникода, пробелы или любой из следующих символов: `~!@#$%^&*()-_+=[]{}|\\/;\':",.<>?',
    player_name_rule_4: 'Имя не может содержать последовательные пробелы.',
    player_name_rule_5:
      'Имя не может содержать последовательные символы подчеркивания.',
    username_taken: 'Это имя пользователя занято!',
  },
  illegal: {
    ability_at_max_level: 'Способность уже на максимальном уровне!',
    affinity_at_max_level: 'Сродство уже на максимальном уровне!',
    affinity_at_zero: 'Сродство уже на нуле!',
    caverns_enemy_blocking: 'Враг блокирует выход.',
    no_inn_challenges_left: 'не осталось сражений',
    no_opponent_found: 'Противник не найден.',
    not_enough_ability_points: 'Недостаточно очков способностей!',
    not_enough_attribute_points: 'Недостаточно очков атрибутов!',
    opponent_not_online: 'Оппонент в данный момент не в сети.',
    prayed_for_self: 'Хм. Эгоистично.',
  },
};
