export default {
  dye: 'Equipment Dye',
  miracle_dye: 'Miracle Dye',
  hero_xp_boost: 'Hero XP Boost',
  prayer_candle: 'Prayer Candle',
  bag: 'Bag',
  item_container_space: (container_size) => `Space: ${container_size}`,

  // light helmet
  headband: 'Headband',
  headdress: 'Headdress',
  circlet: 'Circlet',
  diadem: 'Diadem',
  crown: 'Crown',

  // med helmet
  skullcap: 'Skullcap',
  leather_helm: 'Leather Helm',
  studded_helm: 'Studded Helm',
  coif: 'Coif',
  dragonscale_helm: 'Dragonscale Helm',

  // heavy helmet
  copper_helm: 'Copper Helm',
  bronze_helm: 'Bronze Helm',
  iron_helm: 'Iron Helm',
  steel_helm: 'Steel Helm',
  mithril_helm: 'Mithril Helm',

  // light torsoe
  rags: 'Rags',
  linen_cloak: 'Linen Cloak',
  brocade_cloak: 'Brocade Cloak',
  spidersilk_cloak: 'Spidersilk Cloak',
  mageweave_cloak: 'Mageweave Cloak',

  // med torsoe
  hide_vest: 'Hide Vest',
  leather_vest: 'Leather Vest',
  studded_vest: 'Studded Vest',
  chainmail_vest: 'Chain-mail Vest',
  dragonscale_vest: 'Dragonscale Vest',

  // heavy torsoe
  copper_plate: 'Copper Plate',
  bronze_plate: 'Bronze Plate',
  iron_plate: 'Iron Plate',
  steel_plate: 'Steel Plate',
  mithril_plate: 'Mithril Plate',

  // light boots
  sandals: 'Sandals',
  linen_shoes: 'Linen Shoes',
  brocade_slippers: 'Brocade Slippers',
  spidersilk_slippers: 'Spidersilk Slippers',
  mageweave_slippers: 'Mageweave Slippers',

  // med boots
  hide_boots: 'Hide Boots',
  leather_boots: 'Leather Boots',
  studded_boots: 'Studded Boots',
  chainmail_boots: 'Chain-mail Boots',
  dragonscale_boots: 'Dragonscale Boots',

  // heavy boots
  copper_boots: 'Copper Boots',
  bronze_boots: 'Bronze Boots',
  iron_boots: 'Iron Boots',
  steel_boots: 'Steel Boots',
  mithril_boots: 'Mithril Boots',

  // daggers
  shiv: 'Shiv',
  dagger: 'Dagger',
  slicer: 'Slicer',
  stiletto: 'Stiletto',
  dragon_tooth: 'Dragon Tooth',

  // hammers
  club: 'Club',
  hammer: 'Hammer',
  greathammer: 'Greathammer',
  grandhammer: 'Grandhammer',
  thors_hammer: "Thor's Hammer",

  // staves
  walking_stick: 'Walking Stick',
  quarterstaff: 'Quarterstaff',
  oaken_staff: 'Oaken Staff',
  greatstaff: 'Greatstaff',
  wizards_staff: "Wizard's Staff",

  // swords
  shortsword: 'Shortsword',
  broadsword: 'Broadsword',
  longsword: 'Longsword',
  falchion: 'Falchion',
  anduril: 'Anduril',

  // bows
  shortbow: 'Shortbow',
  longbow: 'Longbow',
  composite_bow: 'Composite Bow',
  mechanical_bow: 'Mechanical Bow',
  elven_bow: 'Elven Bow',

  // shields
  wooden_shield: 'Wooden Shield',
  buckler: 'Buckler',
  round_shield: 'Round Shield',
  kite_shield: 'Kite Shield',
  tower_shield: 'Tower Shield',

  // books
  folio: 'Folio',
  scroll: 'Scroll',
  book: 'Book',
  tome: 'Tome',
  compendium: 'Compendium',

  // trinkets
  bauble: 'Bauble',
  trinket: 'Trinket',
  figurine: 'Figurine',
  totem: 'Totem',
  orb: 'Orb',

  // ring
  ring: 'Ring',

  prefixes: {
    blessed: 'Blessed',
    clumsy: 'Clumsy',
    coarse: 'Coarse',
    crude: 'Crude',
    cursed: 'Cursed',
    dull: 'Dull',
    elegant: 'Elegant',
    emblazoned: 'Emblazoned',
    fine: 'Fine',
    glorious: 'Glorious',
    glowing: 'Glowing',
    heavy: 'Heavy',
    lavish: 'Lavish',
    light: 'Light',
    magical: 'Magical',
    majestic: 'Majestic',
    modest: 'Modest',
    ornate: 'Ornate',
    rotting: 'Rotting',
    runed: 'Runed',
    scuffed: 'Scuffed',
    shining: 'Shining',
    sleek: 'Sleek',
    ulrich: "Ulrich's",
    garland: "Garland's",
    vetronius: "Vetronius'",
    sindri: "Sindri's",
    cousity: "Cousity's",

    // armor
    fitted: 'Fitted',
    padded: 'Padded',
    patched: 'Patched',
    ragged: 'Ragged',
    stinking: 'Stinking',
    thin: 'Thin',
    warm: 'Warm',
    scrubbed: 'Scrubbed',

    // weapons
    barbaric: 'Barbaric',
    barbed: 'Barbed',
    brutal: 'Brutal',
    fragile: 'Fragile',
    hefty: 'Hefty',
    gladiator: "Gladiator's",
    masterwork: 'Masterwork',
    murderous: 'Murderous',
    swift: 'Swift',
    unbalanced: 'Unbalanced',
    vampiric: 'Vampiric',
    venomous: 'Venomous',
    weeping: 'Weeping',
    well_balanced: 'Well-balanced',
  },

  suffixes: {
    anger: 'of Anger',
    balance: 'of Balance',
    bear: 'of the Bear',
    boar: 'of the Boar',
    chaos: 'of Chaos',
    cunning: 'of Cunning',
    defense: 'of Defense',
    doom: 'of Doom',
    dragon: 'of the Dragon',
    eagle: 'of the Eagle',
    fawn: 'of the Fawn',
    health: 'of Health',
    ignorance: 'of Ignorance',
    leadership: 'of Leadership',
    life: 'of Life',
    madness: 'of Madness',
    might: 'of Might',
    mountain: 'of the Mountain',
    order: 'of Order',
    ox: 'of the Ox',
    power: 'of Power',
    protection: 'of Protection',
    resilience: 'of Resilience',
    scourge: 'of the Scourge',
    sea: 'of the Sea',
    sky: 'of the Sky',
    sloth: 'of Sloth',
    snake: 'of the Snake',
    speed: 'of Speed',
    stupidity: 'of Stupidity',
    tiger: 'of the Tiger',
    travelling: 'of Travelling',
    tree: 'of the Tree',
    unicorn: 'of the Unicorn',
    vulgar: 'of the Vulgar',
    weakness: 'of Weakness',
    whale: 'of the Whale',
    wonder: 'of Wonder',
    rejuvenation: 'of Rejuvenation',
    poise: 'of Poise',
    focus: 'of Focus',
    novice: 'of the Novice',
    fortress: 'of the Fortress',
    lunatic: 'of the Lunatic',
    dove: 'of the Dove',

    // weapons
    destruction: 'of Destruction',
    fire: 'of Fire',
    ice: 'of Ice',
    penetration: 'of Penetration',
    thunder: 'of Thunder',
    terror: 'of Terror',
  },

  descriptions: {
    equipment_dye: 'Changes the color of one piece of equipment.',
    miracle_dye: 'Becomes an equipment dye of the color you choose.',
    hero_xp_boost:
      'Grants one hero a bonus to all XP gains for the duration.\n\nTO USE: at Edit Hero screen, drag from inventory to the slot in the top-middle.',
    prayer_candle:
      "Choose a player to pray for - their active prayer timers (of the candle's alignment) are instantly reduced. Additionally, you get lower prayer times for the duration.",
    vampiric: '15% of attack damage returned as health.',
    venomous: 'Attacks poison the target.',
    weeping: 'Attacks cause the target to bleed.',
    fire: '20% chance to cast Burning Hands on hit.',
    gladiator: '20% chance to stun.',
    ice: '20% chance to cast Ice Nine on hit.',
    penetration: "Attacks ignore 50% of the target's armor.",
    thunder: '20% chance to cast Lightning on hit.',
    terror: '7% chance to Fear the target.',
    destruction: '+20% chance to crit.',
  },
};
