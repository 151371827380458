export default {
  assassin: 'Assassin',
  battle_mage: 'Battle Mage',
  dreamsmith: 'Dreamsmith',
  invoker: 'Invoker',
  knight: 'Knight',
  mercenary: 'Mercenary',
  monk: 'Monk',
  paladin: 'Paladin',
  ranger: 'Ranger',
  shaman: 'Shaman',
  warlock: 'Warlock',

  dwarf: 'Dwarf',
  elf: 'Elf',
  faerie: 'Faerie',
  poppet: 'Poppet',

  bandit: 'Bandit',
  cave_lizard: 'Cave Lizard',
  ectoplasm: 'Ectoplasm',
  fire_drake: 'Fire Drake',
  flying_jelly: 'Flying Jelly',
  ghost: 'Ghost',
  giant_rat: 'Giant Rat',
  goblin: 'Goblin',
  goblin_elementalist: 'Goblin Elementalist',
  goo: 'Goo',
  huge_bat: 'Huge Bat',
  kobold: 'Kobold',
  kobold_cleric: 'Kobold Cleric',
  lich: 'Lich',
  phantasm: 'Phantasm',
  ratling_priest: 'Ratling Priest',
  ratling_warrior: 'Ratling Warrior',
  skeletal_archer: 'Skeletal Archer',
  skeleton: 'Skeleton',
  slime: 'Slime',
  snake: 'Snake',
  voidwalker: 'Voidwalker',
  wraith: 'Wraith',

  // types
  amorphous: 'Amorphous',
  beast: 'Beast',
  flying: 'Flying',
  humanoid: 'Humanoid',
  magic: 'Magic',
  undead: 'Undead',
};
