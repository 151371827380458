<template>
  <div class="relative flex flex-col pt-[70px]">
    <div
      id="hero_carousel_anchor"
      ref="hero_carousel_anchor"
      class="absolute top-0 right-0 w-[25%] h-full"
    ></div>

    <component
      :is="edit_hero_submenus[state.current_submenu_key] || 'div'"
      id="edit_hero_submenu"
      ref="edit_hero_submenu"
      class="grow w-full"
      :key="state.submenu_refresh_key"
      @item-drag-start="onItemDragStart"
    />
    <div class="w-full h-[8vh] flex justify-center items-center">
      <div class="h-[5vh] w-full background-transparent flex">
        <div
          id="edit_hero_nav_btns"
          class="grow h-full"
        ></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { nextTick, onMounted, onBeforeUnmount, reactive, ref } from 'vue';
import XPBar from '~/components/common/XPBar';
import { Screens } from '~/constants';
import { EditHeroActions, TutorialActions, UIActions } from '~/flux/actions';
import {
  FluxGetters,
  GameStateStore,
  HeroBuildStore,
  TutorialStore,
  UIStore,
} from '~/flux/stores';
import Tools from '~/Tools';
import CanvasTools from '~/view/CanvasTools';
import { HeroPicker } from '~/view/components/common/canvas';
import EditHeroNavBtns from '../canvas/EditHeroNavBtns';
import EditAbilities from './EditAbilities.vue';
import EditAISettings from './EditAISettings.vue';
import EditAttributes from './EditAttributes.vue';
import EditEquipment from './EditEquipment.vue';

let _dragging_item_data;
let _hero_build_carousel;
let _nav_btns;
let _xp_bar;

const edit_hero_submenus = {
  [Screens.ABILITIES]: EditAbilities,
  [Screens.AI_SETTINGS]: EditAISettings,
  [Screens.ATTRIBUTES]: EditAttributes,
  [Screens.EQUIPMENT]: EditEquipment,
};

const state = reactive({
  current_submenu_key: Screens.EQUIPMENT,
  submenu_refresh_key: 0,
});

const edit_hero_submenu = ref(null);

onMounted(() => {
  makeXPBar();
  makeNavBtns();
  makeHeroBuildCarousel();

  GameStateStore.on(
    GameStateStore.EQUIPMENT_CHANGE,
    onEquipmentAssignmentChange
  );
  GameStateStore.on(GameStateStore.XP_BOOST_USED, onXpBoostUsed);
  HeroBuildStore.on(HeroBuildStore.ABILITY_CHANGE, onEquipmentAssignmentChange);
  HeroBuildStore.on(
    HeroBuildStore.ATTRIBUTE_CHANGE,
    onEquipmentAssignmentChange
  );
  HeroBuildStore.on(HeroBuildStore.ITEM_EQUIPPED, onEquipmentAssignmentChange);
  HeroBuildStore.on(
    HeroBuildStore.ITEM_UNEQUIPPED,
    onEquipmentAssignmentChange
  );
  UIStore.on(UIStore.HERO_FOCUSED, onHeroFocused);

  window.addEventListener('resize', handleResize);
  window.addEventListener('orientationchange', handleResize);

  if (!TutorialStore.getCompletedTutorialSteps().navigated_to_edit_hero) {
    TutorialActions.logTutorialStepCompleted(
      'navigated_to_edit_hero',
      'onboard_step:4'
    );
  }
});

onBeforeUnmount(() => {
  destroyXPBar();
  destroyNavBtns();
  destroyHeroBuildCarousel();

  GameStateStore.removeListener(
    GameStateStore.EQUIPMENT_CHANGE,
    onEquipmentAssignmentChange
  );
  GameStateStore.removeListener(GameStateStore.XP_BOOST_USED, onXpBoostUsed);
  HeroBuildStore.removeListener(
    HeroBuildStore.ABILITY_CHANGE,
    onEquipmentAssignmentChange
  );
  HeroBuildStore.removeListener(
    HeroBuildStore.ATTRIBUTE_CHANGE,
    onEquipmentAssignmentChange
  );
  HeroBuildStore.removeListener(
    HeroBuildStore.ITEM_EQUIPPED,
    onEquipmentAssignmentChange
  );
  HeroBuildStore.removeListener(
    HeroBuildStore.ITEM_UNEQUIPPED,
    onEquipmentAssignmentChange
  );
  UIStore.removeListener(UIStore.HERO_FOCUSED, onHeroFocused);
  window.removeEventListener('resize', handleResize);
  window.removeEventListener('orientationchange', handleResize);
});

function makeXPBar() {
  destroyXPBar();
  _xp_bar = new XPBar({
    roster_hero: FluxGetters.getFocusedHero().roster_hero,
    onXpPotSlotMouseup,
  });
  _xp_bar.x = window.innerWidth / 2 - _xp_bar.width / 2;
  _xp_bar.y = DT_CANVAS_GLOBALS.spacing * 10.5;
  TweenMax.from(_xp_bar, 0.3, { y: -_xp_bar.height });
  DT_CANVAS_GLOBALS.stage.addChild(_xp_bar);
}

function destroyXPBar() {
  _xp_bar?.dispose();
  _xp_bar = null;
}

function makeNavBtns() {
  destroyNavBtns();

  _nav_btns = new EditHeroNavBtns(state.current_submenu_key);
  const dom_anchor = document.getElementById('edit_hero_nav_btns');
  const { y, height } = dom_anchor.getBoundingClientRect();
  _nav_btns.x = Math.round(window.innerWidth / 2 - _nav_btns.width / 2);
  _nav_btns.y = Math.round(y);
  _nav_btns.eventEmitter.on('EDIT_HERO_MENU_NAV', onSubmenuChange);
  DT_CANVAS_GLOBALS.stage.addChild(_nav_btns);
}

function destroyNavBtns() {
  if (_nav_btns) {
    _nav_btns.eventEmitter.removeListener(
      'EDIT_HERO_MENU_NAV',
      onSubmenuChange
    );
    _nav_btns.dispose();
    _nav_btns = null;
  }
}

function onSubmenuChange(new_submenu_key) {
  state.current_submenu_key = new_submenu_key;
}

let _last_hero_carousel_anchor;
function makeHeroBuildCarousel({
  do_puppet_transition_in = true,
  skip_initial_update = false,
  is_resize = false,
} = {}) {
  destroyHeroBuildCarousel();

  _hero_build_carousel = HeroPicker({
    built_heroes:
      HeroBuildStore.getAll().hero_builds[UIStore.getAll().focused_hero_handle],
    roster_heroes: GameStateStore.getAll().gameState.hero_roster,
    puppet_scale_mod: Math.min(4.2, window.innerHeight * 0.005),
    initial_selected_build_id: FluxGetters.getFocusedHeroBuild().built_hero._id,
    do_puppet_transition_in,
    skip_initial_update,
  });

  // dynamically locate the hero picker to the left of the nav buttons from the DOM
  const { x, y, height, width } =
    _last_hero_carousel_anchor && !is_resize
      ? _last_hero_carousel_anchor
      : CanvasTools.getDomAnchor('hero_carousel_anchor');
  _last_hero_carousel_anchor = { x, y, height, width };
  _hero_build_carousel.x = x + width / 2;
  _hero_build_carousel.y = y + height / 2 + DT_CANVAS_GLOBALS.spacing * 8;
  DT_CANVAS_GLOBALS.stage.addChild(_hero_build_carousel);

  _hero_build_carousel.event_emitter.on('new_selected_item', onBuildSelected);
}

function destroyHeroBuildCarousel() {
  if (_hero_build_carousel) {
    _hero_build_carousel.event_emitter.removeListener(
      'new_selected_item',
      onBuildSelected
    );
    _hero_build_carousel.dispose();
    DT_CANVAS_GLOBALS.stage.removeChild(_hero_build_carousel);
    _hero_build_carousel = null;
  }
}

const handleResize = Tools.debounce(() => {
  makeHeroBuildCarousel({
    do_puppet_transition_in: false,
    skip_initial_update: true,
    is_resize: true,
  });
  makeXPBar();
  makeNavBtns();
}, 300);

function onBuildSelected(carousel_element) {
  const hero_build = carousel_element.icon.actor;

  UIActions.focusHeroBuild({
    hero_handle: hero_build.hero_handle,
    build_id: hero_build._id,
  });

  // refresh the submenu component
  state.submenu_refresh_key += 1;
}

function onEquipmentAssignmentChange() {
  makeHeroBuildCarousel({
    do_puppet_transition_in: false,
    skip_initial_update: true,
  });
}

function onHeroFocused() {
  state.submenu_refresh_key += 1;
  nextTick(() => {
    makeXPBar();
    makeNavBtns();
    makeHeroBuildCarousel();
  });
}

function onItemDragStart(data) {
  _dragging_item_data = data;
}

function onXpPotSlotMouseup() {
  if (_dragging_item_data) {
    const game_item = _dragging_item_data?.game_item_icon?.getItem();
    if (game_item?.type === 'hero_xp_boost') {
      EditHeroActions.useXPBoost({
        potionId: game_item.uid,
        heroHandle: UIStore.getAll().focused_hero_handle,
      });
    }
  }
}

function onXpBoostUsed() {
  makeXPBar();
}
</script>
