import * as PIXI from 'pixi.js';
import BattleActions from '~/flux/actions/BattleActions';
import { BattleStore } from '~/flux/stores';
import ActionSelectPanel from './ActionSelectPanel';
import AbilitySelectPanel from './AbilitySelectPanel';

const HeroControlView = function (hero, doHideCallback) {
  PIXI.Container.call(this);

  const destroyActionSelectPanel = () => {
    if (_actionSelectPanel) {
      _actionSelectPanel.eventEmitter.removeAllListeners();
      _actionSelectPanel.dispose();
      _actionSelectPanel = null;
    }
  };

  const hideActionSelectPanel = () => {
    if (_actionSelectPanel) {
      _actionSelectPanel.disable();

      TweenMax.to(_actionSelectPanel.scale, 0.15, {
        x: 0,
        y: 0,
        onComplete: destroyActionSelectPanel,
      });
    }
  };

  const destroyAbilitySelectPanel = () => {
    if (_abilitySelectPanel) {
      _abilitySelectPanel.eventEmitter.removeListener(
        'ABILITY_SELECTED',
        onAbilitySelected
      );
      _abilitySelectPanel.dispose();
      _abilitySelectPanel = null;
    }
  };

  const hideAbilitySelectPanel = () => {
    if (_abilitySelectPanel) {
      _abilitySelectPanel.disable();

      TweenMax.to(_abilitySelectPanel.scale, 0.15, {
        x: 0,
        y: 0,
        onComplete: destroyAbilitySelectPanel,
      });
    }
  };

  const onMoveSelected = (move) => {
    BattleActions.selectAbility(move);
    doHideCallback();
  };

  const onMeleeAttackSelected = (meleeAttack) => {
    onAbilitySelected(meleeAttack);
  };

  const onAbilitySelected = (ability) => {
    BattleActions.selectAbility(ability);

    if (ability) {
      doHideCallback();

      // auto-execute self-targeted abilities
      if (ability.range.max === 0) {
        BattleActions.selectGamePiece(hero);
      }
    } else {
      hideAbilitySelectPanel();
      makeActionSelectPanel();
    }
  };

  var _abilitySelectPanel;
  const onAbilitiesSelected = () => {
    hideActionSelectPanel();
    destroyAbilitySelectPanel();

    _abilitySelectPanel = new AbilitySelectPanel(hero);
    TweenMax.from(_abilitySelectPanel, 0.25, { alpha: 0, ease: Expo.easeIn });
    this.addChild(_abilitySelectPanel);
    _abilitySelectPanel.eventEmitter.on('ABILITY_SELECTED', onAbilitySelected);
    BattleActions.selectAbilities();
  };

  const onWaitSelected = () => {
    BattleActions.heroWait();
    doHideCallback();
  };

  this.dispose = () => {
    destroyActionSelectPanel();
    destroyAbilitySelectPanel();

    this.removeChildren();
  };

  var buttonWidth;
  var _actionSelectPanel;
  const makeActionSelectPanel = () => {
    _actionSelectPanel = new ActionSelectPanel(hero);
    this.addChild(_actionSelectPanel);

    _actionSelectPanel.eventEmitter.on('MOVE', onMoveSelected);
    _actionSelectPanel.eventEmitter.on('MELEE_ATTACK', onMeleeAttackSelected);
    _actionSelectPanel.eventEmitter.on('ABILITIES', onAbilitiesSelected);
    _actionSelectPanel.eventEmitter.on('WAIT', onWaitSelected);

    buttonWidth = _actionSelectPanel.buttonWidth;
  };
  makeActionSelectPanel();
};
HeroControlView.prototype = Object.create(PIXI.Container.prototype);
HeroControlView.prototype.constructor = HeroControlView;
export default HeroControlView;
