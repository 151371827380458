<template>
  <Dialog
    :open="true"
    @close="props.onClose"
  >
    <div
      class="fixed inset-0 flex items-center justify-center p-4 z-20 bg-zinc-700/90"
    >
      <DialogPanel
        class="border border-white w-[800px] h-[90vh] flex flex-col bg-black"
      >
        <canvas
          id="modal_canvas"
          ref="modal_canvas"
          class="absolute z-0 w-[800px] h-full"
        />

        <DialogTitle class="border-b border-white p-3 text-yellow-300">
          {{ text('ui.battle_log') }}
        </DialogTitle>

        <div
          id="battle_log_body"
          class="relative w-full p-6 pointer-events-none"
        >
          <div
            id="battle_log_labels"
            class="w-full flex text-sm font-bold"
          >
            <div class="w-[25%] text-center">{{ text('ui.opponent') }}</div>
            <div class="w-[15%] text-center">{{ text('ui.match_type') }}</div>
            <div class="w-[15%] text-center">{{ text('ui.result') }}</div>
            <div class="w-[45%] text-center">{{ text('ui.rewards') }}</div>
          </div>
          <div class="w-full overflow-y-auto">
            <div
              v-if="state.sorted_battle_log_entries.length === 0"
              class="mt-48 w-full text-center text-neutral-500 italic text-xs"
            >
              {{ text('ui.no_log_entries') }}
            </div>
            <div
              v-for="(log_entry, i) of state.sorted_battle_log_entries"
              :id="`battle_log_entry_${log_entry.battle_id}`"
              :key="log_entry.battle_id"
              class="w-full flex text-xs p-4 items-center max-h-[64px]"
            >
              <div class="w-[25%] text-center">
                <UserNameplate :username="log_entry.opponent" />
              </div>
              <div class="w-[15%] text-center">
                {{ text(`ui.game_modes.${log_entry.game_mode}`) }}
                {{ text(`ui.game_submodes.${log_entry.game_submode}`) }}
              </div>
              <div
                :class="{
                  'w-[15%] text-center': true,
                  'text-green-500': log_entry.won,
                  'text-red-500': !log_entry.won,
                }"
              >
                {{ log_entry.won ? text('ui.won') : text('ui.lost') }}
              </div>
              <!--  {{ log_entry.won ? '+' : '' }} {{ log_entry.eloChange }} -->
              <div class="w-[35%] text-center">
                <BattleRewardsIcons
                  v-if="log_entry.rewards && state.modal_pixi_app"
                  :key="`${state.rewards_key}_${i}`"
                  :battle_id="log_entry.battle_id"
                  :modal_pixi_stage="state.modal_pixi_app.stage"
                  :rewards="log_entry.rewards"
                  class="mr-3"
                />
              </div>
              <div
                :class="{
                  'w-[5%] p-3 flex justify-center items-center': true,
                  invisible: claimAllBtnInvisibility(log_entry),
                }"
                style="font-size: 9px"
              >
                <div
                  class="text-center text-blue-500 hover:text-yellow-300 cursor-pointer pointer-events-auto"
                  @click="onClaimAllClick(log_entry.battle_id, $event)"
                >
                  {{ text('ui.claim_all') }}
                </div>
              </div>
              <div class="w-[5%] flex justify-center items-center">
                <!-- <div
                  class="ml-6 p-3 text-lg font-bold text-red-800 hover:text-yellow-300 cursor-pointer pointer-events-auto"
                  @click="deleteLogEntry(log_entry.battle_id)"
                > -->
                <div
                  :class="{
                    ['ml-6 p-3 text-lg font-bold text-red-800 hover:text-yellow-300 cursor-pointer']: true,
                    ['pointer-events-auto']:
                      !state.pending_claim_all[log_entry.battle_id],
                    ['pointer-events-none']:
                      state.pending_claim_all[log_entry.battle_id],
                  }"
                  @click="deleteLogEntry(log_entry.battle_id)"
                >
                  <DTAnimatedElipsis
                    v-if="state.pending_claim_all[log_entry.battle_id]"
                    class="text-tiny font-extrabold"
                  />
                  <span v-else>{{ 'x' }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script setup>
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/vue';
import {
  nextTick,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  watch,
} from 'vue';
import { BattleDebriefActions } from '~/flux/actions';
import {
  CurrencyStore,
  GameStateStore,
  NotificationsStore,
  PlayerStore,
} from '~/flux/stores';
import text from '~/text';
import CanvasTools from '~/view/CanvasTools';
import { DTAnimatedElipsis, UserNameplate } from '~/view/components/common/DOM';
import BattleRewardsIcons from './BattleRewardsIcons.vue';

const props = defineProps({
  onClose: {
    type: Function,
    required: true,
  },
});

const modal_canvas = ref(null);

watch(modal_canvas, (val) => {
  if (val && !state.modal_pixi_app) {
    state.modal_pixi_app = CanvasTools.makePixiRenderer({
      canvas_id: 'modal_canvas',
      canvas_ele: val,
    });
    updateModalStagePivot();
  }
});

const state = reactive({
  modal_pixi_app: null,
  rewards_key: 0,
  sorted_battle_log_entries: [],
  pending_claim_all: {},
});

onMounted(() => {
  CurrencyStore.on(CurrencyStore.GOLD_REWARD_CLAIMED, onBattleLog);
  CurrencyStore.on(CurrencyStore.PD_REWARD_CLAIMED, onBattleLog);
  GameStateStore.on(GameStateStore.ITEM_REWARD_CLAIMED, onBattleLog);
  GameStateStore.on(GameStateStore.ALL_REWARDS_CLAIMED, onAllRewardsClaimed);
  NotificationsStore.on(NotificationsStore.GOT_BATTLE_LOG, onBattleLog);
  onBattleLog();
});

onBeforeUnmount(() => {
  state.modal_pixi_app?.destroy();
  CurrencyStore.removeListener(CurrencyStore.GOLD_REWARD_CLAIMED, onBattleLog);
  CurrencyStore.removeListener(CurrencyStore.PD_REWARD_CLAIMED, onBattleLog);
  GameStateStore.removeListener(
    GameStateStore.ITEM_REWARD_CLAIMED,
    onBattleLog
  );
  GameStateStore.removeListener(
    GameStateStore.ALL_REWARDS_CLAIMED,
    onAllRewardsClaimed
  );
  NotificationsStore.removeListener(
    NotificationsStore.GOT_BATTLE_LOG,
    onBattleLog
  );
});

function claimAllBtnInvisibility(log_entry) {
  if (!log_entry?.rewards) {
    return true;
  }

  const are_actual_items = Object.values(
    log_entry.rewards[PlayerStore.getAll().loggedInPlayerId]?.items ?? {}
  ).filter((x) => !!x).length;
  if (
    !are_actual_items &&
    !log_entry.rewards?.gold &&
    !log_entry.rewards?.pixieDust
  ) {
    return true;
  }
  const rewards =
    log_entry.rewards[PlayerStore.getAll().loggedInPlayerId] ||
    log_entry.rewards;
  return (
    !rewards?.gold &&
    !rewards?.pixieDust &&
    !Object.values(rewards?.items).length
  );
}

function deleteLogEntry(battle_id) {
  BattleDebriefActions.deleteBattleLogEntry(battle_id);
}

function onBattleLog() {
  state.sorted_battle_log_entries = Object.entries(
    NotificationsStore.getAll().battleLog
  )
    .map(([battle_id, entry]) => ({ ...entry, battle_id }))
    .sort((a, b) => b.endedAt - a.endedAt);
  nextTick(() => {
    updateModalStagePivot();
    state.rewards_key++;
  });
}

function onClaimAllClick(battle_id, event) {
  event.stopPropagation();
  BattleDebriefActions.claimAllRewards(battle_id);
  state.pending_claim_all[battle_id] = true;
}

function updateModalStagePivot() {
  state.modal_pixi_app.stage.pivot.x = modal_canvas.value.offsetLeft;
  state.modal_pixi_app.stage.pivot.y = modal_canvas.value.offsetTop;
}

function onAllRewardsClaimed({ log_id }) {
  delete state.pending_claim_all[log_id];
}
</script>
