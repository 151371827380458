import * as PIXI from 'pixi.js';
import BattleCalc from 'dt-common/battle_engine/BattleCalc';
import Audio from '~/Audio';
import Colors from '~/constants/Colors';

const SanctuaryRendering = {
  render: function (data, gameBoard, isCaverns) {
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
    const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

    render_movement &&
      actorSprite.puppet
        .getMovements()
        .magicUpGentle.movePuppet({ transitTime });
  },

  renderResult: function (data, gameBoard, isCaverns) {
    const victimSprite = gameBoard.getAllPieceSprites()[data.victimId];

    for (var uid in gameBoard.getTileSprites()) {
      var tileSprite = gameBoard.getTileSprites()[uid];

      if (
        BattleCalc.boardDistance(
          tileSprite.gamePiece,
          victimSprite.gamePiece
        ) <= data.effectRange
      ) {
        var numBeams = 19 + Math.round(Math.random() * 12);
        for (var i = 0; i < numBeams; ++i) {
          var beam = new PIXI.Graphics();
          beam.beginFill(Colors.BRIGHT_YELLOW, 0.3 + Math.random() * 0.5);
          beam.drawRect(-0.5, -0.5, 1, 1);
          beam.endFill();
          TweenMax.to(beam.scale, 0.5 + Math.random() * 1.5, {
            y: 25 + Math.random() * 45 /*,yoyo:true,repeat:1*/,
          }).delay(i * 0.03);
          beam.x = isCaverns
            ? -25 + Math.random() * 50
            : -tileSprite.tileImg.width / 2 +
              Math.random() * tileSprite.tileImg.width;
          beam.y =
            -tileSprite.tileImg.height / 2 +
            Math.random() * tileSprite.tileImg.height;
          TweenMax.to(beam, 0.5 + Math.random() * 1.5, {
            y: beam.y - 30 - Math.random() * 30,
            alpha: 0,
          }).delay(i * 0.03);
          tileSprite.tileImg.addChild(beam);
        }
      }
    }

    Audio.play('positive_magic_01');
  },
};
export default SanctuaryRendering;
