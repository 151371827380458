import * as PIXI from 'pixi.js';
import Colors from '~/constants/Colors';
import text from '~/text';
import BattleCalc from 'dt-common/battle_engine/BattleCalc';
import Game from 'dt-common/constants/Game';
import {
  AltarStore,
  ApplicationStore,
  BattleStore,
  CurrencyStore,
  GameStateStore,
  UIStore,
} from '~/flux/stores';
import { ObjectPool } from '~/Tools';

const STYLE = {
  fontFamily: 'Courier New',
  fontSize: '20px',
  fontWeight: 'bold',
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowDistance: 1,
};

var _txtPool = new ObjectPool(
  // create func
  () => {
    const txt = new PIXI.Text('', STYLE);
    txt.interactive = false;
    return txt;
  },

  // reset func
  (txt) => {
    gsap.killTweensOf(txt);
    txt.alpha = 1;
  },

  // starting num
  5
);

var messageQueue = [];

const init = () => {
  let did_connected_msg = false;
  const onSocketConnected = () => {
    if (!did_connected_msg) {
      MessageLog.addMessage(text('ui.connected_to_server'), Colors.GREEN);
      did_connected_msg = true;

      // check for ad blockers
      if (!document.getElementById('8675309')) {
        $addMessageLogMessage(text('ui.ad_blocker_msg.a'), Colors.RED);
        $addMessageLogMessage(text('ui.ad_blocker_msg.b'), Colors.RED);
        $addMessageLogMessage(
          text('ui.ad_blocker_msg.c'),
          Colors.BRIGHT_YELLOW
        );
        $addMessageLogMessage(text('ui.ad_blocker_msg.d'), Colors.RED);
      }
    }
  };

  const onSocketDisconnected = () => {
    MessageLog.addMessage(text('ui.disconnected_from_server'), Colors.RED);
  };

  var insufficientAPCount = 0;
  setInterval(() => {
    insufficientAPCount = Math.max(0, insufficientAPCount - 1);
  }, 15000);

  const onInsufficientStats = (data) => {
    if (
      UIStore.getAll().current_game_mode !== Game.GAME_MODES.GAME_MODE_pvpLive
    ) {
      return;
    }

    const unit = BattleStore.getAll().battleState.allPieces[data.actorId];
    const ability = BattleCalc.getEquippedAbility(unit, data.abilityHandle);

    if (unit.ap < ability.apCost) {
      MessageLog.addMessage(text('battle.not_enough_ap'), Colors.RED);

      if (++insufficientAPCount > 2) {
        MessageLog.addMessage(text('battle.try_wait_button'), Colors.RED);
        if (insufficientAPCount > 4) {
          MessageLog.addMessage(
            text('battle.shaped_like_hourglass'),
            Colors.RED
          );
        }
      }
    } else if (unit.mp < ability.mpCost) {
      MessageLog.addMessage(text('battle.not_enough_mp'), Colors.RED);
    } else {
      MessageLog.addMessage(text('battle.insufficient_stats'), Colors.RED);
    }
  };

  const onIllegalAction = () => {
    if (
      UIStore.getAll().current_game_mode === Game.GAME_MODES.GAME_MODE_pvpLive
    ) {
      MessageLog.addMessage(text('battle.illegal_action'), Colors.RED);
    }
  };

  const onInsufficientPixieDust = () => {
    MessageLog.addMessage(text('ui.insufficient_pixie_dust'), Colors.RED);
  };

  const onNoInventorySpace = () => {
    MessageLog.addMessage(text('ui.no_inventory_space'), Colors.RED);
  };

  const onHeroLevelUp = (data) => {
    MessageLog.addMessage(
      text('heroes.' + data.heroHandle + '.name') +
        ' ' +
        text('ui.leveled_up') +
        ' ' +
        data.newLevel.toString() +
        '!',
      Colors.BRIGHT_YELLOW
    );
  };

  const onWasPrayerCandleTarget = (data) => {
    MessageLog.addMessage(
      data.sender +
        ' ' +
        text('ui.endeared_you_to_god') +
        ' ' +
        text('ui.gods.' + data.alignment) +
        '!',
      Colors.BRIGHT_YELLOW
    );
  };

  const onPDCredit = (creditAmnt) => {
    MessageLog.addMessage(
      text('ui.on_pd_credit') +
        ' ' +
        creditAmnt +
        ' ' +
        text('ui.pixie_dust') +
        '!',
      Colors.PIXIE_DUST
    );
  };

  const killMessage = (m) => {
    DT_CANVAS_GLOBALS.stage.removeChild(m);
    _txtPool.put(m);
  };

  setInterval(() => {
    if (document.hidden) {
      return;
    }
    if (!messageQueue[0]) {
      return;
    }

    // shift one off off the message queue and display it
    var message = _txtPool.get();
    message.text = messageQueue[0].text;
    message.style.fill = messageQueue[0].color;

    message.x = window.innerWidth / 2 - message.width / 2;
    message.y = window.innerHeight * 0.22;
    DT_CANVAS_GLOBALS.overlay_stage.addChild(message);

    TweenMax.to(message, 4.0, {
      y: window.innerHeight * 0.08,
      ease: Linear.easeNone,
    });
    TweenMax.to(message, 4.0, {
      alpha: 0,
      ease: Expo.easeIn,
      onComplete: killMessage,
      onCompleteParams: [message],
    });

    messageQueue = messageQueue.slice(1);
  }, 850);

  ApplicationStore.on(ApplicationStore.SOCKET_CONNECTED, onSocketConnected);
  ApplicationStore.on(
    ApplicationStore.SOCKET_DISCONNECTED,
    onSocketDisconnected
  );
  BattleStore.on(BattleStore.INSUFFICIENT_STATS, onInsufficientStats);
  BattleStore.on(BattleStore.ILLEGAL_ACTION, onIllegalAction);
  CurrencyStore.on(
    CurrencyStore.INSUFFICIENT_PIXIE_DUST,
    onInsufficientPixieDust
  );
  CurrencyStore.on(CurrencyStore.PD_CREDIT, onPDCredit);
  GameStateStore.on(GameStateStore.NO_INVENTORY_SPACE, onNoInventorySpace);
  GameStateStore.on(GameStateStore.HERO_LEVEL_UP, onHeroLevelUp);
  AltarStore.on(AltarStore.WAS_PRAYER_CANDLE_TARGET, onWasPrayerCandleTarget);
};

const addMessage = (text, color) => {
  messageQueue.push({ text, color });
};

// i know, i know - this is just a quick hack to fix circular dependencies in the v1 code (flux stores importing message log)
window.$addMessageLogMessage = addMessage;

const MessageLog = {
  init,
  addMessage,
};
export default MessageLog;
