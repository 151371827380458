<template>
  <div
    id="caverns_battle_menu"
    class="mx-4 my-3 w-[calc(50%-30px)] h-[26vh] absolute bottom-0 right-0 bg-zinc-700/20 flex justify-between items-center pointer-events-none"
  >
    <!-- current caverns level, gold & pd totals -->
    <div
      id="caverns_quick_info"
      class="w-[30%] h-full p-2 pl-6 text-sm"
    >
      <div>{{ text('ui.depth') }}: {{ state.caverns_level }}</div>
      <div
        v-if="state.portal_level"
        class="text-pink-300"
      >
        {{ text('ui.portal_level') }}: {{ state.portal_level }}
      </div>
      <div class="text-yellow-300">
        {{ text('ui.gold') }}:
        {{ Tools.formatGameCurrency(state.total_gold_picked_up) }}
      </div>
      <div class="text-cyan-300">
        {{ text('ui.pixie_dust') }}:
        {{ Tools.formatGameCurrency(state.total_pd_picked_up) }}
      </div>
    </div>

    <!-- looted item icons -->
    <div
      id="looted_equipment_list"
      class="grow h-full flex flex-wrap overflow-y-auto p-4"
    >
      <div
        v-for="item of state.all_equipment_picked_up"
        :id="`looted_item_icon_${item.uid}`"
        :key="item.uid"
        :style="`width: ${GAME_ITEM_ICON_SIZE_PX}px; height: ${GAME_ITEM_ICON_SIZE_PX}px;`"
        class="m-2"
      ></div>
    </div>

    <!-- return to surface btn -->
    <div class="h-full flex items-end">
      <DTButton
        type="danger"
        class="m-6 w-[165px] h-[60px] pointer-events-auto"
        :disabled="state.pending_return_to_surface"
        @click="
          () => {
            state.pending_return_to_surface = true;
            CavernsActions.returnToSurface();
          }
        "
      >
        <DTAnimatedElipsis
          v-if="state.pending_return_to_surface"
          class="text-2xl font-extrabold"
        />
        <span v-else>{{ text('ui.surface') }}</span>
      </DTButton>
    </div>
  </div>
</template>

<script setup>
import { nextTick, onMounted, onBeforeUnmount, reactive } from 'vue';
import Audio from '~/Audio';
import { CavernsActions } from '~/flux/actions';
import { CavernsStore, UIStore } from '~/flux/stores';
import text from '~/text';
import GameItemIcon from '~/components/common/GameItemIcon';
import Tools from '~/Tools';
import CanvasTools from '~/view/CanvasTools';
import { DTAnimatedElipsis, DTButton } from '~/view/components/common/DOM';

const GAME_ITEM_ICON_SIZE_PX = 48;
let _loot_icons;

const state = reactive({
  all_equipment_picked_up: [],
  caverns_level: CavernsStore.getAll().current_caverns_level,
  pending_return_to_surface: false,
  portal_level:
    CavernsStore.getAll().caverns_data[UIStore.getAll().current_game_submode]
      .portal_level,
  total_gold_picked_up: 0,
  total_pd_picked_up: 0,
});

onMounted(() => {
  CavernsStore.on(CavernsStore.BATTLE_INITIALIZED, onBattleInitialized);
  CavernsStore.on(CavernsStore.CAVERNS_PORTAL_LEVEL_SET, onPortalLevelSet);
  CavernsStore.on(CavernsStore.TOTAL_LOOT_PICKED_UP, onCavernsLootPickedUp);
});

onBeforeUnmount(() => {
  CavernsStore.removeListener(
    CavernsStore.BATTLE_INITIALIZED,
    onBattleInitialized
  );
  CavernsStore.removeListener(
    CavernsStore.CAVERNS_PORTAL_LEVEL_SET,
    onPortalLevelSet
  );
  CavernsStore.removeListener(
    CavernsStore.TOTAL_LOOT_PICKED_UP,
    onCavernsLootPickedUp
  );
  destroyLootIcons();
});

function destroyLootIcons() {
  for (const icon of _loot_icons || []) {
    icon.dispose();
  }
  _loot_icons = null;
}

function makeLootIcons() {
  destroyLootIcons();
  _loot_icons = [];

  for (const loot_item of state.all_equipment_picked_up) {
    // make game item icon & add to stage
    const game_item_icon = new GameItemIcon(loot_item);
    game_item_icon.setScale(GAME_ITEM_ICON_SIZE_PX);
    const { x, y, width, height } = CanvasTools.getDomAnchor(
      `looted_item_icon_${loot_item.uid}`
    );
    game_item_icon.x = x + width / 2;
    game_item_icon.y = y + height / 2;
    DT_CANVAS_GLOBALS.stage.addChildAt(game_item_icon, 0); // add at 0 so as not to mask faerie spinners on equipment change

    // store icon data locally
    _loot_icons.push(game_item_icon);
  }
}

function onBattleInitialized(data) {
  state.caverns_level = CavernsStore.getAll().current_caverns_level;
}

function onCavernsLootPickedUp({ gold, pd, equipment }) {
  state.total_pd_picked_up = pd;
  state.total_gold_picked_up = gold;
  state.all_equipment_picked_up = equipment;
  nextTick(makeLootIcons);
}

function onPortalLevelSet(data) {
  state.portal_level = data.new_portal_level;
  Audio.play('level_up');
}
</script>
