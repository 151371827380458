import Game from 'dt-common/constants/Game';
import UnitHandles from 'dt-common/constants/UnitHandles';
import { Config } from '~/constants';
import text from '~/text';

const { QUALITY_I, QUALITY_II, QUALITY_III, QUALITY_IV } = Game.ItemQuality;

const HEAD_TINT_DICTIONARY = {
  [UnitHandles.ASSASSIN]: 0x424242,
  [UnitHandles.BATTLE_MAGE]: 0xcf6600,
  [UnitHandles.DREAMSMITH]: 0x30dbd5,
  [UnitHandles.INVOKER]: 0x260d93,
  [UnitHandles.KNIGHT]: 0x8898ab,
  [UnitHandles.MERCENARY]: 0x982323,
  [UnitHandles.MONK]: 0x773f0e,
  [UnitHandles.PALADIN]: 0xe0f308,
  [UnitHandles.RANGER]: 0x0d610b,
  [UnitHandles.SHAMAN]: 0x9abd1f,
  [UnitHandles.WARLOCK]: 0x62076f,
};

const EQUIPMENT_DISPLAY_DICTIONARY = {
  helmet: {
    light: [
      ['equipment.headband', 0x85764e],
      ['equipment.headdress', 0x9fc3bd],
      ['equipment.circlet', 0x626894],
      ['equipment.diadem', 0xef5fba],
      ['equipment.crown', 0x8e17a7],
    ],
    med: [
      ['equipment.skullcap', 0xba955a],
      ['equipment.leather_helm', 0x77541e],
      ['equipment.studded_helm', 0x724600],
      ['equipment.coif', 0xb3bcbc],
      ['equipment.dragonscale_helm', 0x5e9457],
    ],
    heavy: [
      ['equipment.copper_helm', 0xa05803],
      ['equipment.bronze_helm', 0xcf7c05],
      ['equipment.iron_helm', 0x6b6b6b],
      ['equipment.steel_helm', 0x91a6a4],
      ['equipment.mithril_helm', 0x6fc6a2],
    ],
  },
  torsoe: {
    light: [
      ['equipment.rags', 0x85764e],
      ['equipment.linen_cloak', 0x9fc3bd],
      ['equipment.brocade_cloak', 0x626894],
      ['equipment.spidersilk_cloak', 0xef5fba],
      ['equipment.mageweave_cloak', 0x8e17a7],
    ],
    med: [
      ['equipment.hide_vest', 0xba955a],
      ['equipment.leather_vest', 0x77541e],
      ['equipment.studded_vest', 0x724600],
      ['equipment.chainmail_vest', 0xb3bcbc],
      ['equipment.dragonscale_vest', 0x5e9457],
    ],
    heavy: [
      ['equipment.copper_plate', 0xa05803],
      ['equipment.bronze_plate', 0xcf7c05],
      ['equipment.iron_plate', 0x6b6b6b],
      ['equipment.steel_plate', 0x91a6a4],
      ['equipment.mithril_plate', 0x6fc6a2],
    ],
  },
  boots: {
    light: [
      ['equipment.sandals', 0x85764e],
      ['equipment.linen_shoes', 0x9fc3bd],
      ['equipment.brocade_slippers', 0x626894],
      ['equipment.spidersilk_slippers', 0xef5fba],
      ['equipment.mageweave_slippers', 0x8e17a7],
    ],
    med: [
      ['equipment.hide_boots', 0xba955a],
      ['equipment.leather_boots', 0x77541e],
      ['equipment.studded_boots', 0x724600],
      ['equipment.chainmail_boots', 0xb3bcbc],
      ['equipment.dragonscale_boots', 0x5e9457],
    ],
    heavy: [
      ['equipment.copper_boots', 0xa05803],
      ['equipment.bronze_boots', 0xcf7c05],
      ['equipment.iron_boots', 0x6b6b6b],
      ['equipment.steel_boots', 0x91a6a4],
      ['equipment.mithril_boots', 0x6fc6a2],
    ],
  },
  weapon: {
    dagger: [
      ['equipment.shiv', 0x96ea9e],
      ['equipment.dagger', 0x96ea9e],
      ['equipment.slicer', 0xaaaaaa],
      ['equipment.stiletto', 0xffffff],
      ['equipment.dragon_tooth', 0x96ea9e],
    ],
    hammer: [
      ['equipment.club', 0x4d320b],
      ['equipment.hammer', 0x949494],
      ['equipment.greathammer', 0x949494],
      ['equipment.grandhammer', 0x949494],
      ['equipment.thors_hammer', 0xf3ac00],
    ],
    staff: [
      ['equipment.walking_stick', 0x4d320b],
      ['equipment.quarterstaff', 0xba8e4f],
      ['equipment.oaken_staff', 0xbd9018],
      ['equipment.greatstaff', 0x517076],
      ['equipment.wizards_staff', 0x641179],
    ],
    sword: [
      ['equipment.shortsword', 0x96ea9e],
      ['equipment.broadsword', 0x96ea9e],
      ['equipment.longsword', 0xaaaaaa],
      ['equipment.falchion', 0xffffff],
      ['equipment.anduril', 0x96ea9e],
    ],
  },
  off_hand: {
    bow: [
      ['equipment.shortbow', 0xba955a],
      ['equipment.longbow', 0x77541e],
      ['equipment.composite_bow', 0x724600],
      ['equipment.mechanical_bow', 0xb3bcbc],
      ['equipment.elven_bow', 0x5e9457],
    ],
    shield: [
      ['equipment.wooden_shield', 0xba955a],
      ['equipment.buckler', 0x77541e],
      ['equipment.round_shield', 0x724600],
      ['equipment.kite_shield', 0xb3bcbc],
      ['equipment.tower_shield', 0x5e9457],
    ],
    book: [
      ['equipment.folio', 0xba955a],
      ['equipment.scroll', 0x77541e],
      ['equipment.book', 0x724600],
      ['equipment.tome', 0xb3bcbc],
      ['equipment.compendium', 0x5e9457],
    ],
    trinket: [
      ['equipment.bauble', 0xba955a],
      ['equipment.trinket', 0x77541e],
      ['equipment.figurine', 0x724600],
      ['equipment.totem', 0xb3bcbc],
      ['equipment.orb', 0x5e9457],
    ],
  },
  ring: {
    ring: [
      ['equipment.ring', 0xffffff],
      ['equipment.ring', 0xffffff],
      ['equipment.ring', 0xffffff],
      ['equipment.ring', 0xffffff],
      ['equipment.ring', 0xffffff],
    ],
  },
  item_container: {
    bag: [
      ['equipment.bag', 0xffffff],
      ['equipment.bag', 0xffffff],
      ['equipment.bag', 0xffffff],
      ['equipment.bag', 0xffffff],
      ['equipment.bag', 0xffffff],
    ],
  },
};

const getName = (item) => {
  try {
    if (!item) {
      throw new Error('no item');
    }

    if (item.type === 'equipment_dye') {
      return text('equipment.dye');
    } else if (item.type === 'miracle_dye') {
      return text('equipment.miracle_dye');
    } else if (item.type === 'hero_xp_boost') {
      return text('equipment.hero_xp_boost');
    } else if (item.type === 'prayer_candle') {
      return text('equipment.prayer_candle');
    } else if (item.type === 'bag') {
      return text('equipment.bag');
    }

    switch (Config.LOCALE) {
      case 'pl':
      case 'pt':
      case 'es':
      default: {
        if (!item.slot || !item.type || isNaN(item.quality)) {
          throw new Error('invalid item');
        }

        return (
          (item.prefix ? text('equipment.prefixes.' + item.prefix) + ' ' : '') +
          text(
            EQUIPMENT_DISPLAY_DICTIONARY[item.slot][item.type][
              getQualityIndex(item)
            ][0]
          ) +
          (item.suffix ? ' ' + text('equipment.suffixes.' + item.suffix) : '')
        );
      }
    }
  } catch (err) {
    logError(
      err,
      Object.assign(
        {
          module: 'components/common/EquipmentDisplay',
          func: 'getName',
        },
        item
      )
    );

    return '???';
  }
};

const PuppetTint = {
  head: function (actor) {
    return HEAD_TINT_DICTIONARY[actor.handle];
  },

  hand: function (actor) {
    return 0xc89e2e;
  },

  equipment: (equipmentPiece) => {
    if (!equipmentPiece) {
      return 0xffffff;
    }

    // TODO(@rob-wfs) probably we will try some sort of filter, overlay, or other effect instead of tinting
    if (equipmentPiece.prefix) {
    }

    if (equipmentPiece.suffix) {
    }

    return (
      equipmentPiece.tint ||
      (equipmentPiece.slot === 'item_container'
        ? 0xffffff
        : EQUIPMENT_DISPLAY_DICTIONARY[equipmentPiece.slot][
            equipmentPiece.type
          ][getQualityIndex(equipmentPiece)][1])
    );
  },
};

const getQualityIndex = (item) => {
  var quality = item.quality;
  if (quality < QUALITY_I) {
    return 0;
  } else if (quality < QUALITY_II) {
    return 1;
  } else if (quality < QUALITY_III) {
    return 2;
  } else if (quality < QUALITY_IV) {
    return 3;
  } else {
    return 4;
  }
};

export default {
  getName,
  getQualityIndex,
  PuppetTint,
};
