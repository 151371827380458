<template>
  <div
    id="chat_body_scroll"
    ref="chat_body_scroll"
    class="grow text-grey-700 max-h-[100%] pt-2 border-r border-r-gray-600 bg-zinc-900/80 overflow-y-auto text-xs"
  >
    <div
      v-for="(message, i) of messages_to_display"
      :key="i"
    >
      <!-- case: line break -->
      <br v-if="message.line_break" />

      <!-- case: emote/command/notif -->
      <div
        v-else-if="message.emote || message.command || message.notification"
        :style="{
          color: message.color || '#fff',
          fontWeight: message.notification ? 'bold' : 'normal',
        }"
      >
        <div :class="{ 'italic ml-2': !!message.emote }">
          <span
            v-if="!!message.timestamp"
            class="mr-1"
            style="font-size: 10px"
            >{{ parseTimestamp(message.timestamp) }}</span
          >
          <span v-html="formatText(message.text)"></span>
        </div>
      </div>

      <!-- case: player chat message -->
      <div
        v-else
        class="flex"
        :style="{ color: message.color || '#fff' }"
      >
        <!-- timestamp | username | colon -->
        <span
          class="mr-1"
          style="font-size: 10px"
          >{{ parseTimestamp(message.timestamp) }}</span
        >
        <UserNameplate :username="message.actor" />
        <span
          v-if="!!message.actor"
          class="font-bold"
          >:</span
        >
        <!-- message body -->
        <div
          v-html="formatText(message.text)"
          :class="{ 'ml-2': !!message.actor }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, onUpdated } from 'vue';
import ChatStore from '~/flux/stores/ChatStore';
import { UserNameplate } from '~/view/components/common/DOM';

const WHITELISTED_URLS = {
  'https://discord.gg/ysgHx3ErwJ': 1,
  'https://qa.dungeonteam.com': 1,
};

const chat_body_scroll = ref(null);
const messages_to_display = ref(null);

onMounted(() => {
  ChatStore.on(ChatStore.GOT_MESSAGE_EVENT, onGotMessage);
  ChatStore.on(ChatStore.CURRENT_ROOM_UPDATED, onCurrentRoomUpdated);

  const { message_cache, current_room_name } = ChatStore.getAll();
  current_room_name && onGotMessage({ room_name: current_room_name });
});

onBeforeUnmount(() => {
  ChatStore.removeListener(ChatStore.GOT_MESSAGE_EVENT, onGotMessage);
  ChatStore.removeListener(
    ChatStore.CURRENT_ROOM_UPDATED,
    onCurrentRoomUpdated
  );
});

onUpdated(() => {
  // keep scroll cinched to bottom when new message lines are added
  if (
    chat_body_scroll.value.scrollHeight - chat_body_scroll.value.scrollTop <
    window.innerHeight * 0.82
  ) {
    chat_body_scroll.value.scrollTop = chat_body_scroll.value.scrollHeight;
  }
});

const onGotMessage = ({ room_name }) => {
  const { message_cache, current_room_name } = ChatStore.getAll();
  if (room_name === current_room_name)
    messages_to_display.value = normalizeMessageCache(message_cache[room_name]);
};

const normalizeMessageCache = (raw) => {
  const MAX_TO_RENDER = 2000;
  return raw.slice(0, MAX_TO_RENDER);
};

function onCurrentRoomUpdated({ current_room_name }) {
  onGotMessage({ room_name: current_room_name });
}

function formatText(text) {
  return (
    text
      // don't allow HTML injection
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      // only allow whitelisted links
      .replace(LINK_REGEX, (url) =>
        WHITELISTED_URLS[url]
          ? `<a href=${url} target=_blank>${url}</a>`
          : '[URL]'
      )
  );
}
const LINK_REGEX =
  /(\b(https?|ftp|file):\/\/([-A-Z0-9+&@#%?=~_|!:,.;]*)([-A-Z0-9+&@#%?\/=~_|!:,.;]*)[-A-Z0-9+&@#\/%=~_|])/gi;

function parseTimestamp(timestamp) {
  if (!timestamp) {
    return '';
  }
  const date = new Date(timestamp);
  return `[${date.toLocaleTimeString()}]`.replace(' AM', '').replace(' PM', '');
}
</script>
