import { EventEmitter } from 'events';

import getBattleStateHelperFuncs from 'dt-common/battle_engine/getBattleStateHelperFuncs';
import StatRecovery from 'dt-common/battle_engine/StatRecovery';

import {
  BattleDispatcher,
  PlayerDispatcher,
  UIDispatcher,
} from '~/flux/dispatchers';
import { awaitSocket, registerDispatchHandlers } from '~/Tools';
import Game from 'dt-common/constants/Game';
import text from '~/text';
import Colors from '~/constants/Colors';

let _socket;
let playerId;
let _statRecovery;
let _health_ping_interval;
let _last_health_ping_at;
let _recovering_engine = false;

// the stuff we serve:
let battleState = null;
let myTeam;
let unitsWaitingForOrders = [];
let selectedAbility = null;
let selectedTarget = null;
const getUnitToOrder = function () {
  return unitsWaitingForOrders.length ? unitsWaitingForOrders[0] : null;
};
let actingAIUnit = null;
let boardCursor = {
  x: null,
  y: null,
};
let debrief = null;

const BattleStore = Object.assign({}, EventEmitter.prototype, {
  ABILITY_EXECUTED: 'ABILITY_EXECUTED',
  ABILITY_SELECTED: 'ABILITY_SELECTED',
  AI_TURN: 'AI_TURN',
  BATTLE_EVENT: 'BATTLE_EVENT',
  BATTLE_INITIALIZED: 'BATTLE_INITIALIZED',
  BATTLE_KILLED: 'BATTLE_KILLED',
  BATTLE_STATE: 'BATTLE_STATE',
  BOARD_CURSOR_CHANGE: 'BOARD_CURSOR_CHANGE',
  CHALLENGE_RESOLVED: 'CHALLENGE_RESOLVED',
  GOT_UNIT_STATE: 'GOT_UNIT_STATE',
  ILLEGAL_ACTION: 'ILLEGAL_ACTION',
  INSUFFICIENT_STATS: 'INSUFFICIENT_STATS',
  ORDERS_REQUESTED: 'ORDERS_REQUESTED',
  RESUMING_TIME: 'RESUMING_TIME',
  STAT_COSTS_EXACTED: 'STAT_COSTS_EXACTED',
  TARGET_SELECTED: 'TARGET_SELECTED',
  TURN_TIMER_INIT: 'TURN_TIMER_INIT',
  TUTORIAL_01_DONE: 'TUTORIAL_01_DONE',
  UNIT_CONDITION: 'UNIT_CONDITION',
  UNIT_CONDITION_EXPIRED: 'UNIT_CONDITION_EXPIRED',
  ENGINE_RECOVERY_FAILED: 'ENGINE_RECOVERY_FAILED',

  getAll() {
    return {
      battleState,
      myTeam,
      paused: battleState ? battleState.paused : null,
      unitsWaitingForOrders,
      unitToOrder: getUnitToOrder(),
      selectedAbility,
      selectedTarget,
      actingAIUnit,
      boardCursor,
      debrief,
    };
  },
});
export default BattleStore;

PlayerDispatcher.register(
  registerDispatchHandlers({
    [PlayerDispatcher.PLAYER_LOGGED_IN]: onPlayerLoggedIn,
  })
);
BattleDispatcher.register(
  registerDispatchHandlers({
    [BattleDispatcher.SELECT_ABILITIES]: selectAbilities,
    [BattleDispatcher.SELECT_ABILITY]: selectAbility,
    [BattleDispatcher.SELECT_GAME_PIECE]: selectGamePiece,
    [BattleDispatcher.HERO_WAIT]: heroWait,
    [BattleDispatcher.MOVE_BTN_TOUCH]: onMoveBtnTouch,
    [BattleDispatcher.EXECUTE_BTN_TOUCH]: onExecuteBtnTouch,
    [BattleDispatcher.KILL_BATTLE]: killBattle,
  })
);
UIDispatcher.register(
  registerDispatchHandlers({
    [UIDispatcher.SELECT_GAME_SUBMODE]: onGameSubmodeSelection,
    [UIDispatcher.UI_NAV]: onUINav,
  })
);

awaitSocket(onSocketConnected);
// awaitSocket().then(onSocketConnected);
function onSocketConnected(socket) {
  try {
    _socket = socket;

    if (!_socket.has_BattleStore_listeners) {
      _socket.on('abilityExecuted', onAbilityExecuted);
      _socket.on('aiTurn', onAITurn);
      _socket.on('battle_ok', receiveHealthOKPing);
      _socket.on('battleEvent', onBattleEvent);
      _socket.on('battleInitialized', onBattleInitialized);
      _socket.on('challengeResolved', onChallengeResolved);
      _socket.on('engine_recovery_failed', onEngineRecoveryFailed);
      _socket.on('gotUnitState', onUnitState);
      _socket.on('inn_battle_resolved', onChallengeResolved);
      _socket.on('illegalAction', onIllegalAction);
      _socket.on('insufficientStats', onInsufficientStats);
      _socket.on('paused', onPaused);
      _socket.on('requestOrders', onOrdersRequest);
      _socket.on('statCostsExacted', onStatCostsExacted);
      _socket.on('timeResumed', onTimeResumed);
      _socket.on('turnTimerInit', onTurnTimerInit);
      _socket.on('unitCondition', onUnitCondition);
      _socket.on('unitConditionExpired', onUnitConditionExpired);

      _socket.has_BattleStore_listeners = true;
    }
  } catch (err) {
    logError(err, {
      module: 'BattleStore',
      func: 'onSocketConnected',
    });
  }
}

function onPlayerLoggedIn(action) {
  try {
    playerId = action.player._id;
  } catch (err) {
    logError(err, {
      module: 'BattleStore',
      func: 'onPlayerLoggedIn',
      action,
    });
  }
}

function onBattleInitialized(data) {
  console.info(`Battle initialized - battle_id: ${data.battle_id}`);
  try {
    const {
      battle_id,
      battleState: { allTiles, allUnits, black_team, tutorial, white_team },
      whitePlayerId,
    } = data;

    myTeam = playerId === whitePlayerId ? 'white' : 'black';

    battleState = {
      battle_id,
      allTiles,
      allUnits,
      allPieces: Object.assign({}, allTiles, allUnits), // TODO(@rob-wfs): apparently the current webpack/babel settings don't the spread operator
      black_team,
      tutorial,
      white_team,
    };
    // this awful garbage is needed for calls to be made like battleState.getAdjacentTiles(unit)
    const hf = getBattleStateHelperFuncs(battleState);
    for (const [key, fn] of Object.entries(hf)) {
      battleState[key] = fn;
    }

    _statRecovery = StatRecovery({
      allUnits,
      initPaused: false,
      unitAPFullCallback: () => {
        _statRecovery?.pause();
      },
      logger: { logError },
    });

    BattleStore.emit(BattleStore.BATTLE_INITIALIZED);

    const now = Date.now();
    _last_health_ping_at = now;
    clearInterval(_health_ping_interval);
    _health_ping_interval = setInterval(() => {
      if (now - _last_health_ping_at > 3000) {
        _recovering_engine = true;
        _socket.emit('battle_health_check_failed', { battle_id });
        $addMessageLogMessage(
          text('ui.health_check_failed'),
          Colors.BRIGHT_YELLOW
        );
      }
    }, 3000);
  } catch (err) {
    logError(err, {
      module: 'BattleStore',
      func: 'onBattleInitialized',
      data,
    });
  }
}

function onOrdersRequest(data) {
  try {
    if (data.playerId !== playerId) {
      throw new Error('got orders request for wrong player');
    }

    _statRecovery?.pause();

    const { heroId } = data;
    if (!unitsWaitingForOrders.includes(heroId)) {
      unitsWaitingForOrders.push(heroId);
    }

    actingAIUnit = null;

    BattleStore.emit(BattleStore.ORDERS_REQUESTED);
  } catch (err) {
    logError(err, {
      module: 'BattleStore',
      func: 'onOrdersRequest',
      data,
    });
  }
}

function onPaused(data) {
  try {
    battleState.allUnits = data.allUnits;

    _statRecovery?.pause();
    _statRecovery?.setUnits(battleState?.allUnits);

    for (var uid in data.allUnits) {
      battleState.allPieces[uid] = data.allUnits[uid];
    }
  } catch (err) {
    logError(err, {
      module: 'BattleStore',
      func: 'onPaused',
      data,
    });
  }
}

function onTurnTimerInit(data) {
  try {
    BattleStore.emit(BattleStore.TURN_TIMER_INIT, data);
  } catch (err) {
    logError(err, {
      module: 'BattleStore',
      func: 'onTurnTimerInit',
      data,
    });
  }
}

function selectAbilities() {
  selectedAbility = { handle: 'ABILITIES_SELECTED' };
}

function selectAbility(action) {
  try {
    if (selectedTarget) {
      selectedTarget = null;
      BattleStore.emit(BattleStore.TARGET_SELECTED);
    }

    const { ability } = action;

    if (selectedAbility && ability?.handle === selectedAbility.handle) {
      // selecting an ability twice clears it
      selectedAbility = null;
    } else {
      selectedAbility = ability;
    }

    BattleStore.emit(BattleStore.ABILITY_SELECTED);
  } catch (err) {
    logError(err, {
      module: 'BattleStore',
      func: 'selectAbility',
      action,
    });
  }
}

function selectGamePiece(action) {
  try {
    if (selectedTarget && action.gamePiece.uid === selectedTarget.uid) {
      selectedTarget = null;
      BattleStore.emit(BattleStore.TARGET_SELECTED);
      return;
    }

    if (selectedAbility) {
      const abilityHandle = selectedAbility ? selectedAbility.handle : null;

      if (abilityHandle) {
        _socket.emit('battle_command', {
          playerId,
          command: 'requestExecuteAbility',
          battle_id: battleState.battle_id,
          actorId: getUnitToOrder(),
          abilityHandle,
          stance: selectedAbility ? selectedAbility.stance : null,
          victimId: action.gamePiece.uid,
        });

        selectedAbility = null;
        BattleStore.emit(BattleStore.ABILITY_SELECTED);
      }
    }

    selectedTarget = action.gamePiece;
    BattleStore.emit(BattleStore.TARGET_SELECTED);
  } catch (err) {
    logError(err, {
      module: 'BattleStore',
      func: 'selectGamePiece',
      action,
    });
  }
}

function onStatCostsExacted(data) {
  try {
    const unit = battleState.allPieces[data.actorId];
    if (unit) {
      if (data.hpCost) {
        unit.hp -= data.hpCost;
      }
      if (data.apCost) {
        unit.ap -= data.apCost;
      }
      if (data.mpCost) {
        unit.mp -= data.mpCost;
      }
    }

    BattleStore.emit(BattleStore.STAT_COSTS_EXACTED, data);
  } catch (err) {
    logError(err, {
      module: 'BattleStore',
      func: 'onStatCostsExacted',
      data,
    });
  }
}

function onAbilityExecuted(data) {
  try {
    BattleStore.emit(BattleStore.ABILITY_EXECUTED, data);
  } catch (err) {
    logError(err, {
      module: 'BattleStore',
      func: 'onAbilityExecuted',
      data,
    });
  }
}

function onBattleEvent(data) {
  try {
    processAffectedPieces(data.event.affectedPieces);
    BattleStore.emit(BattleStore.BATTLE_EVENT, data);

    if (
      data.event.eventHandle === 'death' &&
      selectedTarget &&
      data.event.victimId === selectedTarget.uid
    ) {
      selectedTarget = null;
      BattleStore.emit(BattleStore.TARGET_SELECTED);
    }
  } catch (err) {
    logError(err, {
      module: 'BattleStore',
      func: 'onBattleEvent',
    });
  }
}

function processAffectedPieces(affectedPieces) {
  if (!affectedPieces) {
    return;
  }
  if (typeof affectedPieces === 'object') {
    affectedPieces = Object.values(affectedPieces);
  }

  try {
    for (const piece of affectedPieces) {
      if (piece && battleState && battleState.allPieces) {
        if (battleState.allPieces[piece.uid]) {
          Object.assign(battleState.allPieces[piece.uid], piece);
        } else {
          battleState.allPieces[piece.uid] = piece;
        }

        if (piece.unit) {
          if (battleState.allUnits[piece.uid]) {
            Object.assign(battleState.allUnits[piece.uid], piece);
          } else {
            battleState.allUnits[piece.uid] = piece;
          }
        }

        if (piece.tile) {
          if (battleState.allTiles[piece.uid]) {
            Object.assign(battleState.allTiles[piece.uid], piece);
          } else {
            battleState.allTiles[piece.uid] = piece;
          }
        }
      }
    }
  } catch (err) {
    logError(err, {
      module: 'BattleStore',
      func: 'processAffectedPieces',
      affectedPieces,
    });
  }
}

function heroWait() {
  try {
    var lastOrderedUnit = getUnitToOrder();

    const i = unitsWaitingForOrders.findIndex((uid) => uid === lastOrderedUnit);
    if (i > -1) {
      unitsWaitingForOrders.splice(i, 1);
    }

    if (getUnitToOrder()) {
      BattleStore.emit(BattleStore.ORDERS_REQUESTED);
    } else {
      _socket.emit('battle_command', {
        playerId,
        command: 'resumeTime',
        battle_id: battleState.battle_id,
      });
    }
  } catch (err) {
    logError(err, {
      module: 'BattleStore',
      func: 'heroWait',
    });
  }
}

function onTimeResumed(data) {
  try {
    actingAIUnit = null;
    unitsWaitingForOrders = [];

    battleState.allUnits = data.allUnits;

    _statRecovery.setUnits(battleState.allUnits);
    _statRecovery.resume();

    for (var uid in data.allUnits) {
      battleState.allPieces[uid] = data.allUnits[uid];
    }
    // battleState.updatePieces( data.allPieces );
    BattleStore.emit(BattleStore.RESUMING_TIME);

    selectedTarget = null;
    BattleStore.emit(BattleStore.TARGET_SELECTED);

    selectedAbility = null;
    BattleStore.emit(BattleStore.ABILITY_SELECTED);
  } catch (err) {
    logError(err, {
      module: 'BattleStore',
      func: 'onTimeResumed',
      data,
    });
  }
}

function onAITurn(data) {
  try {
    actingAIUnit = data.aiUnitId;

    battleState.allUnits = data.allUnits;

    _statRecovery?.pause();
    _statRecovery?.setUnits(battleState.allUnits);

    for (let uid in data.allUnits) {
      battleState.allPieces[uid] = data.allUnits[uid];
    }
    BattleStore.emit(BattleStore.AI_TURN);

    boardCursor = {
      x: null,
      y: null,
    };

    selectedTarget = null;
    BattleStore.emit(BattleStore.TARGET_SELECTED);

    selectedAbility = null;
    BattleStore.emit(BattleStore.ABILITY_SELECTED);
  } catch (err) {
    logError(err, {
      module: 'BattleStore',
      func: 'onAITurn',
      data,
    });
  }
}

function onMoveBtnTouch(action) {
  try {
    let positionChange;
    switch (action.dirIndex) {
      case Game.MOVES.NORTH:
        positionChange = { x: 0, y: -2 };
        break;
      case Game.MOVES.NORTHEAST:
        positionChange = { x: 1, y: -1 };
        break;
      case Game.MOVES.SOUTHEAST:
        positionChange = { x: 1, y: 1 };
        break;
      case Game.MOVES.SOUTH:
        positionChange = { x: 0, y: 2 };
        break;
      case Game.MOVES.SOUTHWEST:
        positionChange = { x: -1, y: 1 };
        break;
      case Game.MOVES.NORTHWEST:
        positionChange = { x: -1, y: -1 };
        break;
      default:
        throw new Error('Unhandled action.dirIndex');
    }

    if (selectedAbility) {
      boardCursor.x += positionChange.x;
      boardCursor.y += positionChange.y;
      BattleStore.emit(BattleStore.BOARD_CURSOR_CHANGE);
    } else {
      const unitToOrder = battleState.allPieces[getUnitToOrder()];
      selectGamePiece({
        gamePiece: battleState.getTileAtCoords(
          unitToOrder.x + positionChange.x,
          unitToOrder.y + positionChange.y
        ),
      });
    }
  } catch (err) {
    logError(err, {
      module: 'BattleStore',
      func: 'onMoveBtnTouch',
      action,
    });
  }
}

function onExecuteBtnTouch(action) {
  try {
    let gamePiece;

    // try units first
    for (const unit of Object.values(battleState.allUnits)) {
      if (unit.x === boardCursor.x && unit.y === boardCursor.y) {
        selectGamePiece({
          gamePiece: unit,
        });
        return;
      }
    }

    for (const tile of Object.values(battleState.allTiles)) {
      if (tile.x === boardCursor.x && tile.y === boardCursor.y) {
        selectGamePiece({
          gamePiece: tile,
        });
        return;
      }
    }
  } catch (err) {
    logError(err, {
      module: 'BattleStore',
      func: 'onExecuteBtnTouch',
      action,
    });
  }
}

function onInsufficientStats(data) {
  try {
    BattleStore.emit(BattleStore.INSUFFICIENT_STATS, data);
  } catch (err) {
    logError(err, {
      module: 'BattleStore',
      func: 'onInsufficientStats',
      data,
    });
  }
}

function onIllegalAction(data) {
  try {
    BattleStore.emit(BattleStore.ILLEGAL_ACTION, data);
  } catch (err) {
    logError(err, {
      module: 'BattleStore',
      func: 'onIllegalAction',
      data,
    });
  }
}

function onUnitCondition(condition) {
  try {
    const owner = battleState.allPieces[condition.ownerId];
    if (!owner) {
      return;
    }

    if (!owner.conditions) {
      owner.conditions = {};
    }
    owner.conditions[condition.uid] = condition;

    BattleStore.emit(BattleStore.UNIT_CONDITION, condition);
  } catch (err) {
    logError(err, {
      module: 'BattleStore',
      func: 'onUnitCondition',
      condition,
    });
  }
}

function onUnitConditionExpired(data) {
  try {
    BattleStore.emit(BattleStore.UNIT_CONDITION_EXPIRED, data);
    delete battleState.allUnits[data.ownerId].conditions[data.conditionId];
  } catch (err) {
    logError(err, {
      module: 'BattleStore',
      func: 'onUnitConditionExpired',
      data,
    });
  }
}

function onUnitState(unit_state) {
  try {
    battleState.allPieces[unit_state.uid] = unit_state;
    battleState.allUnits[unit_state.uid] = unit_state;
    BattleStore.emit(BattleStore.GOT_UNIT_STATE, unit_state);
  } catch (err) {
    logError(err, {
      module: 'BattleStore',
      func: 'onUnitState',
      unit_state,
    });
  }
}

function onChallengeResolved(data) {
  try {
    debrief = data;
    BattleStore.emit(BattleStore.CHALLENGE_RESOLVED, data);
    clearBattleData();
    clearInterval(_health_ping_interval);
  } catch (err) {
    logError(err, {
      module: 'BattleStore',
      func: 'onChallengeResolved',
      data,
    });
  }
}

function killBattle() {
  if (battleState && battleState.battle_id && _socket && _socket.connected) {
    _socket.emit('battle_command', {
      command: 'killBattle',
      battle_id: battleState.battle_id,
      killed_by: playerId,
    });
  }
  BattleStore.emit(BattleStore.BATTLE_KILLED);
}

function clearBattleData() {
  try {
    if (_statRecovery) {
      _statRecovery.dispose();
      _statRecovery = null;
    }

    battleState = null;
    unitsWaitingForOrders = [];
    selectedAbility = null;
    actingAIUnit = null;
    boardCursor = {
      x: null,
      y: null,
    };
  } catch (err) {
    logError(err, {
      module: 'BattleStore',
      func: 'clearBattleData',
    });
  }
}

function receiveHealthOKPing(data) {
  try {
    if (!battleState) {
      throw new Error('got health ping but no battleState');
    }

    if (data.battle_id !== battleState.battle_id) {
      throw new Error('health ping battle_id mismatch');
    }

    _last_health_ping_at = Date.now();

    if (_recovering_engine) {
      $addMessageLogMessage(
        text('ui.battle_connection_recovered'),
        Colors.GREEN
      );
      _recovering_engine = false;
    }
  } catch (err) {
    logError(err, {
      module: 'BattleStore',
      func: 'receiveHealthOKPing',
      data,
    });
  }
}

function onEngineRecoveryFailed(data) {
  try {
    if (!battleState) {
      throw new Error('got engine_recovery_failed but no battleState');
    }

    if (data.battle_id !== battleState.battle_id) {
      throw new Error('engine_recovery_failed battle_id mismatch');
    }

    $addMessageLogMessage(text('ui.engine_recovery_failed'), Colors.RED);
    BattleStore.emit(BattleStore.ENGINE_RECOVERY_FAILED, data);

    clearBattleData();
    clearInterval(_health_ping_interval);
  } catch (err) {
    logError(err, {
      module: 'BattleStore',
      func: 'onEngineRecoveryFailed',
      data,
    });
  }
}

function onGameSubmodeSelection(action) {
  if (action.current_game_mode === Game.GAME_MODES.GAME_MODE_pvpLive) {
    _socket.emit('leave_standard_matchmaking_queue', {
      game_submode: action.game_submode,
      playerId,
    });
  }
}

function onUINav(action) {
  _socket.emit('leave_standard_matchmaking_queue', {
    game_submode: action.game_submode,
    playerId,
  });
}
