import heroes from './heroes';
import ui from './ui';

const chat = {
  help_msg: 'Введите /help, чтобы просмотреть список команд.',
  make_a_username:
    "Перед отправкой сообщений в чат следует указать имя пользователя (нажмите 'Новый игрок' в правом верхнем углу).",

  help_info: [
    'КОМАНДЫ ЧАТА:',
    // '/challenge <player name> - Назначить матч между вами и определенным игроком (тип матча такой же, как и вашего предыдущего матча).',
    '/color - Изменить цвет сообщений в чате.',
    '/emotes - Отобразить список доступных эмоций.',
    "/gender <субъект>/<объект>/<притяжательный падеж> - Настройте способ обращения к вам. Также можно использовать шорткаты '/gender male' или '/gender female'",
    '/online - Посмотрите, кто сейчас с вами в чате.',
    '/mute <имя игрока> - Перестать получать сообщения от игрока в чате.',
    '/unmute <имя игрока> - Разрешите снова получать сообщения от этого игрока.',
    '/muted <player name> - Посмотрите список отключенных игроков.',
  ],
  players_online: 'Игроки в этом чате',
  your_emotes: 'ВАШИ ЭМОЦИИ:',
  gender_changed: ({ subject, object, possessive }) => {
    return `Способ обращения к вам изменен: ${subject}/${object}/${possessive}`;
  },
  male: 'мужской',
  female: 'женский',

  muted_player: (playerName) => `Звук игрока ${playerName} отключен.`,
  unmuted_player: (playerName) => `Звук игрока ${playerName} был включен.`,
  muted_players_heading: 'Приглушенные игроки',

  notifications: {
    hero_unlocked: (userName, data) => {
      return (
        userName +
        ' разблокировал(а) персонажа ' +
        heroes[data.heroHandle].name +
        '!'
      );
    },

    hero_level_up: (userName, data) => {
      return (
        userName +
        "'s " +
        heroes[data.heroHandle].name +
        ' достиг(ла) уровня ' +
        data.level +
        '!'
      );
    },

    prayer_slot_unlocked: (userName, data) => {
      return (
        userName +
        ' разблокировал(а) ячейку молитвы #' +
        data.numPrayerSlots +
        '!'
      );
    },

    altar_level_up: (userName, data) => {
      return (
        'Слава богам! Уровень алтаря ' +
        userName +
        ' повышен до ' +
        data.level +
        '!'
      );
    },

    matchmaking_queue: ({ player_display_name, game_submode }) =>
      `${player_display_name} только что встал(а) в очередь боев на Арене ${ui.game_submodes[game_submode]}.`,
  },

  emotes: {
    enter_channel: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      return `${userName} заходит в чат.`;
    },

    smile: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      if (!target_displayName) {
        return `${actor.displayName} улыбается.`;
      } else if (actor.displayName === target_displayName) {
        return userName + ' улыбается про себя.';
      } else {
        return userName + ' улыбается в ответ ' + target_displayName + '.';
      }
    },

    lol: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' хохочет!';
      } else if (actor.displayName === target_displayName) {
        return userName + ' пытается сдержать смех, но безуспешно.';
      } else {
        return userName + ' хохочет в ответ ' + target_displayName + '.';
      }
    },

    nod: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' энергично кивает.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' клюет носом...';
      } else {
        return userName + ' кивает в ответ ' + target_displayName + '.';
      }
    },

    headshake: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' качает головой.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' вполголоса бранится и качает головой.';
      } else {
        return (
          userName +
          ' бросает взгляд на ' +
          target_displayName +
          ' и качает головой.'
        );
      }
    },

    wave: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' машет рукой в знак приветствия.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' машет рукой в знак прощания.';
      } else {
        return userName + ' машет рукой ' + target_displayName + '.';
      }
    },

    bow: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' вежливо кланяется.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' отвешивает низкий поклон.';
      } else {
        return userName + ' кланяется ' + target_displayName + '.';
      }
    },

    dance: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' исполняет шуточный танец!';
      } else if (actor.displayName === target_displayName) {
        return userName + ' зажигательно танцует.';
      } else {
        return userName + ' пританцовывает вокруг ' + target_displayName + '.';
      }
    },

    eyeroll: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' в раздражении закатывает глаза.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' бьется в припадке.';
      } else {
        return (
          userName + ' закатывает глаза в ответ ' + target_displayName + '.'
        );
      }
    },

    glare: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' недовольно таращится.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' сводит глаза к переносице.';
      } else {
        return (
          userName +
          ' бросает недовольный взгляд на ' +
          target_displayName +
          '.'
        );
      }
    },

    snicker: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' хихикает. Что тут смешного?';
      } else if (actor.displayName === target_displayName) {
        return userName + ' хрюкает от смеха.';
      } else {
        return userName + ' хихикает в ответ ' + target_displayName + '.';
      }
    },

    giggle: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' смеется, как ребенок.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' не может перестать смеяться!';
      } else {
        return userName + ' смеется в ответ ' + target_displayName + '.';
      }
    },

    yawn: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' зевает.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' широко зевает.';
      } else {
        return (
          userName +
          ' больше не в состоянии слушать ' +
          target_displayName +
          ' и зевает.'
        );
      }
    },

    cry: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' ударяется в слезы.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' безутешно рыдает.';
      } else {
        return (
          userName +
          ' с болью взирает на ' +
          target_displayName +
          ' и всхлипывает.'
        );
      }
    },

    cheer: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' радуется — ура!';
      } else if (actor.displayName === target_displayName) {
        return userName + ' сияет от гордости.';
      } else {
        return userName + ' радуется в ответ ' + target_displayName + '!';
      }
    },

    scream: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' кричит!';
      } else if (actor.displayName === target_displayName) {
        return userName + ' отводит плечи назад и заходится в вопле!';
      } else {
        return userName + ' кричит на ' + target_displayName + '!';
      }
    },

    cower: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' пугается.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' сжимается в клубок.';
      } else {
        return userName + ' пугается в ответ ' + target_displayName + '.';
      }
    },

    cackle: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' гогочет как сумасшедший(ая)!';
      } else if (actor.displayName === target_displayName) {
        return (
          userName + ' безудержно хихикает и от этого заходится в кашле...'
        );
      } else {
        return (
          userName + ' истерично хохочет в ответ ' + target_displayName + '!'
        );
      }
    },

    sigh: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' вздыхает.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' вздыхает с облегчением.';
      } else {
        return (
          userName +
          ' бросает на ' +
          target_displayName +
          ' грустный взгляд и вздыхает.'
        );
      }
    },

    tap: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' стучит.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' в знак осознания постукивает пальцем по лбу.';
      } else {
        return userName + ' похлопывает ' + target_displayName + ' по плечу.';
      }
    },

    poke: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' хочет кого-нибудь ткнуть.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' ковыряет носком землю. Милота!';
      } else {
        return (
          userName + ' наносит ' + target_displayName + ' тычок под ребра.'
        );
      }
    },

    laugh: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' заливается смехом!';
      } else if (actor.displayName === target_displayName) {
        return userName + ' заливается смехом в ответ ' + `${object}self` + '.';
      } else {
        return (
          userName + ' заливается смехом в ответ ' + target_displayName + '!'
        );
      }
    },

    hug: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' скрещивает руки на груди.';
      } else if (actor.displayName === target_displayName) {
        return (
          'Появляются санитары, одевают ' +
          userName +
          ' в смирительную рубашку и увозят в дурку!'
        );
      } else {
        return userName + ' обнимает ' + target_displayName + '. Милота...';
      }
    },

    taunt: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' грозит начать потасовку.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' почему-то втихую злится.';
      } else {
        return (
          userName + ' показывает ' + target_displayName + ' неприличный жест.'
        );
      }
    },

    flex: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' поигрывает мускулами!';
      } else if (actor.displayName === target_displayName) {
        return userName + ' потягивается, как кошка после сна.';
      } else {
        return (
          userName +
          ' поигрывает мускулами, красуясь перед ' +
          target_displayName +
          '.'
        );
      }
    },

    grit: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' скрипит зубами.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' корчится от боли.';
      } else {
        return userName + ' скрипит зубами в ответ ' + target_displayName + '.';
      }
    },

    point: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' заостряет внимание на теме.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' указывает на ' + `${object}self` + '.';
      } else {
        return userName + ' указывает на ' + target_displayName + '.';
      }
    },

    scratch: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' чертит линии на земле.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' чешет затылок.';
      } else {
        return userName + ' чешет спинку ' + target_displayName + '.';
      }
    },

    shrug: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' пожимает плечами.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' пожимает плечами. Кто знает?';
      } else {
        return (
          userName +
          ' смотрит на ' +
          target_displayName +
          ' и пожимает плечами.'
        );
      }
    },

    glance: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' взирает в пустоту.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' отводит глаза.';
      } else {
        return userName + ' бросает взгляд на ' + target_displayName + '.';
      }
    },

    clap: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' громко хлопает в ладоши!';
      } else if (actor.displayName === target_displayName) {
        return userName + ' хлопает по лбу.';
      } else {
        return userName + ' аплодирует ' + target_displayName + '!';
      }
    },

    applaud: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' аплодирует!';
      } else if (actor.displayName === target_displayName) {
        return userName + ' поздравляет ' + `${object}self` + '.';
      } else {
        return userName + ' вызывает на бис ' + target_displayName + '!';
      }
    },

    wink: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' подмигивает.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' мигает — что-то в глаз попало.';
      } else {
        return userName + ' подмигивает ' + target_displayName + '.';
      }
    },

    ban: (actor, target_displayName) => {
      const userName = actor.displayName;
      const {
        subject = 'they',
        object = 'them',
        possessive = 'their',
      } = actor.gender || {};

      return (
        'С небес спускается Дракон хороших манер и проглатывает ' +
        target_displayName +
        '.'
      );
    },
  },
};
export default chat;
