export default {
  en: { emoji: '🇺🇸', name: 'English' },
  ru: { emoji: '🇷🇺', name: 'Русский' },
  zh: { emoji: '🇨🇳', name: '中文' },
  hi: { emoji: '🇮🇳', name: 'हिन्दी' },
  es: { emoji: '🇪🇸', name: 'Español' },
  fr: { emoji: '🇫🇷', name: 'Français' },
  ar: { emoji: '🇸🇦', name: 'العربية' },
  pt: { emoji: '🇵🇹', name: 'Português' },
  tr: { emoji: '🇹🇷', name: 'Türkçe' },
  ko: { emoji: '🇰🇷', name: '한국어' },
  de: { emoji: '🇩🇪', name: 'Deutsch' },
};
