import * as PIXI from 'pixi.js';
import Colors from '~/constants/Colors';
import CanvasTools from '~/view/CanvasTools';

const TutorialBox = function (text, arrowDirection = 'none', maxWidth = 350) {
  PIXI.Container.call(this);

  this.dispose = () => {
    this.removeChildren();
  };

  this.body = new PIXI.Text(text, {
    fontFamily: 'Courier New',
    fontSize: CanvasTools.dynamicFontSizeString(18),
    fill: Colors.BRIGHT_YELLOW,
    wordWrap: true,
    wordWrapWidth: maxWidth,
  });

  this._width = this.body.width + 17;
  this._height = this.body.height + 17;

  CanvasTools.addBackFill(this, Colors.MP_BLUE);
  CanvasTools.addBorder(
    this,
    'chat_border_horiz.png',
    'chat_border_vert.png',
    'chat_border_horiz.png',
    'chat_border_vert.png',
    0
  );

  this.body.x = Math.round(this._width / 2 - this.body.width / 2);
  this.body.y = Math.round(this._height / 2 - this.body.height / 2);
  this.addChild(this.body);

  if (arrowDirection === 'none') {
    this.pivot = {
      x: this._width / 2,
      y: this._height / 2,
    };
  } else {
    const arrowImg = new PIXI.Text('', {
      fontFamily: 'Courier New',
      fontSize: CanvasTools.dynamicFontSizeString(16),
      fill: Colors.BRIGHT_YELLOW,
      fontStyle: 'bold',
    });

    switch (arrowDirection) {
      case 'up':
        arrowImg.text = '^\n' + '|\n' + '|\n' + '|';
        arrowImg.x = Math.round(this._width / 2 - arrowImg.width / 2);
        arrowImg.y = Math.round(-arrowImg.height);
        this.pivot = {
          x: Math.round(this._width / 2),
          y: Math.round(arrowImg.y),
        };
        break;

      case 'down':
        arrowImg.text = '|\n' + '|\n' + '|\n' + 'v\n';
        arrowImg.x = Math.round(this._width / 2 - arrowImg.width / 2);
        arrowImg.y = Math.round(this._height);
        this.pivot = {
          x: Math.round(this._width / 2),
          y: Math.round(arrowImg.y + arrowImg.height),
        };
        break;

      case 'left':
        arrowImg.text = '<---';
        arrowImg.x = Math.round(-arrowImg.width);
        arrowImg.y = Math.round(this._height / 2 - arrowImg.height / 2);
        this.pivot = {
          x: Math.round(arrowImg.x - arrowImg.width),
          y: Math.round(this._height / 2),
        };
        break;

      case 'right':
        arrowImg.text = '--->';
        arrowImg.x = Math.round(this._width);
        arrowImg.y = Math.round(this._height / 2 - arrowImg.height / 2);
        this.pivot = {
          x: Math.round(arrowImg.x + arrowImg.width),
          y: Math.round(this._height / 2),
        };
        break;
    }

    this.addChild(arrowImg);
  }
};
TutorialBox.prototype = Object.create(PIXI.Container.prototype);
TutorialBox.prototype.constructor = TutorialBox;
export default TutorialBox;
