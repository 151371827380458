import { EventEmitter } from 'events';
import SettingsDispatcher from '~/flux/dispatchers/SettingsDispatcher';
import { registerDispatchHandlers } from '~/Tools';

// the stuff we serve:
let musicVolume = parseFloat(localStorage.getItem('dt_musicVolume'), 10);
if (isNaN(musicVolume)) {
  musicVolume = 0.5;
}
let sfxVolume = parseFloat(localStorage.getItem('dt_sfxVolume'), 10);
if (isNaN(sfxVolume)) {
  sfxVolume = 0.5;
}

const SettingsStore = Object.assign({}, EventEmitter.prototype, {
  BROWSER_LANGUAGE_CHANGED: 'BROWSER_LANGUAGE_CHANGED',

  getAll() {
    return {
      musicVolume,
      sfxVolume,
    };
  },
});
export default SettingsStore;

SettingsDispatcher.register(
  registerDispatchHandlers({
    [SettingsDispatcher.CHANGE_BROWSER_LANGUAGE]: changeBrowserLanguage,
    [SettingsDispatcher.SAVE_SETTINGS]: saveSettings,
  })
);

function changeBrowserLanguage() {
  setTimeout(
    () => SettingsStore.emit(SettingsStore.BROWSER_LANGUAGE_CHANGED),
    100
  );
}

function saveSettings(action) {
  musicVolume = action.settings.musicVolume;
  localStorage.setItem('dt_musicVolume', musicVolume);

  sfxVolume = action.settings.sfxVolume;
  localStorage.setItem('dt_sfxVolume', sfxVolume);
}
