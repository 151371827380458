import { EventEmitter } from 'events';

import { ApplicationDispatcher, PlayerDispatcher } from '~/flux/dispatchers';
import { awaitSocket, registerDispatchHandlers } from '~/Tools';
import text from '~/text';
import Colors from '~/constants/Colors';

let _socket;

// the stuff we serve:
let all_players = [];
let loggedInPlayerId = false;
let displayName = '';
let social = {};

const getLoggedInPlayer = () =>
  all_players.find((p) => p._id === loggedInPlayerId);

const PlayerStore = Object.assign({}, EventEmitter.prototype, {
  GOT_MULTIPLE_PLAYERS: 'GOT_MULTIPLE_PLAYERS',
  PLAYER_LOGGED_IN: 'PLAYER_LOGGED_IN',
  NEW_PLAYER_NAME: 'NEW_PLAYER_NAME',
  NEWSLETTER_SIGNUP_ERROR: 'NEWSLETTER_SIGNUP_ERROR',
  NEWSLETTER_SIGNUP_SUCCESS: 'NEWSLETTER_SIGNUP_SUCCESS',
  SOCIAL_CLICK_REGISTERED: 'SOCIAL_CLICK_REGISTERED',

  getAll() {
    return {
      all_players,
      loggedInPlayerId,
      loggedInPlayer: getLoggedInPlayer(),
      displayName,
      social,
    };
  },
});
export default PlayerStore;

PlayerDispatcher.register(
  registerDispatchHandlers({
    [PlayerDispatcher.LOGIN_PLAYER]: loginPlayer,
    [PlayerDispatcher.ENTER_NEW_PLAYER_NAME]: requestPlayerNameChange,
    [PlayerDispatcher.SUBMIT_NEWSLETTER_SIGNUP]: submitNewsletterSignup,
    [PlayerDispatcher.SOCIAL_CLICK]: registerSocialClick,
  })
);

awaitSocket(onSocketConnected);
function onSocketConnected(socket) {
  try {
    _socket = socket;

    if (!_socket.has_PlayerStore_listeners) {
      _socket.io.on('reconnect', reconnectPlayer);

      _socket.on('accountDetails', onAccountDetails);
      _socket.on('player_logged_in', onPlayerLoggedIn);
      _socket.on('newPlayerName', onNewPlayerName);
      _socket.on('socialClickRegistered', onSocialClickRegistered);
      _socket.on('mailchimp_novice_ring_message', onMailchimpNoviceRingMessage);
      _socket.on('newsletter_signup_error', onNewsletterSignupError);
      _socket.on('newsletter_signup_success', onNewsletterSignupSuccess);

      _socket.has_PlayerStore_listeners = true;
    }

    if (loggedInPlayerId && socket.do_reconnect_player) {
      reconnectPlayer();
      socket.do_reconnect_player = false;
    }
  } catch (err) {
    logError(err, {
      module: 'PlayerStore',
      func: 'onSocketConnected',
    });
  }
}

function onAccountDetails(data) {
  try {
    if (!Array.isArray(data.all_players) || !data.all_players.length) {
      throw new Error('no player data');
    }

    all_players = data.all_players;

    if (all_players.length > 1) {
      PlayerStore.emit(PlayerStore.GOT_MULTIPLE_PLAYERS, all_players);
    } else {
      loginPlayer({ playerId: all_players[0]._id });
    }
  } catch (err) {
    logError(err, {
      module: 'PlayerStore',
      func: 'onAccountDetails',
      all_players,
    });
  }
}

function loginPlayer(action) {
  try {
    const player = all_players.find((p) => p._id === action.playerId);
    const { _id, scriptData, privateData } = player;

    loggedInPlayerId = _id;
    if (process.env.DT_TIER === 'localdev') {
      console.info({ loggedInPlayerId });
    }
    displayName = scriptData.displayName;
    social = privateData.social;

    _socket.emit('login_player', { playerId: _id });
  } catch (err) {
    logError(err, {
      module: 'PlayerStore',
      func: 'loginPlayer',
      action,
    });
  }
}

function onPlayerLoggedIn({ playerId }) {
  try {
    if (playerId !== loggedInPlayerId) {
      throw new Error('playerId mismatch');
    }
    const player = all_players.find((p) => p._id === playerId);
    PlayerStore.emit(PlayerStore.PLAYER_LOGGED_IN, player);
  } catch (err) {
    logError(err, {
      module: 'PlayerStore',
      func: 'onPlayerLoggedIn',
      playerId,
    });
  }
}

function reconnectPlayer() {
  if (loggedInPlayerId) {
    const playerId = loggedInPlayerId;
    _socket.emit('reconnect_player', { playerId });
    setTimeout(() => {
      _socket.emit('login_player', {
        playerId,
        is_reconnection: true,
      });
    }, 500);
  }
}

function requestPlayerNameChange(action) {
  const { newPlayerName } = action;

  // validation is done in the API, but lets try to avoid sending overly long strings
  if (!newPlayerName || newPlayerName.length < 5 || newPlayerName.length > 20) {
    $addMessageLogMessage(text('server.error.player_name_rule_2'), Colors.RED);
    return;
  }

  _socket.emit('request_player_name_change', {
    playerId: loggedInPlayerId,
    newPlayerName,
  });
}

function onNewPlayerName(data) {
  displayName = data.newPlayerName;
  PlayerStore.emit(PlayerStore.NEW_PLAYER_NAME);
}

function submitNewsletterSignup(action) {
  _socket.emit('submit_newsletter_signup', {
    playerId: loggedInPlayerId,
    email: action.email,
  });
}

function onNewsletterSignupError(data) {
  PlayerStore.emit(PlayerStore.NEWSLETTER_SIGNUP_ERROR, data.detail);
}

function onNewsletterSignupSuccess() {
  social.signed_up_newsletter = true;
  PlayerStore.emit(PlayerStore.NEWSLETTER_SIGNUP_SUCCESS);
}

function registerSocialClick(action) {
  _socket.emit('register_social_click', {
    playerId: loggedInPlayerId,
    platformTag: action.platformTag,
  });
}

function onSocialClickRegistered(data) {
  social = data.social;
  PlayerStore.emit(PlayerStore.SOCIAL_CLICK_REGISTERED);
}

function onMailchimpNoviceRingMessage() {
  $addMessageLogMessage(
    text('ui.mailing_list_bonus_item_msg.1'),
    Colors.BRIGHT_YELLOW
  );
  $addMessageLogMessage(
    text('ui.mailing_list_bonus_item_msg.2'),
    Colors.BRIGHT_YELLOW
  );
  $addMessageLogMessage(
    text('ui.mailing_list_bonus_item_msg.3'),
    Colors.BRIGHT_YELLOW
  );
  $addMessageLogMessage('...', Colors.BRIGHT_YELLOW);
  $addMessageLogMessage(
    text('ui.mailing_list_bonus_item_msg.4'),
    Colors.BRIGHT_YELLOW
  );
}
