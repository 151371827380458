import * as PIXI from 'pixi.js';
import EquipmentFigure from '~/components/common/EquipmentFigure'; // TODO(@robertlombardo): move to ~/view/components/common
import { Screens } from '~/constants';
import { UIActions } from '~/flux/actions';
import { PuppetWithNameplate } from '~/view/components/common/canvas';

const EngagedSquadView = function ({
  hero_builds,
  loadout_squad_list,
  roster_heroes,
  puppet_scale = 1,
  do_transition_in,
  face_direction = false, // should default to Game.SOUTH
  is_opponent = false,
  before_battle,
  getHorizontalPuppetSpacing,
  getVerticalPuppetSpacing,
  perma_vamp = null,
}) {
  PIXI.Container.call(this);

  let _bg;
  let _equipment_figure;
  let _puppets = [];

  this.getPuppets = () => _puppets;

  this.dispose = () => {
    loadout_squad_list = null;
    destroyEquipmentFigure();
    destroyPuppets();
    this.destroy();
  };

  const destroyPuppets = () => {
    for (const puppet of _puppets || []) {
      puppet?.dispose();
    }
    _puppets = null;
  };

  const makePuppets = () => {
    try {
      destroyPuppets();
      _puppets = [];

      const sorted_squad_list = loadout_squad_list.sort(
        (a, b) => a.engagement.slot - b.engagement.slot
      );
      for (const loadout_hero of sorted_squad_list) {
        const i = _puppets.length;
        const y_drift = Math.abs(
          i + 0.5 - Object.keys(loadout_squad_list).length / 2
        );

        const puppet = new PuppetWithNameplate({
          built_hero: hero_builds[loadout_hero.handle].find(
            ({ _id }) => _id === loadout_hero.hero_build_id
          ),
          roster_hero: roster_heroes[loadout_hero.handle],
          scale_mod: puppet_scale,
          show_build_name: true,
          before_battle,
          perma_vamp,
          face_direction,
          show_no_abilities_warning: !is_opponent,
        });
        puppet.x =
          typeof getHorizontalPuppetSpacing === 'function'
            ? getHorizontalPuppetSpacing({ puppet_index: i })
            : Math.round(i * PUPPET_HORIZ_SPACING);
        puppet.y =
          typeof getVerticalPuppetSpacing === 'function'
            ? getVerticalPuppetSpacing({ puppet_index: i, y_drift })
            : DT_CANVAS_GLOBALS.spacing * 3.75 + Math.round(y_drift * 10);
        puppet.buttonMode = puppet.interactive = true;
        this.addChild(puppet);
        _puppets.push(puppet);

        do_transition_in && puppet.transitionIn();

        if (is_opponent) {
          puppet.tap = puppet.click = makeEquipmentFigure;
        } else {
          puppet.tap = puppet.click = () => {
            UIActions.focusHeroBuild({
              hero_handle: loadout_hero.handle,
              build_id: loadout_hero.hero_build_id,
            });
            UIActions.uiNav({ screen_id: Screens.EDIT_HERO });
          };
        }
      }
    } catch (err) {
      logError(err, {
        module: 'EngagedSquadView',
        func: 'makePuppets',
      });
    }
  };

  const makeEquipmentFigure = (event) => {
    destroyEquipmentFigure();

    _bg = new PIXI.Graphics();
    _bg.beginFill(0, 0.75);
    _bg.drawRect(0, 0, window.innerWidth, window.innerHeight);
    _bg.endFill();
    TweenMax.from(_bg, 0.35, { alpha: 0 });
    _bg.interactive = true;
    _bg.tap = _bg.click = hideEquipmentFigure;
    DT_CANVAS_GLOBALS.stage.addChild(_bg);

    _equipment_figure = new EquipmentFigure(
      event.target.puppet.actor.equipment
    );
    _equipment_figure.init();
    const { x, y } = event.data.global;
    _equipment_figure.x = x;
    _equipment_figure.y = y;
    TweenMax.from(_equipment_figure.scale, 0.2, { x: 0, y: 0 });
    DT_CANVAS_GLOBALS.stage.addChild(_equipment_figure);
  };

  const hideEquipmentFigure = () => {
    if (_equipment_figure) {
      TweenMax.to(_equipment_figure.scale, 0.15, { x: 0, y: 0 });
      TweenMax.to(_bg, 0.2, { alpha: 0, onComplete: destroyEquipmentFigure });
    }
  };

  const destroyEquipmentFigure = () => {
    _equipment_figure?.dispose();
    if (_bg) {
      _bg.tap = _bg.click = null;
      _bg?.parent?.removeChild(_bg);
    }
  };

  makePuppets();
  this._width =
    getHorizontalPuppetSpacing({
      puppet_index: _puppets.length - 1,
    }) +
    puppet_scale * 45;
};
EngagedSquadView.prototype = Object.create(PIXI.Container.prototype);
EngagedSquadView.prototype.constructor = EngagedSquadView;
export default EngagedSquadView;
