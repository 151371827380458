<template>
  <Dialog :open="!state.did_start_game">
    <div class="fixed inset-0 flex items-center justify-center p-4">
      <DialogPanel
        class="border border-white w-[800px] max-h-[90vh] flex flex-col"
      >
        <DialogTitle class="relative border-b border-white">
          <img
            :src="`${Config.DOM_IMG_PATH}/banner_800x150${Config.LOCALE === 'ru' ? '_ru' : ''}.jpg`"
          />
        </DialogTitle>

        <div class="py-6 w-full flex justify-center">
          <div
            v-if="!state.assets_loaded"
            class="w-full h-[64px] flex flex-col text-center text-xs"
          >
            {{ text('ui.loading') }}
            <DTSpinner class="-mt-[85px]" />
          </div>
          <!-- play button -->
          <DTButton
            v-else
            class="w-52 px-8 py-4 text-2xl"
            type="success"
            @click="onPlayBtnClicked"
          >
            {{ text('ui.PLAY') }}
          </DTButton>
        </div>

        <!-- scrollable announcements/info area -->
        <div
          v-if="Config.PLATFORM !== 'yandex'"
          class="p-6 pt-0 overflow-auto"
        >
          <!-- accouncements section -->
          <!-- <div :class="classes.section_title">
            {{ text('ui.announcements') }}
          </div>
          <div class="text-sm">
            <p>
              2025.01.04 - Because today's Caverns changes (9 new mobs!)
              increase the difficulty significantly, I'm giving everyone
              <span style="color: cyan">1,000 Pixie Dust</span> in case you have
              to restart any squads at depth: 1.
            </p>
            <p>
              Those of you with squads deeper than level 100 will almost
              certainly have to restart. Apologies, but ~100 was always supposed
              to be the endgame depth.
            </p>
            <p>
              ...or ...if you really need me to, I can manually set your depth
              to something reasonable. Just DM rob-wfs on the Discord!
            </p>
          </div> -->

          <!-- devlog section -->
          <div :class="classes.section_title">
            {{ text('ui.devlog') }}
          </div>
          <div class="text-sm">
            <!-- devlog 2025.01.04 -->
            <div>
              <p class="mt-6"><b>2025.01.18</b> - Chat system update</p>
              <div class="pb-4 pl-4 w-full">
                <div
                  class="text-xs cursor-pointer text-yellow-600"
                  @click="
                    state.show_changelog['2025.01.18'] =
                      !state.show_changelog['2025.01.18']
                  "
                >
                  <div v-if="state.show_changelog['2025.01.18']">
                    <span>{{ '▲  ' }}</span
                    ><span>[{{ text('ui.hide_changelog') }}]</span>
                  </div>
                  <div v-else>
                    <span>{{ '▼  ' }}</span
                    ><span>[{{ text('ui.show_changelog') }}]</span>
                  </div>
                </div>
              </div>
              <ul
                v-if="state.show_changelog['2025.01.18']"
                class="ml-5 list-outside list-disc"
              >
                <li>
                  Chat
                  <ul>
                    <li>
                      - Instead of having a single, global chat channel... chat
                      rooms are now separated by language, as well as limited to
                      200 players each.
                    </li>
                    <li>
                      - Introduced three new chat commands:
                      <code>/mute &lt;player&gt;</code>,
                      <code>/unmute &lt;player&gt;</code>, and
                      <code>/muted</code> (to get a list of muted players).
                    </li>
                    <li>- You can no longer inject HTML into the chat :)</li>
                    <li>
                      - Posting links in chat is disallowed now, except for a
                      whitelist. DM rob-wfs on Discord if you want something
                      added to the whitelist.
                    </li>
                  </ul>
                </li>
                <li>
                  AI
                  <ul>
                    <li>
                      - Summon Dwarf will now target tiles near the nearest
                      enemy, not next to the caster.
                    </li>
                    <li>
                      - Support units should no longer send mana to summoned
                      units.
                    </li>
                    <li>
                      - Caverns squads will now buff & summon while rooms are
                      empty, instead of doing nothing.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            <!-- devlog 2025.01.04 -->
            <div>
              <p class="mt-6">
                <b>2025.01.04</b> - 9 new caverns mobs! Plus balance & AI
                fixes...
              </p>
              <div class="pb-4 pl-4 w-full">
                <div
                  class="text-xs cursor-pointer text-yellow-600"
                  @click="
                    state.show_changelog['2025.01.04'] =
                      !state.show_changelog['2025.01.04']
                  "
                >
                  <div v-if="state.show_changelog['2025.01.04']">
                    <span>{{ '▲  ' }}</span
                    ><span>[{{ text('ui.hide_changelog') }}]</span>
                  </div>
                  <div v-else>
                    <span>{{ '▼  ' }}</span
                    ><span>[{{ text('ui.show_changelog') }}]</span>
                  </div>
                </div>
              </div>
              <ul
                v-if="state.show_changelog['2025.01.04']"
                class="ml-5 list-outside list-disc"
              >
                <li>
                  Get ready to face these new mobs in the Caverns:
                  <ul>
                    <li>- Ectoplasm</li>
                    <li>- Fire Drake</li>
                    <li>- Flying Jelly</li>
                    <li>- Ghost</li>
                    <li>- Goo</li>
                    <li>- Lich</li>
                    <li>- Phantasm</li>
                    <li>- Voidwalker</li>
                    <li>- Wraith</li>
                  </ul>
                </li>
                <li>
                  Balance
                  <ul>
                    <li>
                      - Reduced duration of fears
                      <ul>
                        <li class="pl-4">
                          * Road to Nowhere:
                          <code>1 + L*(Le/100 + Pe/150)</code> -->
                          <code>1 + L*(Le/150 + Pe/200)</code>
                        </li>
                        <li class="pl-4">
                          * Devil Mask:
                          <code>0.5 + L*(Ma/250 + Le/350)</code> -->
                          <code>0.5 + L*(Ma/333 + Le/333)</code>
                        </li>
                      </ul>
                    </li>
                    <li>
                      - Reduced duration of Taunt:
                      <code>1 + L*(Wi/200 + Pe/250)</code> -->
                      <code>1 + L*(Wi/225 + Pe/275)</code>
                    </li>
                    <li>
                      - Increased duration of summoned Dwarves/Elves/Faeries:
                      <code>40 + L*2</code> --> <code>40 + L*5</code>
                    </li>
                    <li>
                      - Increased the AP cost of several abilities:
                      <ul>
                        <li class="pl-4">
                          * Road to Nowhere: <code>15</code> --> <code>50</code>
                        </li>
                        <li class="pl-4">
                          * Summon Dwarf/Elf/Faerie: <code>10</code> -->
                          <code>35</code>
                        </li>
                        <li class="pl-4">
                          * Psyche Out: <code>10</code> --> <code>35</code>
                        </li>
                        <li class="pl-4">
                          * Devil Mask: <code>40</code> --> <code>50</code>
                        </li>
                        <li class="pl-4">
                          * Poppets: <code>10</code> --> <code>35</code>
                        </li>
                        <li class="pl-4">
                          * Bless: <code>10</code> --> <code>25</code>
                        </li>
                      </ul>
                    </li>
                    <li>
                      - ONE ability, however, got an AP cost reduction - Stance
                      Dancing: <code>70</code> --> <code>50</code>
                    </li>
                    <li>
                      - Increased Summon Option range:
                      <code>5</code> --> <code>9</code>
                    </li>
                    <li>
                      - Reduced the damage of Fire/Thunder/Weepin/Venomous
                      weapon procs,
                    </li>
                    <li>
                      - Adjusted the gold prices of items in the shop (made
                      sensitive to prefixes/suffixes)
                    </li>
                    <li>- Increased stat bonuses for Trinket type items</li>
                  </ul>
                </li>
                <li>
                  AI
                  <ul>
                    <li>
                      - Fixed a bug causing ranged units to use AoE attacks when
                      single-target is better
                    </li>
                    <li>
                      - Fixed bugs in the movement AI, esp. for units with
                      Leap/Teleport
                    </li>
                    <li>
                      - Fixed a bug causing units to fumigate a lot of nearby
                      allies
                    </li>
                    <li>- Tanks will no longer taunt enemies who are feared</li>
                  </ul>
                </li>
                <li>Fixed the Suture ability</li>
                <li>
                  Fixed several bugs in damage calculations, especially for
                  Armor Spikes. Also made poison & bleed damage affected by
                  Affinities
                </li>
                <li>Caverns: play an alert sound when a room is cleared</li>
              </ul>
            </div>

            <!-- devlog 2024.12.30 -->
            <div>
              <p class="mt-6">
                <b>2024.12.30</b> - Introducting... hero affinities!
              </p>
              <div class="pb-4 pl-4 w-full">
                <div
                  class="text-xs cursor-pointer text-yellow-600"
                  @click="
                    state.show_changelog['2024.12.30'] =
                      !state.show_changelog['2024.12.30']
                  "
                >
                  <div v-if="state.show_changelog['2024.12.30']">
                    <span>{{ '▲  ' }}</span
                    ><span>[{{ text('ui.hide_changelog') }}]</span>
                  </div>
                  <div v-else>
                    <span>{{ '▼  ' }}</span
                    ><span>[{{ text('ui.show_changelog') }}]</span>
                  </div>
                </div>
              </div>
              <ul
                v-if="state.show_changelog['2024.12.30']"
                class="ml-5 list-outside list-disc"
              >
                <li>
                  HERO AFFINITIES: Now your level 30+ heroes can spend ability
                  points on "Affinity" upgrades, giving them bonus damage or
                  damage reduction against specific hero classes or monster
                  types. Find the Affinities interface in the lower left of the
                  Edit Abilities screen!
                </li>
                <li>
                  Fixed a serious bug causing Caverns mobs abilities always to
                  be level 0. So, you're going to find mobs a lot more dangerous
                  at the depths you're already at. Since this is likely to cause
                  some players to have to restart Caverns from the first level,
                  I'm giving everyone
                  <span style="color: cyan">1,000 Pixie Dust</span> to make up
                  for it.
                </li>
                <li>
                  Balance - increase Burning Hands damage scaling:<code
                    >65 + L*(Le/5 + Ma/7 + De/10)</code
                  >
                  --> <code>65 + L*(Le/3 + Ma/5 + De/7)</code>
                </li>
              </ul>
            </div>

            <!-- devlog 2024.12.26 -->
            <div>
              <p class="mt-6">
                <b>2024.12.26</b> - Small balance changes & AI fixes
              </p>
              <div class="pb-4 pl-4 w-full">
                <div
                  class="text-xs cursor-pointer text-yellow-600"
                  @click="
                    state.show_changelog['2024.12.26'] =
                      !state.show_changelog['2024.12.26']
                  "
                >
                  <div v-if="state.show_changelog['2024.12.26']">
                    <span>{{ '▲  ' }}</span
                    ><span>[{{ text('ui.hide_changelog') }}]</span>
                  </div>
                  <div v-else>
                    <span>{{ '▼  ' }}</span
                    ><span>[{{ text('ui.show_changelog') }}]</span>
                  </div>
                </div>
              </div>
              <ul
                v-if="state.show_changelog['2024.12.26']"
                class="ml-5 list-outside list-disc"
              >
                <li>
                  Balance
                  <ul>
                    <li>- Devil Mask</li>
                    <ul>
                      <li class="pl-4">
                        * AP cost: <code>10</code> --> <code>40</code>
                      </li>
                      <li class="pl-4">
                        * MP cost: <code>30 + L*3</code> -->
                        <code>30 + L*5</code>
                      </li>
                      <li class="pl-4">
                        * Effect range: <code>1 + L/5</code> --> <code>2</code>
                      </li>
                    </ul>
                    <li>
                      - Poison Weapon potency: <code>20 + L*(Le/15)</code> -->
                      <code>20 + L*(Le/5)</code>
                    </li>
                  </ul>
                </li>
                <li>
                  AI
                  <ul>
                    <li>
                      - Fixed a bug where Devil Mask could be cast with no
                      enemies in range
                    </li>
                    <li>
                      - For healing, heroes will now only target heroes in PvP
                    </li>
                    <li>
                      - For buffs, units will now start with the closest
                      unbuffed allies
                    </li>
                    <li>
                      - Similarly, Suture will now target the closest ally who
                      needs it
                    </li>
                    <li>
                      - Units will no longer use Leap/Teleport while feared
                    </li>
                  </ul>
                </li>
                <li>Options on poppets can now deal damage</li>
              </ul>
            </div>

            <!-- devlog 2024.12.21 -->
            <div>
              <p class="mt-6">
                <b>2024.12.21</b> - TWO ability points per level now? ~
                whaaaaaat ...
              </p>
              <div class="pb-4 pl-4 w-full">
                <div
                  class="text-xs cursor-pointer text-yellow-600"
                  @click="
                    state.show_changelog['2024.12.21'] =
                      !state.show_changelog['2024.12.21']
                  "
                >
                  <div v-if="state.show_changelog['2024.12.21']">
                    <span>{{ '▲  ' }}</span
                    ><span>[{{ text('ui.hide_changelog') }}]</span>
                  </div>
                  <div v-else>
                    <span>{{ '▼  ' }}</span
                    ><span>[{{ text('ui.show_changelog') }}]</span>
                  </div>
                </div>
              </div>
              <ul
                v-if="state.show_changelog['2024.12.21']"
                class="ml-5 list-outside list-disc"
              >
                <li>
                  You heard that right. Heroes now get not 1 but 2 ability
                  points per level.
                </li>
                <li>
                  Suture potency increased:
                  <code>20 + L*(Le/6 + De/4)</code> -->
                  <code>20 + L*(Le + De)</code>
                </li>
                <li>
                  Poison Weapon AP cost decreased:
                  <code>50 - L</code> -->
                  <code>30 - L</code>
                </li>
                <li>
                  When traveling in Caverns, any friendly dwarves in play will
                  now start out in front.
                </li>
                <li>
                  Fixed several Caverns battle log bugs, like null/undefined
                  unit names, and keeping your position while scrolling.
                </li>
                <li>
                  Limited the number of initial caverns mobs to 1~5, to avoid
                  overwhelming heroes before they've had a chance to
                  buff/summon.
                </li>
              </ul>
            </div>

            <!-- devlog 2024.12.14 -->
            <div>
              <p class="mt-6">
                <b>2024.12.14</b> - AI & bug fixes, game balance
              </p>
              <div class="pb-4 pl-4 w-full">
                <div
                  class="text-xs cursor-pointer text-yellow-600"
                  @click="
                    state.show_changelog['2024.12.14'] =
                      !state.show_changelog['2024.12.14']
                  "
                >
                  <div v-if="state.show_changelog['2024.12.14']">
                    <span>{{ '▲  ' }}</span
                    ><span>[{{ text('ui.hide_changelog') }}]</span>
                  </div>
                  <div v-else>
                    <span>{{ '▼  ' }}</span
                    ><span>[{{ text('ui.show_changelog') }}]</span>
                  </div>
                </div>
              </div>
              <ul
                v-if="state.show_changelog['2024.12.14']"
                class="ml-5 list-outside list-disc"
              >
                <li>Inn: Increased maintain/upgrade peanut thresholds again</li>
                <li>
                  Balance:
                  <ul>
                    <li>
                      - Lightning AP/MP costs adjusted
                      <ul>
                        <li class="pl-4">
                          * AP Cost: <code>25</code> --> <code>40 - L</code>
                        </li>
                        <li class="pl-4">
                          * MP Cost: <code>50 + L*4</code> -->
                          <code>30 + L*3</code>
                        </li>
                      </ul>
                    </li>
                    <li>
                      - Summon Dwarf/Elf/Faerie MP cost adjusted:
                      <code>100 + L*40</code> --> <code>150 + L*25</code>
                    </li>
                    <li>
                      - Holy force AP cost increased: <code>10</code> -->
                      <code>40 - L</code>
                    </li>
                    <li>- Dwarves: removed Shield Block and added Slam</li>
                    <li>
                      - Slam knockback reduced: <code>2 + L*( St/80 )</code> -->
                      <code>1 + L*( St/120 )</code>
                    </li>
                  </ul>
                </li>
                <li>
                  AI
                  <ul>
                    <li>
                      - Fixed a bug causing units sometimes to act before the
                      result of the previous ability was calculated (e.g.
                      shooting arrows at dead targets, double-casting CC).
                    </li>
                    <li>
                      - Fixed several bugs causing units sometimes to run back &
                      forth aimlessly
                    </li>
                    <li>
                      - In PvP, the Interference tree now only targets enemy
                      heroes
                    </li>
                    <li>
                      - Only in Caverns will DoT abilities be spread around
                      evenly. In PvP they'll now focus down one hero target.
                    </li>
                    <li>
                      - Archers should no longer melee attack adjacent enemies
                      when they have the opportunity to step back & shoot
                    </li>
                    <li>
                      - Tanks will no longer target stunned enemies with Taunt
                    </li>
                    <li>
                      - Increased the chances for DPS Close units to use Sky
                      Hammer and Slam
                    </li>
                    <li>
                      - Support units now consider groups of 2+ allies (from 3)
                      as a "cluster" for AoE heals
                    </li>
                    <li>- Units will no longer use Adrenaline while feared</li>
                  </ul>
                </li>
                <li>
                  Fixed bugs preventing use of XP Potions / Candles from hero
                  inventory
                </li>
                <li>
                  Fixed a bug causing summoned units' puppets sometimes not to
                  appear
                </li>
                <li>Added SFX to Razor Shot and Blunt Arrow</li>
                <li>
                  Tweaked renderings for Scared, Enraged, Road to Nowhere,
                  Infect Mind
                </li>
              </ul>
            </div>

            <!-- devlog 2024.12.11 -->
            <div>
              <p class="mt-6">
                <b>2024.12.11</b> - Inn: upgrade/maintain peanut thresholds
                increased, and item reward scaling
              </p>
              <div class="pb-4 pl-4 w-full">
                <div
                  class="text-xs cursor-pointer text-yellow-600"
                  @click="
                    state.show_changelog['2024.12.11'] =
                      !state.show_changelog['2024.12.11']
                  "
                >
                  <div v-if="state.show_changelog['2024.12.11']">
                    <span>{{ '▲  ' }}</span
                    ><span>[{{ text('ui.hide_changelog') }}]</span>
                  </div>
                  <div v-else>
                    <span>{{ '▼  ' }}</span
                    ><span>[{{ text('ui.show_changelog') }}]</span>
                  </div>
                </div>
              </div>
              <ul
                v-if="state.show_changelog['2024.12.11']"
                class="ml-5 list-outside list-disc"
              >
                <li>
                  Balance: Fumigate AP cost increased: <code>10</code> -->
                  <code>50 - L</code>
                </li>
                <li>Fixed an AI bug causing infinite loops (lock ups)</li>
                <li>
                  AI > Interference: allow consecutive CC abilities (re-try if
                  first fails)
                </li>
                <li>
                  Inn
                  <ul>
                    <li>
                      - Increased upgrade/maintain peanut thresholds by 50%
                    </li>
                    <li>- Fixed reward item quality scaling</li>
                    <li>- Fixed a bug causing swaps to the wrong rank</li>
                  </ul>
                </li>
              </ul>
            </div>

            <!-- devlog 2024.12.09 -->
            <div>
              <p class="mt-6">
                <b>2024.12.09</b> - AI fixes & improvements, Caverns
                performance, and game balance.
              </p>
              <div class="pb-4 pl-4 w-full">
                <div
                  class="text-xs cursor-pointer text-yellow-600"
                  @click="
                    state.show_changelog['2024.12.09'] =
                      !state.show_changelog['2024.12.09']
                  "
                >
                  <div v-if="state.show_changelog['2024.12.09']">
                    <span>{{ '▲  ' }}</span
                    ><span>[{{ text('ui.hide_changelog') }}]</span>
                  </div>
                  <div v-else>
                    <span>{{ '▼  ' }}</span
                    ><span>[{{ text('ui.show_changelog') }}]</span>
                  </div>
                </div>
              </div>
              <ul
                v-if="state.show_changelog['2024.12.09']"
                class="ml-5 list-outside list-disc"
              >
                <li>
                  Balance
                  <ul>
                    <li>
                      - Nerfed Adrenaline
                      <ul>
                        <li class="pl-4">
                          * MP Cost: <code>75 - L</code> -->
                          <code>150 - L*3</code>
                        </li>
                        <li class="pl-4">
                          * AP Gain: <code>25 + L*( St/12 )</code> -->
                          <code>10 + L*( St/12 )</code>
                        </li>
                      </ul>
                    </li>
                    <li>
                      - Boosted Layers of Protection potency:
                      <code>L*1.5</code> --> <code>L*5</code>
                    </li>
                    <li>
                      - Increased Healing Prayer AP cost: <code>20</code> -->
                      <code>45</code>
                    </li>
                    <li>
                      - Nerfed Sanctuary
                      <ul>
                        <li class="pl-4">
                          * AP Cost: <code>25</code> --> <code>65</code>
                        </li>
                        <li class="pl-4">
                          * MP Cost: <code>30 + L*5</code> -->
                          <code>40 + L*6</code>
                        </li>
                        <li class="pl-4">
                          * Effect Range: <code>3</code> --> <code>2</code>
                        </li>
                      </ul>
                    </li>
                    <li>
                      - Nerfed Infect Mind
                      <ul>
                        <li class="pl-4">
                          * AP Cost: <code>10</code> --> <code>30</code>
                        </li>
                        <li class="pl-4">
                          * MP Cost: <code>20 + L*2</code> -->
                          <code>20 + L*5</code>
                        </li>
                      </ul>
                    </li>
                    <li>
                      - Adusted summoning spells' MP Cost scaling:
                      <code>L*50</code> --> <code>100+L*40</code>
                    </li>
                  </ul>
                </li>
                <li>
                  AI
                  <ul>
                    <li>- Heroes will now target only heroes (not summons)</li>
                    <li>
                      - Units should no longer perform actions before the
                      results of the previous action are calculated (e.g.
                      shooting arrows at dead enemies).
                    </li>
                    <li>
                      - Units should no longer do Roundhouse when there aren't
                      enemies in range.
                    </li>
                    <li>
                      - Changed the way "best melee target" is calculated. Units
                      should no longer break away from nearby enemies to go
                      after a distant enemy with low HP. Also fixes the
                      wasting-a-turn-running-back-and-forth bug.
                    </li>
                    <li>- Units should no longer pass turns with full AP</li>
                    <li>- Units should now stack Armor Break appropriately</li>
                    <li>
                      - Units should now use Mortal Strike on slowed targets
                    </li>
                    <li>- CC abilities should no longer be duplicated</li>
                    <li>
                      - Units should now use Stance Dancing appropriately
                      (defensive for tanks, offensive for DPS)
                    </li>
                    <li>
                      - Ranged DPS should now use a variety of equipped
                      abilities (including Fumigate and Lightning)
                    </li>
                    <li>- Improved the targeting for DoT abilities</li>
                  </ul>
                </li>
                <li>
                  ... aaaand ... drastically improved Caverns CPU performance
                </li>
              </ul>
            </div>

            <!-- devlog 2024.12.04 -->
            <div>
              <p class="mt-6">
                <b>2024.12.04</b> - Performance & UI improvements, balance
                changes...
              </p>
              <div class="pb-4 pl-4 w-full">
                <div
                  class="text-xs cursor-pointer text-yellow-600"
                  @click="
                    state.show_changelog['2024.12.04'] =
                      !state.show_changelog['2024.12.04']
                  "
                >
                  <div v-if="state.show_changelog['2024.12.04']">
                    <span>{{ '▲  ' }}</span
                    ><span>[{{ text('ui.hide_changelog') }}]</span>
                  </div>
                  <div v-else>
                    <span>{{ '▼  ' }}</span
                    ><span>[{{ text('ui.show_changelog') }}]</span>
                  </div>
                </div>
              </div>
              <ul
                v-if="state.show_changelog['2024.12.04']"
                class="ml-5 list-outside list-disc"
              >
                <li>
                  Balance
                  <ul>
                    <li>
                      - Reduced the scaling of Fire/Ice/Thunder/Weeping/Venomous
                      weapon procs
                    </li>
                    <ul>
                      <li class="pl-4">
                        * Fire damage: <code>30 + L*6</code> -->
                        <code>50 + L*4</code>
                      </li>
                      <li class="pl-4">
                        * Ice jumps: <code>L/5</code> --> <code>L/7</code>
                      </li>
                      <li class="pl-4">
                        * Lightning damage: <code>30 + L*4</code> -->
                        <code>50 + L*2</code>
                      </li>
                      <li class="pl-4">
                        * Weeping/Venomous potency: <code>10 + L</code> -->
                        <code>5 + L/2.5</code>
                      </li>
                    </ul>
                    <li>
                      - Reduced the MP cost scaling of Summon Dwarf/Elf/Faerie
                      from <code>L*100</code> to <code>L*50</code>
                    </li>
                    <li>
                      - Increased Healing Prayer MP cost scaling from
                      <code>10 + L*4</code> to <code>5 + L*10</code>
                    </li>
                    <li>
                      - Increased Send Mana AP cost scaling from
                      <code>25</code> to <code>45</code>
                    </li>
                  </ul>
                </li>
                <li>
                  Reduced the Shopkeeper's restock interval from 3hrs to 30mins
                </li>
                <li>Increased amount of PD sold for real-world currency</li>
                <li>Fixed Armor Spikes</li>
                <li>
                  Fixed the math on XP needed to for Levels 50+ (sorry, Mr.
                  Gentle)
                </li>
                <li>
                  Fixed a ton of UI layout issues, esp. for smaller resolutions
                </li>
                <li>
                  Fixed a memory leak in the Blessed rendering, a couple other
                  Caverns leaks, an Armory leak, and a huge leak for anyone on
                  the web hitting the Refresh button.
                </li>
              </ul>
            </div>

            <!-- devlog 2024.12.02 -->
            <div>
              <p class="mt-6"><b>2024.12.02</b> - Armor class revamp!</p>
              <div class="pb-4 pl-4 w-full">
                <div
                  class="text-xs cursor-pointer text-yellow-600"
                  @click="
                    state.show_changelog['2024.12.02'] =
                      !state.show_changelog['2024.12.02']
                  "
                >
                  <div v-if="state.show_changelog['2024.12.02']">
                    <span>{{ '▲  ' }}</span
                    ><span>[{{ text('ui.hide_changelog') }}]</span>
                  </div>
                  <div v-else>
                    <span>{{ '▼  ' }}</span
                    ><span>[{{ text('ui.show_changelog') }}]</span>
                  </div>
                </div>
              </div>
              <ul
                v-if="state.show_changelog['2024.12.02']"
                class="ml-5 list-outside list-disc"
              >
                <li>Armor Class now works as a % damage reduction, not flat</li>
                <li>
                  Removed the Pixie Dust cost for resetting abilities &
                  attributes
                </li>
                <li>
                  Caverns info panel: added loot items to the legend, blurb
                  about auto-pickup
                </li>
                <li>Nerfed Multi-shot damage</li>
                <li>Reduced gold rewards for Inn battles</li>
                <li>Fixed a bug resizing the game window from the Shop</li>
                <li>
                  (And, as usual, several other bug fixes not worth mentioning.)
                </li>
              </ul>
            </div>

            <!-- devlog 2024.11.29 -->
            <div>
              <p class="mt-6">
                <b>2024.11.29</b> - Shop "Sell All" button, grouped starting
                Caverns runs, moving bags with items in them, AI & bug fixes,
                and a little balance just for good measure :)
              </p>
              <div class="pb-4 pl-4 w-full">
                <div
                  class="text-xs cursor-pointer text-yellow-600"
                  @click="
                    state.show_changelog['2024.11.29'] =
                      !state.show_changelog['2024.11.29']
                  "
                >
                  <div v-if="state.show_changelog['2024.11.29']">
                    <span>{{ '▲  ' }}</span
                    ><span>[{{ text('ui.hide_changelog') }}]</span>
                  </div>
                  <div v-else>
                    <span>{{ '▼  ' }}</span
                    ><span>[{{ text('ui.show_changelog') }}]</span>
                  </div>
                </div>
              </div>
              <ul
                v-if="state.show_changelog['2024.11.29']"
                class="ml-5 list-outside list-disc"
              >
                <li>Caverns: heroes now start each run grouped together</li>
                <li>
                  Reduced number of particles rendered for Blessed condition and
                  Molotov explosion
                </li>
                <li>
                  Boosted Suture bleed reduction potency: (20 + Level*(Le/8 +
                  De/12)) --> (20 + Level*(Le/4 + De/8))
                </li>
                <li>
                  Resizing the window from the Inn screen no longer breaks the
                  universe
                </li>
                <li>Added a "Sell All" button to the shop</li>
                <li>Player names can now have characters from any language</li>
                <li>You can now move around bags with items in them.</li>
                <li>
                  Fixed a bug calculating teams' HP remaining bars in the
                  Inn/Arena
                </li>
                <li>Fixed a bug unlocking bag slots from the Shop</li>
                <li>Fixed AI lockups caused by Enraged conditions</li>
                <li>
                  Fixed AI bugs preventing the Support tree from using Send Mana
                  and Sanctuary
                </li>
                <li>Increased Sanctuary effect range from 2 -> 3</li>
                <li>And many other bug fixes!</li>
              </ul>
            </div>

            <!-- devlog 2024.11.27 -->
            <div>
              <p class="mt-6">
                <b>2024.11.27</b> - Performance improvements, new weapon prefix,
                Caverns view panning, and much more!
              </p>
              <div class="pb-4 pl-4 w-full">
                <div
                  class="text-xs cursor-pointer text-yellow-600"
                  @click="
                    state.show_changelog['2024.11.27'] =
                      !state.show_changelog['2024.11.27']
                  "
                >
                  <div v-if="state.show_changelog['2024.11.27']">
                    <span>{{ '▲  ' }}</span
                    ><span>[{{ text('ui.hide_changelog') }}]</span>
                  </div>
                  <div v-else>
                    <span>{{ '▼  ' }}</span
                    ><span>[{{ text('ui.show_changelog') }}]</span>
                  </div>
                </div>
              </div>
              <ul
                v-if="state.show_changelog['2024.11.27']"
                class="ml-5 list-outside list-disc"
              >
                <li>Added a tutorial for new players</li>
                <li>The Caverns view is now pannable by click+dragging</li>
                <li>
                  Added new weapon prefix "Gladiator's" - 20% chance to stun.
                </li>
                <li>
                  Added mod commands to silence misbehaving players; deputized
                  Mr. Gentle, Trevor, Draquonis2019, and Meisterlampe as mods.
                </li>
                <li>
                  Audio will now be muted when tabbing out / minimizing the
                  game.
                </li>
                <li>
                  Pixie Dust store appeal for wishlists is now an appeal for
                  reviews
                </li>
                <li>
                  Performance improvements
                  <ul>
                    <li>- Dead body debris now disappears after 60 seconds</li>
                    <li>- Fixed a (bad) memory leak on the Edit Hero screen</li>
                    <li>- Did an optimization to the peanuts animation</li>
                    <li>- Fixed a caverns memory leak</li>
                    <li>- Did an optimization to the blessed rendering</li>
                  </ul>
                </li>
              </ul>
            </div>

            <!-- devlog 2024.11.22 -->
            <div>
              <p class="mt-6">
                <b>2024.11.22</b> - I've been pushing updates just about every
                day, so here's a list of everything that's gone in since the
                last notes:
              </p>
              <div class="pb-4 pl-4 w-full">
                <div
                  class="text-xs cursor-pointer text-yellow-600"
                  @click="
                    state.show_changelog['2024.11.22'] =
                      !state.show_changelog['2024.11.22']
                  "
                >
                  <div v-if="state.show_changelog['2024.11.22']">
                    <span>{{ '▲  ' }}</span
                    ><span>[{{ text('ui.hide_changelog') }}]</span>
                  </div>
                  <div v-else>
                    <span>{{ '▼  ' }}</span
                    ><span>[{{ text('ui.show_changelog') }}]</span>
                  </div>
                </div>
              </div>
              <ul
                v-if="state.show_changelog['2024.11.22']"
                class="ml-5 list-outside list-disc"
              >
                <li>
                  Notable bug fixes
                  <ul>
                    <li>
                      - Fixed a bug making players unable to sell their starting
                      bags
                    </li>
                    <li>
                      - Fixed (only some apparently) AI bugs causing infinite
                      loops in Caverns
                    </li>
                    <li>
                      - Fixed a bug allowing players to skip Caverns levels by
                      spam clicking the stairs
                    </li>
                  </ul>
                </li>
                <li>
                  Balance
                  <ul>
                    <li>- Rebalanced Mystic focus to cost less AP</li>
                    <li>
                      - Nerfed the "of Terror" weapon suffix to proc less & have
                      shorter duration
                    </li>
                    <li>
                      - Increased the stat bonuses of medium & light armor
                      pieces; increased shield AC
                    </li>
                  </ul>
                </li>
                <li>Caverns: increased the minimum breadth of hallways</li>
                <li>
                  Restricted areas of the game for beginners, starting out in
                  Caverns and progressively unlocking other areas
                </li>
                <li>Added Russian language support</li>
                <li>
                  Aaaand of course fixed many other bugs, too numerous to
                  mention
                </li>
              </ul>
            </div>

            <!-- devlog: 2024.11.16 patch notes -->
            <div>
              <p class="mt-6 text-sm">
                <b>2024.11.16</b> - AI fixes, among other things
              </p>
            </div>
            <!-- devlog: 2024.11.05 patch notes -->
            <div>
              <p class="mt-6 text-sm">
                <b>2024.11.05</b> - dwarf/elf/faerie and poppets summons have
                been limited to 2~5, scaling with ability level
                <br />
              </p>
            </div>
            <!-- devlog: DT is BACK -->
            <div>
              <p class="mt-6 text-sm">
                <b>2024.11.01</b> - Dungeon Team is BACK.
              </p>
              <p>
                We're back at it, folks. It's been a crazy, crazy year, but I've
                ended up with the space to go full time at DT again. Steam
                release coming soon. Stay tuned for updates.
                <br />
              </p>
            </div>
          </div>
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script setup>
import { onBeforeUpdate, onMounted, onBeforeUnmount, reactive } from 'vue';
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/vue';
import Audio from '~/Audio';
import { Config } from '~/constants';
import AccountActions from '~/flux/actions/AccountActions';
import { ApplicationStore } from '~/flux/stores';
import text from '~/text';
import { DTButton, DTSpinner } from '~/view/components/common/DOM';

const props = defineProps({
  onClose: Function,
});

const state = reactive({
  did_start_game: false,
  show_changelog: {},
  assets_loaded: false,
});

const classes = {
  section_title: 'mt-12 text-yellow-300 font-bold text-lg underline',
};

onMounted(() => {
  if (Config.PLATFORM === 'game_distribution') {
    window['GD_OPTIONS'] = {
      gameId: '38764ebe5ecc462eaf059d604e435900',
      onEvent: (event) => {
        switch (event.name) {
          case 'SDK_GAME_START':
            // advertisement done, resume game logic and unmute audio
            if (!state.did_start_game) {
              // this.startGame();
            }
            Audio.setMusicVolume(_preAdVolume);
            break;
          case 'SDK_GAME_PAUSE':
            // pause game logic / mute audio
            _preAdVolume = Audio.getMusicVolume();
            Audio.setMusicVolume(0);
            break;
          case 'SDK_GDPR_TRACKING':
            // this event is triggered when your user doesn't want to be tracked
            break;
          case 'SDK_GDPR_TARGETING':
            // this event is triggered when your user doesn't want personalised targeting of ads and such
            break;
        }
      },
    };
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://html5.api.gamedistribution.com/main.min.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'gamedistribution-jssdk');
  } else if (Config.PLATFORM === 'armor_games') {
    document.domain = 'armorgames.com';
    (function () {
      var ag = null;
      document.addEventListener('DOMContentLoaded', function (event) {
        var agiChecks = 0;
        function checkForAGI() {
          if (agiChecks > 1000) return;

          try {
            if (typeof parent.parent.agi !== 'undefined') {
              ag = new ArmorGames({
                user_id: parent.parent.apiAuth.user_id,
                auth_token: parent.parent.apiAuth.auth_token,
                game_id: parent.parent.apiAuth.game_id,
                api_key: '0E8060CF-72E7-472D-90D2-8CEF069A2844',
                agi: parent.parent.agi,
              });

              // ... you can start doing AG requests
            } else {
              agiChecks++;
              window.setTimeout(checkForAGI, 250);
            }
          } catch (err) {
            agiChecks++;
            window.setTimeout(checkForAGI, 250);
          }
        }
        checkForAGI();
      });
    })();
  }

  state.assets_loaded = ApplicationStore.getAll().assets_loaded;
  ApplicationStore.on(ApplicationStore.ASSETS_LOADED, onAssetsLoaded);
});

onBeforeUnmount(() => {
  ApplicationStore.removeListener(
    ApplicationStore.ASSETS_LOADED,
    onAssetsLoaded
  );
});

function onAssetsLoaded() {
  state.assets_loaded = true;
}

function onPlayBtnClicked() {
  if (Config.PLATFORM === 'game_distribution') {
    if (
      typeof window.gdsdk !== 'undefined' &&
      window.gdsdk.showAd !== 'undefined'
    ) {
      state.is_open = false;
      window.gdsdk.showAd();
    }
  } else {
    // start game
    AccountActions.authenticate();
    state.did_start_game = true;
    props.onClose();
  }
}
</script>

<style>
.play-btn-wrapper {
  text-align: center;
  margin-bottom: 10px;
}

.play-btn,
.play-btn:focus {
  color: green;
  padding: 5px 30px 5px 30px;
  font-size: 32px;
  font-weight: bold;
}

.welcome-section > p {
  font-size: 12px;
}

.video-wrapper {
  text-align: center;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 0px;
  height: 0;
}
.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.submit-video {
  margin-top: 5px;
  font-size: 10px;
  text-align: right;
}

.patch-notes {
  font-size: 12px;
}

.help-lost-game-data {
  font-size: 10px;
  text-align: right;
}

.help-lost-game-data > a {
  color: red;
}

code {
  border: 1px solid #444;
  padding: 2px 5px;
  color: rgb(166, 91, 174);
}
</style>
