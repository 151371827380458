<template>
  <p>
    {{ text('context_info.HERO_BUILDS.p1') }}
  </p>
  <p>
    {{ text('context_info.HERO_BUILDS.p2') }}
  </p>
  <p>
    {{ text('context_info.HERO_BUILDS.p3') }}
  </p>
  <hr />
  <p class="mt-3">
    <span
      >{{ text('context_info.HERO_BUILDS.unlock_costs') }} 25{{
        text('ui.pixie_dust_short')
      }}, 50{{ text('ui.pixie_dust_short') }}, 100{{
        text('ui.pixie_dust_short')
      }}, 250{{ text('ui.pixie_dust_short') }}, 500{{
        text('ui.pixie_dust_short')
      }}, 750{{ text('ui.pixie_dust_short') }}, 1,000{{
        text('ui.pixie_dust_short')
      }}, 1,500{{ text('ui.pixie_dust_short') }}, 2,000{{
        text('ui.pixie_dust_short')
      }}, 5,000{{ text('ui.pixie_dust_short') }}, 10,000{{
        text('ui.pixie_dust_short')
      }}</span
    >
  </p>
  <hr />
  <p class="mt-3">
    <span class="text-cyan-400"
      >***{{ text('context_info.tip_for_beginners') }}***
    </span>
    <span>{{ text('context_info.HERO_BUILDS.p4') }}</span>
  </p>
</template>

<script setup>
import { onMounted } from 'vue';
import { TutorialActions } from '~/flux/actions';
import { TutorialStore } from '~/flux/stores';
import text from '~/text';

onMounted(() => {
  const { opened_context_info } = TutorialStore.getCompletedTutorialSteps();
  if (!opened_context_info) logTutorialStepCompleted('opened_context_info');
});
</script>
