import TutorialDispatcher from '~/flux/dispatchers/TutorialDispatcher';
import {
  TutorialBox,
  TutorialSpotlight,
} from '~/view/components/common/canvas';
import { UIStore } from '~/flux/stores';
import Tools from '~/Tools';

let _tutorial_box;
let _tutorial_spotlight;
let _box_params_buf;
let _spotlight_params_buf;

const TutorialActions = {
  destroyTutorialBox() {
    _tutorial_box?.destroy();
    _tutorial_box = null;
  },

  destroyTutorialSpotlight() {
    TutorialDispatcher.handleTutorialAction({
      actionType: TutorialDispatcher.TUTORIAL_SPOTLIGHT_HIDDEN,
    });

    _tutorial_spotlight?.destroy();
    _tutorial_spotlight = null;
  },

  hideTutorialBox() {
    _tutorial_box &&
      TweenMax.to(_tutorial_box, 0.5, {
        alpha: 0,
        onComplete: (dead_box) => dead_box?.destroy(),
        onCompleteParams: [_tutorial_box],
      });
  },

  logTutorialStepCompleted(step_name, onboard_step) {
    TutorialDispatcher.handleTutorialAction({
      actionType: TutorialDispatcher.LOG_TUTORIAL_STEP_COMPLETED,
      step_name,
      onboard_step,
    });
  },

  makeTutorialBox(params) {
    if (UIStore.getAll().showing_modal) {
      _box_params_buf = { ...params };
      return;
    }
    _box_params_buf = null;

    const { text, arrow, width, x, y } = params;

    TutorialActions.destroyTutorialBox();
    _tutorial_box = new TutorialBox(text, arrow, width);
    _tutorial_box.params = params;
    _tutorial_box.x = x;
    _tutorial_box.y = y;
    TweenMax.from(_tutorial_box, 0.35, { alpha: 0 });
    DT_CANVAS_GLOBALS.overlay_stage.addChild(_tutorial_box);
    return _tutorial_box;
  },

  makeTutorialSpotlight(params) {
    if (UIStore.getAll().showing_modal) {
      _spotlight_params_buf = { ...params };
      return;
    }
    _spotlight_params_buf = null;

    const { radius, x, y, onTransitionInComplete } = params;

    TutorialDispatcher.handleTutorialAction({
      actionType: TutorialDispatcher.TUTORIAL_SPOTLIGHT_VISIBLE,
    });

    _tutorial_spotlight?.destroy();
    _tutorial_spotlight = new TutorialSpotlight(radius);
    _tutorial_spotlight.params = params;
    _tutorial_spotlight.x = x;
    _tutorial_spotlight.y = y;
    _tutorial_spotlight.transitionIn(onTransitionInComplete);
    DT_CANVAS_GLOBALS.overlay_stage.addChild(_tutorial_spotlight);
    return _tutorial_spotlight;
  },

  startTutorialBattle01() {
    TutorialDispatcher.handleTutorialAction({
      actionType: TutorialDispatcher.START_TUTORIAL_BATTLE_01,
    });
  },
};
export default TutorialActions;

UIStore.on(
  UIStore.SHOW_MODAL,
  Tools.debounce(() => {
    if (_tutorial_box) _box_params_buf = _tutorial_box.params;
    if (_tutorial_spotlight) _spotlight_params_buf = _tutorial_spotlight.params;
    TutorialActions.destroyTutorialBox();
    TutorialActions.destroyTutorialSpotlight();
  }, 50)
);

UIStore.on(UIStore.MODAL_CLOSED, () => {
  _box_params_buf && TutorialActions.makeTutorialBox(_box_params_buf);
  _spotlight_params_buf &&
    TutorialActions.makeTutorialSpotlight(_spotlight_params_buf);
});
