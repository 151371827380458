import * as PIXI from 'pixi.js';
import generateStarterEquipmentAndBags from 'dt-common/isomorphic-helpers/generateStarterEquipmentAndBags';
import Audio from '~/Audio';
import UnitPuppet from '~/components/common/unit_puppets/UnitPuppet'; // TODO(@robertlombardo): move to ~/view/components/common/canvas
import Colors from '~/constants/Colors';
import { ChatActions } from '~/flux/actions';
import { PlayerStore } from '~/flux/stores';
import text from '~/text';
import CanvasTools from '~/view/CanvasTools';
import AnimatedLevelBar from './AnimatedLevelBar';
import HeroLevelView from './HeroLevelView';

const PuppetWithNameplate = function ({
  before_battle,
  built_hero,
  roster_hero,
  scale_mod = 1,
  show_build_name = false,
  build_name_label_only = false,
  build_name_scale_mod = 1,
  spoof_starter_equipment = false,
  perma_vamp = null,
  face_direction = false,
  show_no_abilities_warning = false,
}) {
  PIXI.Container.call(this);

  const PUPPET_BODY_SCALE = 0.7 * scale_mod;
  const ROOT_SCALE = 0.8 * scale_mod;
  const hero_handle = roster_hero.handle;

  let _animated_level_bar;
  let _build_name_label;
  let _level_view;
  let _name_plate;
  let _puppet;
  let _puppet_build;
  // let _unit_bags = roster_hero.bags;
  let _editHeroTip;

  this.transitionIn = () => {
    _puppet.transitionIn({ type: 'sprint_forward' });

    TweenMax.from(_name_plate, 0.3, {
      alpha: 0,
      y: _name_plate.y + DT_CANVAS_GLOBALS.spacing * 2,
    });
    _level_view &&
      TweenMax.from(_level_view, 0.2, {
        alpha: 0,
        y: _level_view.y + DT_CANVAS_GLOBALS.spacing * 3,
      });
  };

  if (built_hero) {
    _puppet_build = built_hero;
  } else {
    const equipment_assignment = {};

    if (spoof_starter_equipment) {
      const { starter_equipment, starter_bags } =
        generateStarterEquipmentAndBags(hero_handle);
      for (const [slot, item] of Object.entries(starter_equipment)) {
        equipment_assignment[slot] = item.uid;
      }
      roster_hero = { ...roster_hero, bags: starter_bags };
    }

    _puppet_build = {
      ...roster_hero,
      equipment_assignment,
    };
  }

  this.actor = _puppet_build;

  // make UnitPuppet
  this.puppet = _puppet = new UnitPuppet({
    body_scale: PUPPET_BODY_SCALE,
    roster_hero,
    unit_build: _puppet_build,
    perma_vamp,
  });
  face_direction && _puppet.face(face_direction);
  this.addChild(_puppet);

  // add nameplate (Knight, Warlock, etc.)
  _name_plate = new PIXI.Sprite();
  _name_plate.texture =
    PIXI.utils.TextureCache['armory/class_label_default.png'];
  _name_plate.txt = new PIXI.Text(
    text(`heroes.${hero_handle}.name`).toUpperCase(),
    {
      fontFamily: 'Courier New',
      fontStyle: 'bold',
      fontSize: 22,
      fill: 0x000000,
      dropShadow: true,
      dropShadowDistance: 2,
      dropShadowColor: 0x929292,
    }
  );
  _name_plate.txt.x = Math.round(
    _name_plate.width / 2 - _name_plate.txt.width / 2
  );
  _name_plate.txt.y = Math.round(
    _name_plate.height * 0.65 - _name_plate.txt.height / 2
  );
  _name_plate.addChild(_name_plate.txt);
  _name_plate.height = ROOT_SCALE * 30;
  _name_plate.scale.x = _name_plate.scale.y;
  _name_plate.x = Math.round(-_name_plate.width / 2);
  _name_plate.y = Math.round(ROOT_SCALE * 7);
  this.addChild(_name_plate);

  if (roster_hero.level > 0) {
    // unlocked hero view - color the nameplate
    _name_plate.tint = Colors[hero_handle];
    _name_plate.txt.style.fill = 0xffffff;
    _name_plate.txt.style.dropShadowDistance = 2;
    _name_plate.txt.style.dropShadowColor = 0x000000;

    // render hero level
    _level_view = new HeroLevelView({ roster_hero, scale_mod });
    _level_view.height = ROOT_SCALE * 28;
    _level_view.scale.x = _level_view.scale.y;
    _level_view.x = Math.round(-_level_view.width / 2);
    _level_view.y = Math.round(_name_plate.y + _name_plate.height * 0.8);
    this.addChild(_level_view);

    // hero build name
    if (show_build_name) {
      const build_name =
        _puppet_build.build_display_name || text('heroes.basic');
      _build_name_label = new PIXI.Text(build_name, {
        fontFamily: 'Courier New',
        fontSize: 14 * build_name_scale_mod,
        fill: Colors[hero_handle],
        dropShadow: true,
        dropShadowDistance: 1,
        dropShadowColor: 0xffffff,
      });
      _build_name_label.x = Math.round(-_build_name_label.width / 2);
      _build_name_label.y = Math.round(
        _name_plate.y - _build_name_label.height / 2
      );

      const bg = new PIXI.Graphics();
      bg.beginFill(0x000000, 0.4);
      bg.drawRect(0, 0, _build_name_label.width, _build_name_label.height / 2);
      bg.endFill();
      bg.x = _build_name_label.x;
      bg.y = _build_name_label.y + 5;

      this.addChild(bg);
      this.addChild(_build_name_label);

      if (build_name_label_only) {
        _name_plate.visible = false;
        _level_view.visible = false;
        _build_name_label.y = _name_plate.y + 20;
        bg.y = _build_name_label.y + 5;
      }

      // warning about no equipped abilities
      if (show_no_abilities_warning) {
        const no_abilities_equipped =
          (_puppet_build?.equipped_abilities ?? [])
            .filter((key) => !!key)
            .filter((key) => !['move', 'melee_attack'].includes(key)).length ===
          0;
        if (no_abilities_equipped) {
          const tip_container = new PIXI.Container();
          setTimeout(() => {
            // timeout so not to skew width of EngagedSquadView
            this.addChild(tip_container);
          }, 20);

          _editHeroTip = new PIXI.Text('!', {
            fontFamily: 'Courier New',
            fontStyle: 'bold',
            fontSize: CanvasTools.dynamicFontSizeString(20 * scale_mod),
            fill: Colors.BRIGHT_YELLOW,
          });
          _editHeroTip.anchor.x = _editHeroTip.anchor.y = 0.5;
          _editHeroTip.x = scale_mod * -20;
          _editHeroTip.y = scale_mod * -45;
          _editHeroTip.rotation = -Math.PI / 5;
          tip_container.addChild(_editHeroTip);

          const editHeroTipText = new PIXI.Text(
            text('tutorial.no_abilities_equipped'),
            {
              fontFamily: 'Courier New',
              fontStyle: 'bold',
              fontSize: CanvasTools.dynamicFontSizeString(7 * scale_mod),
              fill: Colors.BRIGHT_YELLOW,
              dropShadow: true,
              dropShadowColor: 0x000000,
              dropShadowDistance: 2,
            }
          );
          editHeroTipText.x = -editHeroTipText.width / 2;
          editHeroTipText.y = -scale_mod * 10;
          tip_container.addChild(editHeroTipText);

          TweenMax.from(tip_container, 0.6, {
            alpha: 0,
            ease: Expo.easeOut,
            yoyo: true,
            repeat: -1,
          });
        }
      }
    }
  } else {
    // greyscale locked hero puppets
    _puppet.setTint(0x555555, true, true);
  }

  const showLevelUp = ({ levelShown }) => {
    _level_view.levelTxt.text = levelShown;

    for (let i = 0; i < 15; ++i) {
      const us = new PIXI.Sprite();
      us.texture = PIXI.utils.TextureCache['underscore.png'];
      us.tint = 0xffea00;
      us.alpha = 0.45;
      us.anchor.x = us.anchor.y = 0.5;
      us.x = 0;
      us.y = DT_CANVAS_GLOBALS.spacing * 3;

      TweenMax.from(us, 0.6, {
        alpha: 0,
        onComplete: fadeOutUnderscore,
        onCompleteParams: [us],
      }).delay(i * 0.12);

      TweenMax.to(us, 1.5, {
        y: -DT_CANVAS_GLOBALS.spacing * 30,
        ease: Quad.easeIn,
      }).delay(i * 0.12);

      TweenMax.to(us.scale, 1.5, {
        x: 0.85,
        y: 0,
        ease: Elastic.easeOut,
      }).delay(i * 0.12);

      this.addChild(us);
    }

    Audio.play('level_up');

    if (levelShown % 5 === 0 || levelShown >= 20) {
      ChatActions.gameNotification('hero_level_up', {
        heroHandle: hero_handle,
        level: levelShown,
      });
    }
  };

  // make AnimatedLevelBar for a hero with `before_battle` data
  const { loggedInPlayerId } = PlayerStore.getAll();
  if (
    before_battle &&
    (before_battle[loggedInPlayerId] || before_battle[hero_handle])
  ) {
    // render level & xp data in the pre-battle state, and animate showing xp/level gains
    const before_battle_this_player =
      before_battle[loggedInPlayerId] || before_battle;
    const hero_before_battle = before_battle_this_player
      ? before_battle_this_player[hero_handle]
      : null;
    _level_view.levelTxt.text =
      hero_before_battle?.level || before_battle[hero_handle]?.level || '???';

    _animated_level_bar = new AnimatedLevelBar({
      roster_hero,
      hero_before_battle,
    });
    _animated_level_bar.width = Math.min(
      this.width * 0.95,
      _animated_level_bar.width
    );
    _animated_level_bar.scale.y = _animated_level_bar.scale.x;
    _animated_level_bar.x = Math.round(-_animated_level_bar.width / 2);
    _animated_level_bar.y = Math.round(
      DT_CANVAS_GLOBALS.spacing * 5.5 * scale_mod
    );
    this.addChild(_animated_level_bar);

    _animated_level_bar.eventEmitter.on('ALB_SHOW_LEVEL_UP', showLevelUp);
  }

  this.dispose = () => {
    gsap.killTweensOf(_editHeroTip);

    if (_animated_level_bar) {
      _animated_level_bar.eventEmitter.removeListener(
        'ALB_SHOW_LEVEL_UP',
        showLevelUp
      );
      _animated_level_bar.destroy();
      _animated_level_bar = null;
    }

    _level_view?.dispose();
    _puppet?.dispose();
    _level_view = null;
    _name_plate = null;
    _puppet = null;
    _build_name_label = null;
    this.destroy();
  };
};
PuppetWithNameplate.prototype = Object.create(PIXI.Container.prototype);
PuppetWithNameplate.prototype.constructor = PuppetWithNameplate;
export default PuppetWithNameplate;

function fadeOutUnderscore(us) {
  TweenMax.to(us, 0.6, {
    alpha: 0,
    onComplete: () => {
      if (us.parent) {
        us.parent.removeChild(us);
      }
    },
  });
}
