<template>
  <Dialog
    :open="true"
    @close="props.onClose"
  >
    <div
      class="fixed inset-0 flex items-center justify-center p-4 z-20 bg-zinc-700/90"
    >
      <DialogPanel
        class="border border-white w-[800px] max-h-[90vh] flex flex-col bg-black"
      >
        <DialogTitle
          class="w-full border-b border-white p-3 text-yellow-300 flex justify-between"
        >
          <div>
            {{ text('ui.affinities') }}:
            {{ text(`unit_names.${state.focused_hero_build?.hero_handle}`) }} |
            {{
              state.focused_hero_build?.built_hero?.build_display_name ??
              text('heroes.basic')
            }}
          </div>
          <div>
            {{
              `${text('game.ability_points.full')}: ${state.available_ability_points}`
            }}
          </div>
        </DialogTitle>

        <!-- scrollable body -->
        <div class="flex flex-col overflow-y-auto h-[calc(95%-42px)]">
          <div
            id="HeroAffinitiesModal_body"
            class="w-full p-6 pr-[115px] flex flex-col"
          >
            <!-- attack/defense icons row -->
            <div class="w-full mb-4 flex">
              <div class="w-[40%] pr-6 flex justify-end items-end">
                {{ text('ui.vs') }}
              </div>
              <div class="w-[30%] flex justify-center">
                <img :src="`${DOM_IMG_PATH}/affinity_offensive.png`" />
              </div>
              <div class="w-[30%] flex justify-center">
                <img :src="`${DOM_IMG_PATH}/affinity_defensive.png`" />
              </div>
            </div>
            <!-- 1 row per unit type -->
            <div
              v-for="unit_type_handle of UNIT_TYPE_HANDLES"
              class="w-full flex items-center"
            >
              <!-- affinity type -->
              <div
                class="w-[40%] pr-6 flex justify-end items-center text-yellow-300 no-select"
                @mouseenter="
                  (event) => makeTooltip(unit_type_handle, event.target)
                "
                @mouseleave="() => _tooltip?.dispose()"
              >
                {{ text(`unit_names.${unit_type_handle}`) }}
              </div>
              <!-- attack affinity & +/- buttons -->
              <div class="w-[30%] my-2 flex justify-center items-center">
                <div class="w-[48px] mr-2 text-right">
                  <DTAnimatedElipsis
                    v-if="state.pending_increments.attack[unit_type_handle]"
                    class="text-xs"
                  />
                  <span v-else>
                    +{{
                      (
                        (state.focused_hero_build.built_hero.affinities.attack[
                          unit_type_handle
                        ] || 0) *
                        Balance.AFFINITY_BONUS_PER_POINT *
                        100
                      ).toFixed(0)
                    }}%
                  </span>
                </div>
                <div class="flex">
                  <DTButton
                    type="error"
                    class="w-[32px] h-[32px] mr-1"
                    :disabled="
                      !state.focused_hero_build.built_hero.affinities.attack[
                        unit_type_handle
                      ]
                    "
                    @click="
                      onIncrementAffinityClick('attack', unit_type_handle, -1)
                    "
                    >-</DTButton
                  >
                  <DTButton
                    type="success"
                    class="w-[32px] h-[32px]"
                    :disabled="
                      state.focused_hero_build.built_hero.affinities.attack[
                        unit_type_handle
                      ] >= Balance.MAX_ATTACK_AFFINITY_LEVEL ||
                      state.available_ability_points <= 0
                    "
                    @click="
                      onIncrementAffinityClick('attack', unit_type_handle, 1)
                    "
                    >+</DTButton
                  >
                </div>
              </div>
              <!-- defense affinity & +/- buttons -->
              <div class="w-[30%] my-2 flex justify-center items-center">
                <div class="w-[48px] mr-2 text-right">
                  <DTAnimatedElipsis
                    v-if="state.pending_increments.defense[unit_type_handle]"
                    class="text-xs"
                  />
                  <span v-else>
                    -{{
                      (
                        (state.focused_hero_build.built_hero.affinities.defense[
                          unit_type_handle
                        ] || 0) *
                        Balance.AFFINITY_BONUS_PER_POINT *
                        100
                      ).toFixed(0)
                    }}%
                  </span>
                </div>
                <div class="flex">
                  <DTButton
                    type="error"
                    class="w-[32px] h-[32px] mr-1"
                    :disabled="
                      !state.focused_hero_build.built_hero.affinities.defense[
                        unit_type_handle
                      ]
                    "
                    @click="
                      onIncrementAffinityClick('defense', unit_type_handle, -1)
                    "
                    >-</DTButton
                  >
                  <DTButton
                    type="success"
                    class="w-[32px] h-[32px]"
                    :disabled="
                      state.focused_hero_build.built_hero.affinities.defense[
                        unit_type_handle
                      ] >= Balance.MAX_DEFENSE_AFFINITY_LEVEL ||
                      state.available_ability_points <= 0
                    "
                    @click="
                      onIncrementAffinityClick('defense', unit_type_handle, 1)
                    "
                    >+</DTButton
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="w-full pb-6 flex justify-center">
            <DTButton
              type="success"
              class="py-1 px-5 w-[80px]"
              @click="onOKClick"
            >
              {{ text('ui.OK') }}
            </DTButton>
          </div>
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script setup>
import { onBeforeMount, onBeforeUnmount, reactive } from 'vue';
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/vue';
import Balance from 'dt-common/constants/Balance';
import mobs from 'dt-common/models/game_pieces/ai_units/mobs';
import summoned_units from 'dt-common/models/game_pieces/ai_units/summoned_units';
import getAvailableAbilityPoints from 'dt-common/isomorphic-helpers/getAvailableAbilityPoints';
import Tooltip from '~/components/tooltips/Tooltip';
import text from '~/text';
import { DTAnimatedElipsis, DTButton } from '~/view/components/common/DOM';
import { EditHeroActions } from '~/flux/actions';
import { FluxGetters, HeroBuildStore } from '~/flux/stores';

const { ASSETS_PATH = 'assets' } = process.env;
const DOM_IMG_PATH = `${ASSETS_PATH}/img/DOM`;

const UNIT_TYPE_HANDLES = [
  // hero classes
  'assassin',
  'battle_mage',
  'dreamsmith',
  'invoker',
  'knight',
  'mercenary',
  'monk',
  'paladin',
  'ranger',
  'shaman',
  'warlock',
  // caverns mob types
  'amorphous',
  'beast',
  'flying',
  'humanoid',
  'magic',
  'undead',
];

const UNIT_HANDLES_BY_UNIT_TYPE = [
  ...Object.values(mobs),
  ...Object.values(summoned_units),
].reduce((result, MobClass) => {
  const dummy_unit_build = MobClass(1, { team: 'white', equipment: {} });
  for (const unit_type_handle of dummy_unit_build.types)
    if (!result[unit_type_handle])
      result[unit_type_handle] = [dummy_unit_build.handle];
    else if (!result[unit_type_handle].includes(dummy_unit_build.handle))
      result[unit_type_handle].push(dummy_unit_build.handle);
  return result;
}, {});

let _tooltip;

const props = defineProps({
  onClose: {
    type: Function,
    required: true,
  },
});

const state = reactive({
  focused_hero_build: null,
  available_ability_points: 0,
  pending_increments: { attack: {}, defense: {} },
});

onBeforeMount(() => {
  setFocusedHeroBuild();
  HeroBuildStore.on(HeroBuildStore.ABILITY_CHANGE, onAbilityChange);
});

onBeforeUnmount(() => {
  HeroBuildStore.removeListener(HeroBuildStore.ABILITY_CHANGE, onAbilityChange);
  _tooltip?.dispose();
});

function setFocusedHeroBuild() {
  state.focused_hero_build = FluxGetters.getFocusedHeroBuild();

  // handle legacy data
  if (!state.focused_hero_build.built_hero.affinities)
    state.focused_hero_build.built_hero.affinities = {
      attack: {},
      defense: {},
    };
  if (!state.focused_hero_build.built_hero.affinities.attack)
    state.focused_hero_build.built_hero.affinities.attack = {};
  if (!state.focused_hero_build.built_hero.affinities.defense)
    state.focused_hero_build.built_hero.affinities.defense = {};

  state.available_ability_points = getAvailableAbilityPoints({
    built_hero: state.focused_hero_build.built_hero,
    roster_hero: state.focused_hero_build.roster_hero,
  });
}

function makeTooltip(unit_type_handle) {
  _tooltip?.dispose();

  if (UNIT_HANDLES_BY_UNIT_TYPE[unit_type_handle]?.length)
    _tooltip = new Tooltip('ui', {
      body_text: UNIT_HANDLES_BY_UNIT_TYPE[unit_type_handle]
        .map((unit_handle) => text(`unit_names.${unit_handle}`))
        .join(', '),
    });
}

function onIncrementAffinityClick(affinity_type, unit_type_handle, value) {
  state.pending_increments[affinity_type][unit_type_handle] = true;

  EditHeroActions.incrementAffinity({
    hero_handle: state.focused_hero_build.hero_handle,
    hero_build_id: state.focused_hero_build.built_hero._id,
    affinity_type,
    unit_type_handle,
    value,
  });
}

function onAbilityChange() {
  state.pending_increments = { attack: {}, defense: {} };
  setFocusedHeroBuild();
}

function onOKClick() {
  props.onClose();
}
</script>
