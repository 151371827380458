import * as PIXI from 'pixi.js';
import CavernsTileSprite from './CavernsTileSprite';
import UnitPuppet from '~/components/common/unit_puppets/UnitPuppet';
import Colors from '~/constants/Colors';
import GravitySprite from '~/components/common/GravitySprite';
import ApplicationStore from '~/flux/stores/ApplicationStore';
import { ObjectPool } from '~/Tools';
import UnitStatBar from '~/view/game-screens/battle/canvas/game_board/piece_sprites/UnitStatBar';

const SYMBOLS_DICT = {
  dwarf: 'd',
  elf: 'e',
  faerie: 'p',

  bandit: 'h',
  cave_lizard: 'l',
  ectoplasm: 'o',
  fire_drake: 'j',
  flying_jelly: 'o',
  ghost: 'G',
  giant_rat: 'r',
  goblin: 'g',
  goblin_elementalist: 'g',
  goo: 'o',
  huge_bat: 'B',
  kobold: 'k',
  kobold_cleric: 'k',
  lich: 'L',
  phantasm: 'Q',
  ratling_priest: 'r',
  ratling_warrior: 'r',
  skeletal_archer: 'x',
  skeleton: 'x',
  slime: 'o',
  snake: 's',
  voidwalker: 'W',
  wraith: 'G',
};

let _deathParticlePool;
const onAssetsLoaded = () => {
  _deathParticlePool = new ObjectPool(
    // create func
    () => {
      const p = new GravitySprite(PIXI.utils.TextureCache['o_small.png']);
      p.width = 5 + Math.random() * 7;
      p.scale.y = p.scale.x;
      p.floorY = -10 + Math.random() * 50;
      p.yVelocity = -3.0 - Math.random() * 2;
      p.anchor.x = p.anchor.y = 0.5;
      return p;
    },

    // reset func
    (p) => {
      p?.parent?.removeChild(p);
      p.dispose();
      gsap.killTweensOf(p);
      p.x = 0;
      p.y = 0;
      p.yVelocity = -3.0 - Math.random() * 2;
      p.alpha = 1;
    },

    // starting num
    30
  );
  ApplicationStore.removeListener(
    ApplicationStore.ASSETS_LOADED,
    onAssetsLoaded
  );
};
ApplicationStore.on(ApplicationStore.ASSETS_LOADED, onAssetsLoaded);

const CavernsUnitSprite = function (unit_state, options) {
  const _tileSprite = new CavernsTileSprite(unit_state);
  let _puppet;

  if (unit_state.team === 'black') {
    // make the stats bar
    var sb = (_tileSprite.statBar = new UnitStatBar(unit_state, {
      is_caverns: true,
    }));
    sb.interactive = false;
    _tileSprite.addChild(sb);
    sb.init(); // must be called after addChild(), as it uses the tile sprite's width for drawing
    sb.x = -8;
    sb.y = -17;
    sb.update(unit_state);
  }

  _tileSprite.updateUnit = function (unit_state) {
    if (unit_state.dead || !unit_state.inPlay) {
      return;
    }
    this.gamePiece = unit_state;
    if (unit_state.hero) {
      _puppet.actor = unit_state;
    }

    _tileSprite.statBar?.update(unit_state);
  };

  _tileSprite.tileImg.scale = { x: 0.5, y: 0.5 };
  const visuals = getVisualData(unit_state);
  _tileSprite.tileImg.text = visuals.symbol;
  _tileSprite.tileImg.tint = visuals.tint;

  if (
    unit_state.hero ||
    (unit_state.team === 'white' && unit_state.handle === 'poppet')
  ) {
    _tileSprite.tileImg.alpha = 0.001;

    // make the unit_state puppet
    _puppet = _tileSprite.puppet = new UnitPuppet({
      unit_state,
      body_scale: 0.5,
    });
    _puppet.y = _tileSprite.height * 0.6;
    _tileSprite.addChild(_puppet);
    _puppet.interactive = false;
    if (!options?.prevent_transition_in) {
      _puppet.transitionIn();
    }
  }

  // initialize the text queue timer
  _tileSprite.textQueue = [];
  const onTextQueueInterval = () => {
    if (_tileSprite.textQueue.length > 0) {
      const textEffect = _tileSprite.textQueue.shift();
      textEffect.scale.x = Math.min(2, 1 / _tileSprite.scale.x);
      textEffect.scale.y = Math.min(2, 1 / _tileSprite.scale.y);
      textEffect.y = -_tileSprite.tileImg.height * 0.23;
      textEffect.init();
      _tileSprite.addChild(textEffect);
    }
  };
  _tileSprite.textQueueTimer = setInterval(onTextQueueInterval, 300);

  const onDeathParticleComplete = (deadParticle) => {
    _deathParticlePool.put(deadParticle);
  };

  _tileSprite.onDeath = function () {
    gsap.killTweensOf(_tileSprite.tileImg);
    TweenMax.to(_tileSprite.tileImg, 0.35, { alpha: 0 });

    if (!unit_state.hero) {
      var numParticles = Math.floor(6 + Math.random() * 5);
      var gSprite;
      for (let i = 0; i < numParticles; ++i) {
        gSprite = _deathParticlePool.get();
        gSprite.init();
        gSprite.rotation_tween = TweenMax.to(
          gSprite,
          1.5 + Math.random() * 1.0,
          {
            x: gSprite.x - 60 + Math.random() * 120,
            rotation: Math.random() * Math.PI * 3,
            alpha: 0,
            onComplete: onDeathParticleComplete,
            onCompleteParams: [gSprite],
          }
        );
        this.addChild(gSprite);
      }

      TweenMax.delayedCall(2.5, () => {
        _tileSprite?.disposeAsUnitSprite();
        _tileSprite?.dispose();
      });
    }
  };

  _tileSprite.disposeAsUnitSprite = () => {
    if (this.disposed) {
      return;
    }

    _puppet?.dispose();

    clearInterval(_tileSprite.textQueueTimer);
    _tileSprite.textQueue = null;
    _tileSprite?.statBar?.dispose();
    this.disposed = true;
  };

  return _tileSprite;
};
export default CavernsUnitSprite;

function getVisualData(unit_state) {
  var result = {};

  if (unit_state.hero) {
    result.symbol = '_'; // '@';
  } else {
    result.symbol = SYMBOLS_DICT[unit_state.handle];
  }

  result.tint = Colors[unit_state.hero_handle || unit_state.handle];

  return result;
}
