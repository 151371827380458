import * as PIXI from 'pixi.js';
import getAvailableAbilityPoints from 'dt-common/isomorphic-helpers/getAvailableAbilityPoints';
import { EditHeroActions, UIActions } from '~/flux/actions';
import { FluxGetters, UIStore } from '~/flux/stores';
import text from '~/text';
import CanvasTools from '~/view/CanvasTools';

const AbilityPointsPanel = function () {
  PIXI.Container.call(this);

  const { built_hero, roster_hero } = FluxGetters.getFocusedHero();
  const {
    current_game_mode,
    current_game_submode,
    focused_hero_handle: hero_handle,
  } = UIStore.getAll();

  this.dispose = () => {
    this.destroy();
  };

  var resetBtn = new PIXI.Sprite();
  resetBtn.texture = PIXI.utils.TextureCache['armory/reset_button.png'];
  resetBtn.interactive = resetBtn.buttonMode = true;
  resetBtn.width = resetBtn.height = 26;
  resetBtn.hitArea = new PIXI.Circle(0, 0, 29 / resetBtn.scale.x);
  resetBtn.tap = resetBtn.click = () => {
    UIActions.showModal({
      modal_key: 'ResetAbilitiesModal',
      modal_props: {
        hero_handle,
        onConfirmed: () => {
          EditHeroActions.resetAbilitiesConfirm({
            hero_handle,
            hero_build_id: FluxGetters.getFocusedHeroBuild().built_hero._id,
          });
        },
      },
    });
  };
  resetBtn.anchor.x = resetBtn.anchor.y = 0.5;
  this.addChild(resetBtn);

  // reset button mouseover highlight
  const highlight = new PIXI.Graphics();
  highlight.beginFill(0xffffff, 0.05);
  highlight.drawCircle(0, 0, 14);
  highlight.endFill();
  highlight.visible = false;
  highlight.pivot.x = highlight.pivot.y = 0.5;
  this.addChildAt(highlight, 0);

  resetBtn.mouseover = resetBtn.pointerover = () => {
    highlight.visible = true;
  };
  resetBtn.mouseout = resetBtn.pointerout = () => {
    highlight.visible = false;
  };

  const available_ability_points = getAvailableAbilityPoints({
    built_hero,
    roster_hero,
  });
  const label_text = `${text('game.ability_points.full')}: ${available_ability_points}`;
  var label = new PIXI.Text(label_text, {
    fontFamily: 'Courier New',
    fontSize: CanvasTools.dynamicFontSizeString(14),
    fontStyle: 'bold',
    fill: 0xffffff,
  });
  label.x = resetBtn.x + resetBtn.width / 2 + DT_CANVAS_GLOBALS.spacing;
  label.y = resetBtn.y - label.height / 2;
  this.addChild(label);
};
AbilityPointsPanel.prototype = Object.create(PIXI.Container.prototype);
AbilityPointsPanel.prototype.constructor = AbilityPointsPanel;
export default AbilityPointsPanel;
